import { faFloppyDisk, faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Modal, Popover, Space, Tabs, notification } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Fab from '../../components/admin/Fab';
import Loading from '../../components/base/Loading';
import PageHeader from '../../components/base/PageHeader';
import FormBuilder from '../../components/formBuilder/FormBuilder';
import MessageTemplateBuilder from '../../components/taskDefinitionBuilder/MessageTemplateBuilder';
import TaskDefinitionFiles from '../../components/taskDefinitionBuilder/TaskDefinitionFiles';
import useCompany from '../../context/company/useCompany';
import {
  FormFieldDefinition,
  Provider,
  TaskDefinitionProviderConfig,
  useDeleteTaskDefinitionProviderConfigMutation,
  useGetProvidersQuery,
  useGetTaskDefinitionFilesQuery,
  useGetTaskDefinitionQuery,
  useOwnUserQuery,
  useUpdateTaskDefinitionMutation,
  useUpsertTaskDefinitionProviderConfigMutation,
} from '../../graphql/schema';
import confirmModal from '../../helper/confirmModal';

type DeletableTaskDefinitionProviderConfig = TaskDefinitionProviderConfig & {
  deleted?: boolean;
};

const TaskDefinition = () => {
  const { id } = useParams();
  const { company } = useCompany();
  const { data: ownUser } = useOwnUserQuery();

  const [addProviderModalOpen, setAddProviderModalOpen] =
    useState<boolean>(false);

  const [taskDefinitionProviderConfigs, setTaskDefinitionProviderConfigs] =
    useState<DeletableTaskDefinitionProviderConfig[]>([]);
  const [formDefinition, setFormDefinition] = useState<FormFieldDefinition[]>(
    [],
  );
  const [settingsFormDefinition, setSettingsFormDefinition] = useState<
    FormFieldDefinition[]
  >([]);
  const [formsData, setFormsData] = useState<{
    form: any;
    settings: any;
  }>({
    form: {},
    settings: {},
  });

  const [updateTaskDefinition] = useUpdateTaskDefinitionMutation();
  const [upsertTaskDefinitionProviderConfig] =
    useUpsertTaskDefinitionProviderConfigMutation();
  const [deleteTaskDefinitionProviderConfig] =
    useDeleteTaskDefinitionProviderConfigMutation();

  const { data, loading } = useGetTaskDefinitionQuery({
    variables: {
      id: id ?? '',
    },
    skip: !id,
    fetchPolicy: 'no-cache',
  });

  const { data: providers, loading: providersLoading } = useGetProvidersQuery();

  const {
    data: files,
    loading: filesLoading,
    refetch: reloadFiles,
  } = useGetTaskDefinitionFilesQuery({
    variables: {
      taskDefinitionId: id ?? '',
    },
    skip: !id,
  });

  useEffect(() => {
    if (data?.taskDefinition.providerConfigs) {
      setTaskDefinitionProviderConfigs(data?.taskDefinition.providerConfigs);
    }
    if (data?.taskDefinition.formDefinition) {
      setFormDefinition(
        data?.taskDefinition.formDefinition.map(({ __typename, ...item }) => ({
          ...item,
        })),
      );
    }
    if (data?.taskDefinition.settingsFormDefinition) {
      setSettingsFormDefinition(
        data?.taskDefinition.settingsFormDefinition.map(
          ({ __typename, ...item }) => ({
            ...item,
          }),
        ),
      );
    }
  }, [data]);

  const addTaskDefinitionProviderConfig = useCallback(
    (provider: Provider) => {
      const config = {
        taskDefinitionId: data?.taskDefinition.id ?? '',
        providerId: provider.id,
        messageTemplate: {} as any,
        settingsTemplate: {} as any,
      };

      for (const field of provider.messageTemplateFields) {
        config.messageTemplate[field.name] = field.default ?? null;
      }

      for (const field of provider.settingsTemplateFields) {
        config.settingsTemplate[field.name] = field.default ?? null;
      }

      setTaskDefinitionProviderConfigs((configs) => [
        ...configs.filter((config) => config.providerId !== provider.id),
        config,
      ]);
    },
    [data],
  );

  const handleChangeTaskDefinitionProviderConfig = useCallback(
    (providerId: string) => {
      return (formValues: any) => {
        setTaskDefinitionProviderConfigs((configs) =>
          configs.map((config) => {
            if (config.providerId === providerId) {
              const { messageTemplate, settingsTemplate, followUpPrompts } =
                formValues;

              return {
                ...config,
                messageTemplate,
                settingsTemplate,
                followUpPrompts,
              };
            }

            return config;
          }),
        );
      };
    },
    [],
  );

  const addableProviders = useMemo(() => {
    return (
      providers?.providers.filter(
        (provider) =>
          !taskDefinitionProviderConfigs.some(
            (c) => c.providerId === provider.id && !c.deleted,
          ),
      ) ?? []
    );
  }, [providers, taskDefinitionProviderConfigs]);

  const handleSave = useCallback(async () => {
    await Promise.all([
      updateTaskDefinition({
        variables: {
          id: id ?? '',
          dto: {
            formDefinition,
            settingsFormDefinition,
          },
        },
      }),
      ...taskDefinitionProviderConfigs.map((config) => {
        if (config.deleted) {
          return deleteTaskDefinitionProviderConfig({
            variables: {
              taskDefinitionId: id ?? '',
              providerId: config.providerId,
            },
          });
        }

        return upsertTaskDefinitionProviderConfig({
          variables: {
            taskDefinitionId: id ?? '',
            providerId: config.providerId,
            dto: {
              messageTemplate: config.messageTemplate,
              settingsTemplate: config.settingsTemplate,
              followUpPrompts: config.followUpPrompts?.map(
                (followUpPrompt) => ({
                  label: followUpPrompt.label,
                  prompt: followUpPrompt.prompt,
                }),
              ),
            },
          },
        });
      }),
    ]);

    notification.success({
      message: 'Die Änderungen wurden gespeichert.',
    });
  }, [
    id,
    taskDefinitionProviderConfigs,
    formDefinition,
    settingsFormDefinition,
    updateTaskDefinition,
    upsertTaskDefinitionProviderConfig,
  ]);

  const handleChangeData = useCallback((type: 'form' | 'settings') => {
    return (newData: any) => {
      setFormsData((old) => ({ ...old, [type]: newData }));
    };
  }, []);

  const formVariables = useMemo(() => {
    const vars: any = {
      form: {},
      settings: {},
      company: {
        name: company?.name ?? '',
        description: company?.config.description ?? '',
        facts: company?.config.facts ?? '',
      },
      user: {
        firstName: ownUser?.ownUser.firstName ?? '',
        lastName: ownUser?.ownUser.lastName ?? '',
      },
      files: files?.taskDefinitionFiles?.map((file) => file.id) ?? [],
    };

    const convertValue = (value: any): any => {
      if (value instanceof File) {
        return value.name;
      }
      if (Array.isArray(value)) {
        return value.map((v) => convertValue(v));
      }
      return value;
    };

    for (const field of formDefinition) {
      vars.form[field.name] = convertValue(
        formsData.form[field.name] ?? field.default ?? null,
      );
    }

    for (const field of settingsFormDefinition) {
      vars.settings[field.name] = convertValue(
        formsData.settings[field.name] ?? field.default ?? null,
      );
    }

    return vars;
  }, [
    formDefinition,
    settingsFormDefinition,
    formsData,
    company,
    ownUser,
    files,
  ]);

  const handleEditTabs = useCallback(
    async (
      targetKey: React.MouseEvent | React.KeyboardEvent | string,
      action: 'add' | 'remove',
    ) => {
      if (action === 'add') {
        setAddProviderModalOpen(true);
      }

      if (action === 'remove') {
        if (
          await confirmModal({
            title: 'Modell entfernen',
            content:
              'Mit dem Modell werden auch sämtliche Konfigurationen entfernt und können nicht wiederhergestellt werden!',
            okText: 'Entfernen',
            cancelText: 'Abbrechen',
          })
        ) {
          setTaskDefinitionProviderConfigs((configs) => {
            if (
              data?.taskDefinition.providerConfigs?.find(
                (cfg) => cfg.providerId === targetKey,
              )
            ) {
              // Config was persisted before --> mark for deletion

              return configs.filter(
                (config) => config.providerId !== targetKey,
              );
            }

            // Config was not persisted before --> remove directly
            return configs.map((config) => {
              if (config.providerId === targetKey) {
                return {
                  ...config,
                  deleted: true,
                };
              }
              return config;
            });
          });
        }
      }
    },
    [],
  );

  if (loading || providersLoading) return <Loading />;

  return (
    <div className="w-full">
      <Card>
        <PageHeader
          breadcrumbs={[
            {
              label: 'Aufgaben',
              link: '/admin/taskDefinitions',
            },
            {
              label: data?.taskDefinition.title ?? 'Aufgabe',
              link: '#',
            },
          ]}
        />
      </Card>
      <Tabs
        type="editable-card"
        onEdit={handleEditTabs}
        items={[
          {
            key: 'formDefinition',
            label: 'Nutzereingabe',
            closable: false,
            children: (
              <FormBuilder
                schema={formDefinition}
                onChangeSchema={setFormDefinition}
                onChangeData={handleChangeData('form')}
              />
            ),
          },
          {
            key: 'settingsFormDefinition',
            label: 'Einstellungen',
            closable: false,
            children: (
              <FormBuilder
                schema={settingsFormDefinition}
                onChangeSchema={setSettingsFormDefinition}
                onChangeData={handleChangeData('settings')}
              />
            ),
          },
          {
            key: 'files',
            label: 'Dateien',
            closable: false,
            children: (
              <TaskDefinitionFiles
                taskDefinition={data?.taskDefinition}
                files={files?.taskDefinitionFiles ?? []}
                filesLoading={filesLoading}
                reloadFiles={reloadFiles}
              />
            ),
          },

          ...taskDefinitionProviderConfigs
            .filter((config) => !config.deleted)
            .map((config) => {
              const provider = providers?.providers.find(
                (p) => p.id === config.providerId,
              );
              if (!provider)
                return {
                  key: config.providerId,
                  label: 'UNKNOWN',
                };

              return {
                key: config.providerId,
                label: provider?.name ?? config.providerId,
                children: (
                  <MessageTemplateBuilder
                    provider={provider}
                    config={config}
                    onConfigChange={handleChangeTaskDefinitionProviderConfig(
                      provider.id,
                    )}
                    variables={formVariables}
                    messageSchema={formDefinition}
                    settingsSchema={settingsFormDefinition}
                    files={files?.taskDefinitionFiles ?? []}
                  />
                ),
              };
            }),
        ]}
        renderTabBar={(props, DefaultTabBar) => (
          <div className="bg-white px-4 mt-[-30px] mb-3 z-20 border-x-[1px] border-x-[#f0f0f0] border-b-[1px] border-b-[#f0f0f0]">
            <DefaultTabBar {...props} />
          </div>
        )}
      />

      <Modal
        open={addProviderModalOpen}
        onCancel={() => setAddProviderModalOpen(false)}
        footer={null}
      >
        <Space direction="vertical">
          {addableProviders.map((provider) => (
            <Button
              key={provider.id}
              onClick={() => {
                addTaskDefinitionProviderConfig(provider);
                setAddProviderModalOpen(false);
              }}
            >
              {provider.name}
            </Button>
          ))}
        </Space>
      </Modal>

      <Fab
        onClick={handleSave}
        icon={<FontAwesomeIcon icon={faFloppyDisk} />}
        title="Speichern"
      />
    </div>
  );
};

export default TaskDefinition;
