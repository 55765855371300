export const countries = [
  { id: 4, alpha2: 'af', alpha3: 'afg', name: 'Afghanistan' },
  { id: 818, alpha2: 'eg', alpha3: 'egy', name: 'Ägypten' },
  { id: 8, alpha2: 'al', alpha3: 'alb', name: 'Albanien' },
  { id: 12, alpha2: 'dz', alpha3: 'dza', name: 'Algerien' },
  { id: 20, alpha2: 'ad', alpha3: 'and', name: 'Andorra' },
  { id: 24, alpha2: 'ao', alpha3: 'ago', name: 'Angola' },
  { id: 28, alpha2: 'ag', alpha3: 'atg', name: 'Antigua und Barbuda' },
  { id: 226, alpha2: 'gq', alpha3: 'gnq', name: 'Äquatorialguinea' },
  { id: 32, alpha2: 'ar', alpha3: 'arg', name: 'Argentinien' },
  { id: 51, alpha2: 'am', alpha3: 'arm', name: 'Armenien' },
  { id: 31, alpha2: 'az', alpha3: 'aze', name: 'Aserbaidschan' },
  { id: 231, alpha2: 'et', alpha3: 'eth', name: 'Äthiopien' },
  { id: 36, alpha2: 'au', alpha3: 'aus', name: 'Australien' },
  { id: 44, alpha2: 'bs', alpha3: 'bhs', name: 'Bahamas' },
  { id: 48, alpha2: 'bh', alpha3: 'bhr', name: 'Bahrain' },
  { id: 50, alpha2: 'bd', alpha3: 'bgd', name: 'Bangladesch' },
  { id: 52, alpha2: 'bb', alpha3: 'brb', name: 'Barbados' },
  { id: 112, alpha2: 'by', alpha3: 'blr', name: 'Belarus' },
  { id: 56, alpha2: 'be', alpha3: 'bel', name: 'Belgien' },
  { id: 84, alpha2: 'bz', alpha3: 'blz', name: 'Belize' },
  { id: 204, alpha2: 'bj', alpha3: 'ben', name: 'Benin' },
  { id: 64, alpha2: 'bt', alpha3: 'btn', name: 'Bhutan' },
  { id: 68, alpha2: 'bo', alpha3: 'bol', name: 'Bolivien' },
  { id: 70, alpha2: 'ba', alpha3: 'bih', name: 'Bosnien und Herzegowina' },
  { id: 72, alpha2: 'bw', alpha3: 'bwa', name: 'Botswana' },
  { id: 76, alpha2: 'br', alpha3: 'bra', name: 'Brasilien' },
  { id: 96, alpha2: 'bn', alpha3: 'brn', name: 'Brunei' },
  { id: 100, alpha2: 'bg', alpha3: 'bgr', name: 'Bulgarien' },
  { id: 854, alpha2: 'bf', alpha3: 'bfa', name: 'Burkina Faso' },
  { id: 108, alpha2: 'bi', alpha3: 'bdi', name: 'Burundi' },
  { id: 152, alpha2: 'cl', alpha3: 'chl', name: 'Chile' },
  { id: 156, alpha2: 'cn', alpha3: 'chn', name: 'China, Volksrepublik' },
  { id: 188, alpha2: 'cr', alpha3: 'cri', name: 'Costa Rica' },
  { id: 208, alpha2: 'dk', alpha3: 'dnk', name: 'Dänemark' },
  { id: 276, alpha2: 'de', alpha3: 'deu', name: 'Deutschland' },
  { id: 212, alpha2: 'dm', alpha3: 'dma', name: 'Dominica' },
  { id: 214, alpha2: 'do', alpha3: 'dom', name: 'Dominikanische Republik' },
  { id: 262, alpha2: 'dj', alpha3: 'dji', name: 'Dschibuti' },
  { id: 218, alpha2: 'ec', alpha3: 'ecu', name: 'Ecuador' },
  { id: 384, alpha2: 'ci', alpha3: 'civ', name: 'Elfenbeinküste' },
  { id: 222, alpha2: 'sv', alpha3: 'slv', name: 'El Salvador' },
  { id: 232, alpha2: 'er', alpha3: 'eri', name: 'Eritrea' },
  { id: 233, alpha2: 'ee', alpha3: 'est', name: 'Estland' },
  { id: 748, alpha2: 'sz', alpha3: 'swz', name: 'Eswatini' },
  { id: 242, alpha2: 'fj', alpha3: 'fji', name: 'Fidschi' },
  { id: 246, alpha2: 'fi', alpha3: 'fin', name: 'Finnland' },
  { id: 250, alpha2: 'fr', alpha3: 'fra', name: 'Frankreich' },
  { id: 266, alpha2: 'ga', alpha3: 'gab', name: 'Gabun' },
  { id: 270, alpha2: 'gm', alpha3: 'gmb', name: 'Gambia' },
  { id: 268, alpha2: 'ge', alpha3: 'geo', name: 'Georgien' },
  { id: 288, alpha2: 'gh', alpha3: 'gha', name: 'Ghana' },
  { id: 308, alpha2: 'gd', alpha3: 'grd', name: 'Grenada' },
  { id: 300, alpha2: 'gr', alpha3: 'grc', name: 'Griechenland' },
  { id: 320, alpha2: 'gt', alpha3: 'gtm', name: 'Guatemala' },
  { id: 324, alpha2: 'gn', alpha3: 'gin', name: 'Guinea' },
  { id: 624, alpha2: 'gw', alpha3: 'gnb', name: 'Guinea-Bissau' },
  { id: 328, alpha2: 'gy', alpha3: 'guy', name: 'Guyana' },
  { id: 332, alpha2: 'ht', alpha3: 'hti', name: 'Haiti' },
  { id: 340, alpha2: 'hn', alpha3: 'hnd', name: 'Honduras' },
  { id: 356, alpha2: 'in', alpha3: 'ind', name: 'Indien' },
  { id: 360, alpha2: 'id', alpha3: 'idn', name: 'Indonesien' },
  { id: 368, alpha2: 'iq', alpha3: 'irq', name: 'Irak' },
  { id: 364, alpha2: 'ir', alpha3: 'irn', name: 'Iran' },
  { id: 372, alpha2: 'ie', alpha3: 'irl', name: 'Irland' },
  { id: 352, alpha2: 'is', alpha3: 'isl', name: 'Island' },
  { id: 376, alpha2: 'il', alpha3: 'isr', name: 'Israel' },
  { id: 380, alpha2: 'it', alpha3: 'ita', name: 'Italien' },
  { id: 388, alpha2: 'jm', alpha3: 'jam', name: 'Jamaika' },
  { id: 392, alpha2: 'jp', alpha3: 'jpn', name: 'Japan' },
  { id: 887, alpha2: 'ye', alpha3: 'yem', name: 'Jemen' },
  { id: 400, alpha2: 'jo', alpha3: 'jor', name: 'Jordanien' },
  { id: 116, alpha2: 'kh', alpha3: 'khm', name: 'Kambodscha' },
  { id: 120, alpha2: 'cm', alpha3: 'cmr', name: 'Kamerun' },
  { id: 124, alpha2: 'ca', alpha3: 'can', name: 'Kanada' },
  { id: 132, alpha2: 'cv', alpha3: 'cpv', name: 'Kap Verde' },
  { id: 398, alpha2: 'kz', alpha3: 'kaz', name: 'Kasachstan' },
  { id: 634, alpha2: 'qa', alpha3: 'qat', name: 'Katar' },
  { id: 404, alpha2: 'ke', alpha3: 'ken', name: 'Kenia' },
  { id: 417, alpha2: 'kg', alpha3: 'kgz', name: 'Kirgisistan' },
  { id: 296, alpha2: 'ki', alpha3: 'kir', name: 'Kiribati' },
  { id: 170, alpha2: 'co', alpha3: 'col', name: 'Kolumbien' },
  { id: 174, alpha2: 'km', alpha3: 'com', name: 'Komoren' },
  {
    id: 180,
    alpha2: 'cd',
    alpha3: 'cod',
    name: 'Kongo, Demokratische Republik',
  },
  { id: 178, alpha2: 'cg', alpha3: 'cog', name: 'Kongo, Republik' },
  { id: 408, alpha2: 'kp', alpha3: 'prk', name: 'Korea, Nord (Nordkorea)' },
  { id: 410, alpha2: 'kr', alpha3: 'kor', name: 'Korea, Süd (Südkorea)' },
  { id: 191, alpha2: 'hr', alpha3: 'hrv', name: 'Kroatien' },
  { id: 192, alpha2: 'cu', alpha3: 'cub', name: 'Kuba' },
  { id: 414, alpha2: 'kw', alpha3: 'kwt', name: 'Kuwait' },
  { id: 418, alpha2: 'la', alpha3: 'lao', name: 'Laos' },
  { id: 426, alpha2: 'ls', alpha3: 'lso', name: 'Lesotho' },
  { id: 428, alpha2: 'lv', alpha3: 'lva', name: 'Lettland' },
  { id: 422, alpha2: 'lb', alpha3: 'lbn', name: 'Libanon' },
  { id: 430, alpha2: 'lr', alpha3: 'lbr', name: 'Liberia' },
  { id: 434, alpha2: 'ly', alpha3: 'lby', name: 'Libyen' },
  { id: 438, alpha2: 'li', alpha3: 'lie', name: 'Liechtenstein' },
  { id: 440, alpha2: 'lt', alpha3: 'ltu', name: 'Litauen' },
  { id: 442, alpha2: 'lu', alpha3: 'lux', name: 'Luxemburg' },
  { id: 450, alpha2: 'mg', alpha3: 'mdg', name: 'Madagaskar' },
  { id: 454, alpha2: 'mw', alpha3: 'mwi', name: 'Malawi' },
  { id: 458, alpha2: 'my', alpha3: 'mys', name: 'Malaysia' },
  { id: 462, alpha2: 'mv', alpha3: 'mdv', name: 'Malediven' },
  { id: 466, alpha2: 'ml', alpha3: 'mli', name: 'Mali' },
  { id: 470, alpha2: 'mt', alpha3: 'mlt', name: 'Malta' },
  { id: 504, alpha2: 'ma', alpha3: 'mar', name: 'Marokko' },
  { id: 584, alpha2: 'mh', alpha3: 'mhl', name: 'Marshallinseln' },
  { id: 478, alpha2: 'mr', alpha3: 'mrt', name: 'Mauretanien' },
  { id: 480, alpha2: 'mu', alpha3: 'mus', name: 'Mauritius' },
  { id: 484, alpha2: 'mx', alpha3: 'mex', name: 'Mexiko' },
  { id: 583, alpha2: 'fm', alpha3: 'fsm', name: 'Mikronesien' },
  { id: 498, alpha2: 'md', alpha3: 'mda', name: 'Moldau' },
  { id: 492, alpha2: 'mc', alpha3: 'mco', name: 'Monaco' },
  { id: 496, alpha2: 'mn', alpha3: 'mng', name: 'Mongolei' },
  { id: 499, alpha2: 'me', alpha3: 'mne', name: 'Montenegro' },
  { id: 508, alpha2: 'mz', alpha3: 'moz', name: 'Mosambik' },
  { id: 104, alpha2: 'mm', alpha3: 'mmr', name: 'Myanmar' },
  { id: 516, alpha2: 'na', alpha3: 'nam', name: 'Namibia' },
  { id: 520, alpha2: 'nr', alpha3: 'nru', name: 'Nauru' },
  { id: 524, alpha2: 'np', alpha3: 'npl', name: 'Nepal' },
  { id: 554, alpha2: 'nz', alpha3: 'nzl', name: 'Neuseeland' },
  { id: 558, alpha2: 'ni', alpha3: 'nic', name: 'Nicaragua' },
  { id: 528, alpha2: 'nl', alpha3: 'nld', name: 'Niederlande' },
  { id: 562, alpha2: 'ne', alpha3: 'ner', name: 'Niger' },
  { id: 566, alpha2: 'ng', alpha3: 'nga', name: 'Nigeria' },
  { id: 807, alpha2: 'mk', alpha3: 'mkd', name: 'Nordmazedonien' },
  { id: 578, alpha2: 'no', alpha3: 'nor', name: 'Norwegen' },
  { id: 512, alpha2: 'om', alpha3: 'omn', name: 'Oman' },
  { id: 40, alpha2: 'at', alpha3: 'aut', name: 'Österreich' },
  { id: 626, alpha2: 'tl', alpha3: 'tls', name: 'Osttimor' },
  { id: 586, alpha2: 'pk', alpha3: 'pak', name: 'Pakistan' },
  { id: 585, alpha2: 'pw', alpha3: 'plw', name: 'Palau' },
  { id: 591, alpha2: 'pa', alpha3: 'pan', name: 'Panama' },
  { id: 598, alpha2: 'pg', alpha3: 'png', name: 'Papua-Neuguinea' },
  { id: 600, alpha2: 'py', alpha3: 'pry', name: 'Paraguay' },
  { id: 604, alpha2: 'pe', alpha3: 'per', name: 'Peru' },
  { id: 608, alpha2: 'ph', alpha3: 'phl', name: 'Philippinen' },
  { id: 616, alpha2: 'pl', alpha3: 'pol', name: 'Polen' },
  { id: 620, alpha2: 'pt', alpha3: 'prt', name: 'Portugal' },
  { id: 646, alpha2: 'rw', alpha3: 'rwa', name: 'Ruanda' },
  { id: 642, alpha2: 'ro', alpha3: 'rou', name: 'Rumänien' },
  { id: 643, alpha2: 'ru', alpha3: 'rus', name: 'Russland' },
  { id: 90, alpha2: 'sb', alpha3: 'slb', name: 'Salomonen' },
  { id: 894, alpha2: 'zm', alpha3: 'zmb', name: 'Sambia' },
  { id: 882, alpha2: 'ws', alpha3: 'wsm', name: 'Samoa' },
  { id: 674, alpha2: 'sm', alpha3: 'smr', name: 'San Marino' },
  { id: 678, alpha2: 'st', alpha3: 'stp', name: 'São Tomé und Príncipe' },
  { id: 682, alpha2: 'sa', alpha3: 'sau', name: 'Saudi-Arabien' },
  { id: 752, alpha2: 'se', alpha3: 'swe', name: 'Schweden' },
  { id: 756, alpha2: 'ch', alpha3: 'che', name: 'Schweiz' },
  { id: 686, alpha2: 'sn', alpha3: 'sen', name: 'Senegal' },
  { id: 688, alpha2: 'rs', alpha3: 'srb', name: 'Serbien' },
  { id: 690, alpha2: 'sc', alpha3: 'syc', name: 'Seychellen' },
  { id: 694, alpha2: 'sl', alpha3: 'sle', name: 'Sierra Leone' },
  { id: 716, alpha2: 'zw', alpha3: 'zwe', name: 'Simbabwe' },
  { id: 702, alpha2: 'sg', alpha3: 'sgp', name: 'Singapur' },
  { id: 703, alpha2: 'sk', alpha3: 'svk', name: 'Slowakei' },
  { id: 705, alpha2: 'si', alpha3: 'svn', name: 'Slowenien' },
  { id: 706, alpha2: 'so', alpha3: 'som', name: 'Somalia' },
  { id: 724, alpha2: 'es', alpha3: 'esp', name: 'Spanien' },
  { id: 144, alpha2: 'lk', alpha3: 'lka', name: 'Sri Lanka' },
  { id: 659, alpha2: 'kn', alpha3: 'kna', name: 'St. Kitts und Nevis' },
  { id: 662, alpha2: 'lc', alpha3: 'lca', name: 'St. Lucia' },
  {
    id: 670,
    alpha2: 'vc',
    alpha3: 'vct',
    name: 'St. Vincent und die Grenadinen',
  },
  { id: 710, alpha2: 'za', alpha3: 'zaf', name: 'Südafrika' },
  { id: 729, alpha2: 'sd', alpha3: 'sdn', name: 'Sudan' },
  { id: 728, alpha2: 'ss', alpha3: 'ssd', name: 'Südsudan' },
  { id: 740, alpha2: 'sr', alpha3: 'sur', name: 'Suriname' },
  { id: 760, alpha2: 'sy', alpha3: 'syr', name: 'Syrien' },
  { id: 762, alpha2: 'tj', alpha3: 'tjk', name: 'Tadschikistan' },
  { id: 834, alpha2: 'tz', alpha3: 'tza', name: 'Tansania' },
  { id: 764, alpha2: 'th', alpha3: 'tha', name: 'Thailand' },
  { id: 768, alpha2: 'tg', alpha3: 'tgo', name: 'Togo' },
  { id: 776, alpha2: 'to', alpha3: 'ton', name: 'Tonga' },
  { id: 780, alpha2: 'tt', alpha3: 'tto', name: 'Trinidad und Tobago' },
  { id: 148, alpha2: 'td', alpha3: 'tcd', name: 'Tschad' },
  { id: 203, alpha2: 'cz', alpha3: 'cze', name: 'Tschechien' },
  { id: 788, alpha2: 'tn', alpha3: 'tun', name: 'Tunesien' },
  { id: 792, alpha2: 'tr', alpha3: 'tur', name: 'Türkei' },
  { id: 795, alpha2: 'tm', alpha3: 'tkm', name: 'Turkmenistan' },
  { id: 798, alpha2: 'tv', alpha3: 'tuv', name: 'Tuvalu' },
  { id: 800, alpha2: 'ug', alpha3: 'uga', name: 'Uganda' },
  { id: 804, alpha2: 'ua', alpha3: 'ukr', name: 'Ukraine' },
  { id: 348, alpha2: 'hu', alpha3: 'hun', name: 'Ungarn' },
  { id: 858, alpha2: 'uy', alpha3: 'ury', name: 'Uruguay' },
  { id: 860, alpha2: 'uz', alpha3: 'uzb', name: 'Usbekistan' },
  { id: 548, alpha2: 'vu', alpha3: 'vut', name: 'Vanuatu' },
  { id: 862, alpha2: 've', alpha3: 'ven', name: 'Venezuela' },
  {
    id: 784,
    alpha2: 'ae',
    alpha3: 'are',
    name: 'Vereinigte Arabische Emirate',
  },
  { id: 840, alpha2: 'us', alpha3: 'usa', name: 'Vereinigte Staaten' },
  { id: 826, alpha2: 'gb', alpha3: 'gbr', name: 'Vereinigtes Königreich' },
  { id: 704, alpha2: 'vn', alpha3: 'vnm', name: 'Vietnam' },
  {
    id: 140,
    alpha2: 'cf',
    alpha3: 'caf',
    name: 'Zentralafrikanische Republik',
  },
  { id: 196, alpha2: 'cy', alpha3: 'cyp', name: 'Zypern' },
];
