import { Form, Select } from 'antd';
import React from 'react';
import {
  File as ApiFile,
  FieldDefinitionType,
  FormFieldDefinition,
  TemplateField,
} from '../../graphql/schema';
import formItemProps from '../../helper/formItemProps';
import CodeInput from '../form/CodeInput';
import CodeInputBoolean from '../form/CodeInputBoolean';
import VariablesSelect from '../form/VariablesSelect';

interface Props {
  name: string | string[];
  field: TemplateField;
  variables: any;
  messageSchema: FormFieldDefinition[];
  settingsSchema: FormFieldDefinition[];
  files: ApiFile[];
}

const TemplateFormField = ({
  name,
  field,
  variables,
  messageSchema,
  settingsSchema,
  files,
}: Props) => {
  return (
    <Form.Item
      {...formItemProps(name, field.label, !!field.required)}
      key={field.name}
      tooltip={field.tooltip}
    >
      {field.type === FieldDefinitionType.BOOLEAN && (
        <CodeInputBoolean variables={variables} />
      )}
      {field.type === FieldDefinitionType.STRING && (
        <CodeInput variables={variables} />
      )}
      {field.type === FieldDefinitionType.NUMBER && (
        <CodeInput variables={variables} />
      )}
      {field.type === FieldDefinitionType.OPTIONS && (
        <Select options={field.options ?? []} />
      )}
      {field.type === FieldDefinitionType.FILE && (
        <VariablesSelect
          variables={variables}
          messageSchema={messageSchema}
          settingsSchema={settingsSchema}
          files={files}
          single
        />
      )}
      {field.type === FieldDefinitionType.FILES && (
        <VariablesSelect
          variables={variables}
          messageSchema={messageSchema}
          settingsSchema={settingsSchema}
          files={files}
        />
      )}
    </Form.Item>
  );
};

export default TemplateFormField;
