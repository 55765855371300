import { Select } from 'antd';
import React from 'react';
import { languages } from '../../helper/languages';

interface Props {
  value?: string;
  onChange?: (id: string) => void;
}

interface Option {
  label: string;
  value: string;
}

const options: Option[] =
  Object.values(languages).map((label) => {
    return {
      label,
      value: label,
    };
  }) || [];

const LanguageSelect = ({ value, onChange }: Props) => {
  return (
    <Select
      onChange={onChange}
      value={value}
      options={options}
      className="w-full"
      showSearch
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
    />
  );
};

export default LanguageSelect;
