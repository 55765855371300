import type { FormItemProps } from 'antd/lib/form/FormItem';

const formItemProps = (
  name: string | (string | number)[],
  label: string,
  required = false,
): FormItemProps => {
  return {
    name,
    label,
    rules: [
      {
        required,
        message: `${label} ist ein Pflichtfeld!`,
      },
    ],
  };
};

export default formItemProps;
