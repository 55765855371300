import { faHouse } from '@fortawesome/pro-light-svg-icons';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Loading from '../components/base/Loading';
import PageHeader from '../components/base/PageHeader';
import CategoriesSidebar from '../components/tiles/CategoriesSidebar';
import TaskDefinitionTile from '../components/tiles/TaskDefinitionTile';
import TilesGrid from '../components/tiles/TilesGrid';
import {
  Category,
  useGetModulesWithCategoriesQuery,
  useGetTaskDefinitionsForCompanyLazyQuery,
  useGetTaskDefinitionsForCompanyQuery,
  useGetTaskDefinitionsLazyQuery,
  useGetTaskDefinitionsQuery,
} from '../graphql/schema';
import taskDefinitions from './admin/TaskDefinitions';

const TaskOverview = () => {
  const { moduleId } = useParams();

  const [category, setCategory] = useState<Category | undefined>();

  const { data, loading } = useGetTaskDefinitionsForCompanyQuery({
    variables: {
      moduleId: moduleId ?? '',
    },
  });

  const { data: modules, loading: modulesLoading } =
    useGetModulesWithCategoriesQuery();

  const module = useMemo(
    () => modules?.modulesWithCategories.find((m) => m.id === moduleId),
    [moduleId, modules],
  );

  if (modulesLoading || !module) return <Loading />;

  return (
    <>
      <PageHeader
        breadcrumbs={[
          {
            icon: faHouse,
            link: '/',
          },
          {
            label: module?.title,
          },
          ...(category
            ? [
                {
                  label: category?.title,
                },
              ]
            : []),
        ]}
      />
      <TilesGrid
        sidebar={
          <CategoriesSidebar
            module={module}
            selectCategory={setCategory}
            activeCategoryId={category?.id}
          />
        }
        loading={loading}
      >
        {!loading &&
          data?.taskDefinitionsForCompany
            .filter(
              (taskDefinition) =>
                !category || taskDefinition.categoryId === category?.id,
            )
            .sort((a, b) => a.title.localeCompare(b.title))
            .map((taskDefinition) => (
              <TaskDefinitionTile
                taskDefinition={taskDefinition}
                key={taskDefinition.id}
              />
            ))}
      </TilesGrid>
    </>
  );
};

export default TaskOverview;
