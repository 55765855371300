import React from 'react';

export interface ThemeColors {
  primary: string;
  secondary: string;
  primaryText: string;
}

export const defaultThemeColors: ThemeColors = {
  primary: '#222',
  primaryText: '#fff',
  secondary: '#EEF8FF',
};

interface ThemeContextProps {
  colors: ThemeColors;
  setColors: (colors: ThemeColors) => void;
}

export const ThemeContext = React.createContext<ThemeContextProps>({
  colors: defaultThemeColors,
  setColors: () => {},
});
