import { faHouse } from '@fortawesome/pro-light-svg-icons';
import Loading from '../components/base/Loading';
import PageHeader from '../components/base/PageHeader';
import ModuleTile from '../components/tiles/ModuleTile';
import TilesGrid from '../components/tiles/TilesGrid';
import {
  useGetModulesForCompanyQuery,
  useGetModulesWithCategoriesQuery,
} from '../graphql/schema';

const Overview = () => {
  const { data, loading } = useGetModulesForCompanyQuery();

  if (loading) return <Loading />;

  return (
    <>
      <PageHeader
        breadcrumbs={[
          {
            icon: faHouse,
            link: '/',
          },
        ]}
      />
      <TilesGrid>
        {data?.modulesForCompany
          .map((m) => m)
          .sort((a, b) => a.title.localeCompare(b.title))
          .map((module) => (
            <ModuleTile module={module} key={module.id} />
          ))}
      </TilesGrid>
    </>
  );
};

export default Overview;
