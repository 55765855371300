import { faPlus, faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, Input, Space } from 'antd';
import React from 'react';
import confirmModal from '../../helper/confirmModal';
import formItemProps from '../../helper/formItemProps';
import CodeInput from '../form/CodeInput';

type Props = {
  variables: any;
};

const FollowUpPrompts = ({ variables }: Props) => {
  return (
    <Form.List name="followUpPrompts">
      {(fields, { add, remove }) => (
        <Space direction="vertical" className="w-full">
          {fields.map((field) => (
            <div key={field.name} className="border p-4 rounded relative">
              <Form.Item
                {...formItemProps(
                  [field.name, 'label'],
                  'Angezeigte Nachricht',
                  true,
                )}
              >
                <Input />
              </Form.Item>
              <Form.Item
                {...formItemProps([field.name, 'prompt'], 'Prompt', true)}
              >
                <CodeInput variables={variables} />
              </Form.Item>

              <Button
                type="text"
                onClick={async () => {
                  if (
                    await confirmModal({
                      title: 'Folge-Prompt löschen',
                      content:
                        'Das Löschen kann nicht rückgängig gemacht werden!',
                      okText: 'Löschen',
                      cancelText: 'Abbrechen',
                    })
                  ) {
                    remove(field.name);
                  }
                }}
                className="absolute top-1 right-1"
              >
                <FontAwesomeIcon icon={faTrashCan} />
              </Button>
            </div>
          ))}

          <Button
            size="small"
            type="dashed"
            onClick={() => add()}
            icon={<FontAwesomeIcon icon={faPlus} />}
          >
            Prompt hinzufügen
          </Button>
        </Space>
      )}
    </Form.List>
  );
};

export default FollowUpPrompts;
