import { useAuth } from '@app/react-cognito';
import {
  faChevronDown,
  faDoorOpen,
  faKey,
  faUser,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, Space } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useCompany from '../../context/company/useCompany';
import Logo from './Logo';
import Navigation from './Navigation';

const Header = () => {
  const navigate = useNavigate();
  const { logout, userAttributes } = useAuth();
  const { company, companies, changeCompany } = useCompany();

  return (
    <div className="flex w-full justify-between mb-4">
      <div className="w-[200px] flex items-center">
        {companies.length > 1 ? (
          <Dropdown
            menu={{
              items: companies.map((company) => ({
                label: company.name,
                key: company.id,
                onClick: () => {
                  changeCompany(company.id);
                },
              })),
            }}
            trigger={['click']}
          >
            <a
              onClick={(e) => e.preventDefault()}
              className="flex items-center cursor-pointer space-x-2"
            >
              <Logo company={company} />
              <FontAwesomeIcon icon={faChevronDown} size="xs" />
            </a>
          </Dropdown>
        ) : (
          <Logo company={company} />
        )}
      </div>
      <div className="flex-1 flex justify-center items-center">
        <Navigation />
      </div>
      <div className="w-[200px] flex items-center justify-end">
        <Dropdown
          menu={{
            items: [
              {
                key: 'username',
                label: userAttributes?.email ?? '',
                disabled: true,
              },
              {
                key: 'logout',
                label: (
                  <Space>
                    <FontAwesomeIcon icon={faDoorOpen} />
                    Abmelden
                  </Space>
                ),
                onClick: logout,
              },
              {
                key: 'changePassword',
                label: (
                  <Space>
                    <FontAwesomeIcon icon={faKey} />
                    Passwort ändern
                  </Space>
                ),
                onClick: () => navigate('/changePassword'),
              },
            ],
          }}
          trigger={['click']}
        >
          <a
            onClick={(e) => e.preventDefault()}
            className="flex items-center cursor-pointer space-x-2 bg-white rounded-full w-[40px] h-[40px] justify-center"
          >
            <FontAwesomeIcon icon={faUser} size="xs" />
          </a>
        </Dropdown>
      </div>
    </div>
  );
};

export default Header;
