import React from 'react';
import { Company } from '../../graphql/schema';

interface Props {
  company?: Company;
}

const Logo = ({ company }: Props) => {
  if (company?.config?.logo) {
    return (
      <img
        src={company?.config.logo}
        alt={company?.name}
        className="max-h-[40px]"
      />
    );
  }

  return <span>{company?.name}</span>;
};
export default Logo;
