import {
  faChevronDown,
  faChevronUp,
  faPencil,
  faPlusCircle,
  faTrash,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Drawer, Form } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { FormFieldDefinition } from '../../graphql/schema';
import confirmModal from '../../helper/confirmModal';
import FormField from './FormField';
import FormFieldSettings from './FormFieldSettings';

interface Props {
  onChangeSchema: (schema: FormFieldDefinition[]) => void;
  schema: FormFieldDefinition[];
  onChangeData: (data: any) => void;
}

const FormBuilder = ({ schema, onChangeSchema, onChangeData }: Props) => {
  const [renderedForm] = Form.useForm();
  const [settingsForm] = Form.useForm<FormFieldDefinition>();
  const [drawerElement, setDrawerElement] = useState<FormFieldDefinition>();

  useEffect(() => {
    if (!drawerElement) return;

    settingsForm.setFieldsValue({
      ...drawerElement,
      options: drawerElement.options?.map(({ label, value }) => ({
        label,
        value,
      })),
    });
  }, [drawerElement]);

  const handleUpdateFormField = useCallback(async () => {
    if (!drawerElement) return;

    try {
      const formValues = await settingsForm.validateFields();

      onChangeSchema(
        schema.map((field) => {
          if (field.name === drawerElement.name) {
            return formValues;
          }
          return field;
        }),
      );

      setDrawerElement(undefined);
    } catch (e) {}
  }, [drawerElement, schema]);

  const handleAddField = useCallback(() => {
    let newFieldName = 'newField';
    if (schema.find((f) => f.name === newFieldName)) {
      let i = 1;
      while (schema.find((f) => f.name === `${newFieldName}${i}`)) {
        i++;
      }
      newFieldName = `${newFieldName}${i}`;
    }
    onChangeSchema([
      ...schema,
      {
        name: newFieldName,
        type: 'text', // TODO
        label: 'New field',
      },
    ]);
  }, [schema]);

  const handleRemoveField = useCallback(
    (field: string) => {
      return async () => {
        if (
          await confirmModal({
            title: 'Delete field',
            type: 'warning',
            content: `You are about to delete the field "${field}"`,
            okText: 'Delete',
            cancelText: 'Cancel',
          })
        ) {
          onChangeSchema(schema.filter((f) => f.name !== field));
        }
      };
    },
    [schema],
  );

  const handleMoveField = useCallback(
    (field: string, direction: 'up' | 'down') => {
      return async () => {
        // Reorder keys
        const currentKeyIndex = schema.findIndex((f) => f.name === field);
        const newKeyIndex =
          direction === 'up' ? currentKeyIndex - 1 : currentKeyIndex + 1;

        if (newKeyIndex < 0 || newKeyIndex >= schema.length) return;

        const fieldDefinition = schema[currentKeyIndex];
        schema.splice(currentKeyIndex, 1);
        schema.splice(newKeyIndex, 0, fieldDefinition);

        onChangeSchema(schema);
      };
    },
    [schema],
  );

  const handleChangeData = useCallback(() => {
    const fields = renderedForm.getFieldsValue();
    for (const field of schema) {
      if (fields[field.name] === undefined) {
        fields[field.name] = field.default ?? null;
      }
    }

    onChangeData(fields);
  }, [onChangeData, renderedForm, schema]);

  return (
    <>
      <div>
        <Form form={renderedForm} layout="vertical" onBlur={handleChangeData}>
          <div className="flex space-y-2 flex-col">
            {schema.map((field) => (
              <div
                key={field.name}
                className="border border-gray-300 p-2 relative rounded-lg"
              >
                <FormField field={field}>
                  <div className="absolute top-0 right-0 text-xs p-2 flex space-x-2">
                    <div>{field.name}</div>

                    <div
                      className="cursor-pointer"
                      onClick={handleRemoveField(field.name)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </div>

                    <div
                      className="cursor-pointer"
                      onClick={handleMoveField(field.name, 'up')}
                    >
                      <FontAwesomeIcon icon={faChevronUp} />
                    </div>

                    <div
                      className="cursor-pointer"
                      onClick={handleMoveField(field.name, 'down')}
                    >
                      <FontAwesomeIcon icon={faChevronDown} />
                    </div>

                    <div
                      className="cursor-pointer"
                      onClick={() => setDrawerElement(field)}
                    >
                      <FontAwesomeIcon icon={faPencil} />
                    </div>
                  </div>
                </FormField>
              </div>
            ))}
            <Button
              type="dashed"
              onClick={handleAddField}
              block
              icon={
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  className="cursor-pointer"
                />
              }
            >
              Feld hinzufügen
            </Button>
          </div>
        </Form>
      </div>

      <Drawer
        title="Edit field"
        open={!!drawerElement}
        onClose={() => setDrawerElement(undefined)}
        extra={
          <Button onClick={() => handleUpdateFormField()} type="primary">
            Speichern
          </Button>
        }
      >
        <Form form={settingsForm} layout="vertical">
          <FormFieldSettings />
        </Form>
      </Drawer>
    </>
  );
};

export default FormBuilder;
