import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from 'antd';
import { useCallback, useEffect } from 'react';
import { b } from 'vite/dist/node/types.d-aGj9QkWt';
import { FormFieldDefinition } from '../../graphql/schema';
import FancyButton from '../base/FancyButton';
import FormField from '../formBuilder/FormField';

interface Props {
  schema?: FormFieldDefinition[] | null;
  values?: any;
  onSubmit: (values: any) => void;
  saveButtonLabel: string;
  loading?: boolean;
  hideSaveButtonIcon?: boolean;
}

const TaskDefinitionForm = ({
  values,
  schema,
  onSubmit,
  saveButtonLabel,
  loading,
  hideSaveButtonIcon,
}: Props) => {
  const [form] = Form.useForm();

  const handleSubmit = useCallback(
    (formValues: any) => {
      if (!loading) onSubmit(formValues);
    },
    [onSubmit, loading],
  );

  useEffect(() => {
    form.setFieldsValue(values);
  }, [values, form]);

  return (
    <Form form={form} onFinish={handleSubmit} layout="vertical">
      <div className="flex space-y-2 flex-col">
        {schema?.map((field) => (
          <FormField field={field} key={field.name} />
        ))}
        <div className="py-7 text-center">
          <FancyButton htmlType="submit" loading={loading}>
            {saveButtonLabel}
            {hideSaveButtonIcon !== true && (
              <FontAwesomeIcon icon={faArrowRight} />
            )}
          </FancyButton>
        </div>
      </div>
    </Form>
  );
};

export default TaskDefinitionForm;
