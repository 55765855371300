import {
  Card,
  Checkbox,
  Col,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Row,
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { Columns } from '../../components/admin/Table';
import TableWithDrawer from '../../components/admin/TableWithDrawer';
import PageHeader from '../../components/base/PageHeader';
import CompanySelect from '../../components/form/CompanySelect';
import ProviderSelect from '../../components/form/ProviderSelect';
import {
  GetProviderConfigsQuery,
  GetProviderConfigsQueryVariables,
  ProviderConfig,
  useCreateProviderConfigMutation,
  useDeleteProviderConfigMutation,
  useGetProviderConfigsQuery,
  useGetProvidersQuery,
  useUpdateProviderConfigMutation,
} from '../../graphql/schema';
import formItemProps from '../../helper/formItemProps';

const columns: Columns<ProviderConfig> = {
  providerId: 'KI',
};

interface FormFields {
  config: any;
  companyId?: string;
  providerId: string;
}

const ProviderConfigs = () => {
  const [companyId, setCompanyId] = useState<string | undefined | null>(
    window.localStorage.getItem('providerConfigCompanyId') ?? null,
  );

  useEffect(() => {
    if (companyId) {
      window.localStorage.setItem('providerConfigCompanyId', companyId ?? '');
    } else {
      window.localStorage.removeItem('providerConfigCompanyId');
    }
  }, [companyId]);

  const { data: providers } = useGetProvidersQuery();

  const [create] = useCreateProviderConfigMutation();
  const [update] = useUpdateProviderConfigMutation();
  const [remove] = useDeleteProviderConfigMutation();

  const handleCreate = useCallback(
    async (values: FormFields) => {
      await create({
        variables: {
          companyId: values.companyId,
          providerId: values.providerId,
          dto: {
            config: values.config,
          },
        },
        refetchQueries: ['GetProviderConfigs', 'GetGlobalProviderConfigs'],
      });
    },
    [create],
  );

  const handleUpdate = useCallback(
    async (_: string, values: FormFields, item: ProviderConfig) => {
      await update({
        variables: {
          companyId: item.companyId,
          providerId: item.providerId,
          dto: {
            config: values.config,
          },
        },
        refetchQueries: ['GetProviderConfigs', 'GetGlobalProviderConfigs'],
      });
    },
    [update],
  );

  const handleDelete = useCallback(
    async (_: string, item: ProviderConfig) => {
      await remove({
        variables: {
          companyId: item.companyId,
          providerId: item.providerId,
        },
        refetchQueries: ['GetProviderConfigs', 'GetGlobalProviderConfigs'],
      });
    },
    [remove],
  );

  const formRenderer = (type: 'create' | 'edit') => (form: FormInstance) => {
    return (
      <Form layout="vertical" form={form}>
        {type === 'create' && (
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item {...formItemProps('providerId', 'KI', true)}>
                <ProviderSelect />
              </Form.Item>
            </Col>
          </Row>
        )}

        {type === 'create' && (
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item {...formItemProps('companyId', 'Firma', false)}>
                <CompanySelect nullable />
              </Form.Item>
            </Col>
          </Row>
        )}

        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.providerId !== currentValues.providerId
          }
        >
          {({ getFieldValue }) => {
            const providerId = getFieldValue('providerId');
            const provider = providers?.providers.find(
              (p) => p.id === providerId,
            );

            if (!provider) return <div />;

            return provider.configFields.map((field) => (
              <Form.Item
                {...formItemProps(
                  ['config', field.name],
                  field.label,
                  !!field.required,
                )}
                key={field.name}
                valuePropName={field.type === 'BOOLEAN' ? 'checked' : undefined}
              >
                {field.type === 'STRING' && <Input />}
                {field.type === 'NUMBER' && <InputNumber />}
                {field.type === 'BOOLEAN' && <Checkbox />}
              </Form.Item>
            ));
          }}
        </Form.Item>
      </Form>
    );
  };

  return (
    <Card>
      <PageHeader
        breadcrumbs={[
          {
            label: 'KI Einstellungen',
            link: '#',
          },
        ]}
      />

      <div className="flex space-x-2 items-center h-[32px] w-[300px] mb-4">
        <span>Firma:</span>
        <CompanySelect value={companyId} onChange={setCompanyId} nullable />
      </div>

      <TableWithDrawer<
        FormFields,
        ProviderConfig,
        GetProviderConfigsQuery,
        GetProviderConfigsQueryVariables
      >
        query={useGetProviderConfigsQuery}
        queryVariables={{
          companyId: companyId,
        }}
        columns={columns}
        editFormRenderer={formRenderer('edit')}
        createFormRenderer={formRenderer('create')}
        singleItemTitle="Einstellungen"
        onCreate={handleCreate}
        onUpdate={handleUpdate}
        onDelete={handleDelete}
        prepareFormValues={(item) => ({
          config: item.config,
          providerId: item.providerId,
          companyId: item.companyId ?? undefined,
        })}
      />
    </Card>
  );
};

export default ProviderConfigs;
