export default [
  '3d-modeling.svg',
  'a-better-world.svg',
  'a-day-at-the-park.svg',
  'a-day-off.svg',
  'a-moment-to-relax.svg',
  'a-whole-year.svg',
  'about-me.svg',
  'about-us-page.svg',
  'absorbed-in.svg',
  'abstract.svg',
  'accept-request.svg',
  'accept-tasks.svg',
  'access-account.svg',
  'access-denied.svg',
  'account.svg',
  'active-options.svg',
  'active-support.svg',
  'activity-tracker.svg',
  'add-color.svg',
  'add-content.svg',
  'add-document.svg',
  'add-file.svg',
  'add-files.svg',
  'add-friends.svg',
  'add-information.svg',
  'add-notes.svg',
  'add-post.svg',
  'add-tasks.svg',
  'add-to-cart.svg',
  'add-user.svg',
  'address.svg',
  'adjustments.svg',
  'advanced-customization.svg',
  'adventure.svg',
  'adventure-map.svg',
  'agree.svg',
  'agreement.svg',
  'air-support.svg',
  'aircraft.svg',
  'alert.svg',
  'algolia.svg',
  'alien-science.svg',
  'all-the-data.svg',
  'among-nature.svg',
  'amusement-park.svg',
  'analysis.svg',
  'analytics.svg',
  'analyze.svg',
  'android.svg',
  'animating.svg',
  'annotation.svg',
  'anonymous-feedback.svg',
  'apartment-rent.svg',
  'app-data.svg',
  'app-installation.svg',
  'app-wireframe.svg',
  'appreciate-it.svg',
  'approve.svg',
  'apps.svg',
  'around-the-world.svg',
  'arrived.svg',
  'art.svg',
  'art-lover.svg',
  'art-museum.svg',
  'art-thinking.svg',
  'articles.svg',
  'artificial-intelligence.svg',
  'artist.svg',
  'ask-me-anything.svg',
  'asset-selection.svg',
  'astronaut.svg',
  'at-home.svg',
  'at-the-park.svg',
  'at-work.svg',
  'attached-file.svg',
  'audio-conversation.svg',
  'audio-player.svg',
  'augmented-reality.svg',
  'authentication.svg',
  'autumn.svg',
  'awards.svg',
  'awesome.svg',
  'baby.svg',
  'back-home.svg',
  'balloons.svg',
  'barbecue.svg',
  'barber.svg',
  'basketball.svg',
  'be-the-hero.svg',
  'beach-day.svg',
  'bear-market.svg',
  'beer.svg',
  'beer-celebration.svg',
  'before-dawn.svg',
  'begin-chat.svg',
  'best-place.svg',
  'bibliophile.svg',
  'bike-ride.svg',
  'biking.svg',
  'birthday-cake.svg',
  'birthday-girl.svg',
  'bitcoin.svg',
  'bitcoin-p2p.svg',
  'blank-canvas.svg',
  'blog-post.svg',
  'blogging.svg',
  'blooming.svg',
  'body-text.svg',
  'book-lover.svg',
  'book-reading.svg',
  'booked.svg',
  'booking.svg',
  'bookmarks.svg',
  'books.svg',
  'bookshelves.svg',
  'both-sides.svg',
  'brainstorming.svg',
  'breakfast.svg',
  'breaking-barriers.svg',
  'broadcast.svg',
  'browser-stats.svg',
  'browsing.svg',
  'browsing-online.svg',
  'buddies.svg',
  'buffer.svg',
  'bug-fixing.svg',
  'build-wireframe.svg',
  'build-your-home.svg',
  'building.svg',
  'building-blocks.svg',
  'building-websites.svg',
  'bus-stop.svg',
  'business-analytics.svg',
  'business-chat.svg',
  'business-deal.svg',
  'business-decisions.svg',
  'business-man.svg',
  'business-plan.svg',
  'business-shop.svg',
  'businesswoman.svg',
  'button-style.svg',
  'buy-house.svg',
  'by-the-road.svg',
  'cabin.svg',
  'calculator.svg',
  'calendar.svg',
  'calling.svg',
  'camera.svg',
  'campfire.svg',
  'camping.svg',
  'cancel.svg',
  'candidate.svg',
  'car-repair.svg',
  'card-postal.svg',
  'career-development.svg',
  'career-progress.svg',
  'cat.svg',
  'celebrating.svg',
  'celebration.svg',
  'certificate.svg',
  'certification.svg',
  'character-drawing.svg',
  'charts.svg',
  'chasing-love.svg',
  'chat.svg',
  'chat-bot.svg',
  'chatting.svg',
  'check-boxes.svg',
  'checking-boxes.svg',
  'checklist.svg',
  'chef.svg',
  'children.svg',
  'chilling.svg',
  'choice.svg',
  'choose.svg',
  'choosing-house.svg',
  'chore-list.svg',
  'christmas-mode.svg',
  'christmas-stocking.svg',
  'circles.svg',
  'circuit.svg',
  'circuit-board.svg',
  'city-driver.svg',
  'city-girl.svg',
  'city-life.svg',
  'clean-up.svg',
  'click-here.svg',
  'close-tab.svg',
  'cloud-docs.svg',
  'cloud-files.svg',
  'cloud-hosting.svg',
  'cloud-sync.svg',
  'cms.svg',
  'co-workers.svg',
  'co-working.svg',
  'code-inspection.svg',
  'code-review.svg',
  'code-thinking.svg',
  'code-typing.svg',
  'coding.svg',
  'coffee-.svg',
  'coffee-break.svg',
  'coffee-with-friends.svg',
  'cohort-analysis.svg',
  'collab.svg',
  'collaborating.svg',
  'collaboration.svg',
  'collaborators.svg',
  'collecting.svg',
  'collection.svg',
  'color-palette.svg',
  'color-schemes.svg',
  'coming-home.svg',
  'community.svg',
  'complete-design.svg',
  'complete-task.svg',
  'completed-steps.svg',
  'completed-tasks.svg',
  'completing.svg',
  'compose-music.svg',
  'composition.svg',
  'conceptual-idea.svg',
  'conference.svg',
  'conference-call.svg',
  'conference-speaker.svg',
  'confidential-letter.svg',
  'confirm.svg',
  'confirmation.svg',
  'confirmed.svg',
  'connected.svg',
  'connected-world.svg',
  'connecting-teams.svg',
  'connection.svg',
  'contact-us.svg',
  'container-ship.svg',
  'contemplating.svg',
  'content.svg',
  'content-creator.svg',
  'content-structure.svg',
  'content-team.svg',
  'contract.svg',
  'contrast.svg',
  'control-panel.svg',
  'conversation.svg',
  'convert.svg',
  'cookie-love.svg',
  'cooking.svg',
  'coolness.svg',
  'counting-stars.svg',
  'country-side.svg',
  'couple.svg',
  'couple-love.svg',
  'create.svg',
  'creation.svg',
  'creation-process.svg',
  'creative-draft.svg',
  'creative-experiment.svg',
  'creative-process.svg',
  'creative-team.svg',
  'creative-thinking.svg',
  'creative-woman.svg',
  'creativity.svg',
  'credit-card.svg',
  'credit-card-payment.svg',
  'credit-card-payments.svg',
  'crypto-flowers.svg',
  'crypto-portfolio.svg',
  'current-location.svg',
  'customer-survey.svg',
  'dark-alley.svg',
  'dark-analytics.svg',
  'dark-mode.svg',
  'dashboard.svg',
  'data.svg',
  'data-extraction.svg',
  'data-input.svg',
  'data-points.svg',
  'data-processing.svg',
  'data-report.svg',
  'data-reports.svg',
  'data-trends.svg',
  'date-night.svg',
  'date-picker.svg',
  'decide.svg',
  'deconstructed.svg',
  'decorate-christmas-tree.svg',
  'deliveries.svg',
  'delivery.svg',
  'delivery-address.svg',
  'delivery-truck.svg',
  'departing.svg',
  'design-community.svg',
  'design-components.svg',
  'design-data.svg',
  'design-feedback.svg',
  'design-inspiration.svg',
  'design-notes.svg',
  'design-objectives.svg',
  'design-process.svg',
  'design-sprint.svg',
  'design-stats.svg',
  'design-team.svg',
  'design-thinking.svg',
  'design-tools.svg',
  'designer.svg',
  'designer-girl.svg',
  'designer-life.svg',
  'designer-mindset.svg',
  'destination.svg',
  'destinations.svg',
  'detailed-analysis.svg',
  'detailed-examination.svg',
  'detailed-information.svg',
  'details.svg',
  'dev-focus.svg',
  'dev-productivity.svg',
  'develop-app.svg',
  'developer-activity.svg',
  'development.svg',
  'devices.svg',
  'diary.svg',
  'diet.svg',
  'different-love.svg',
  'digital-currency.svg',
  'digital-nomad.svg',
  'directions.svg',
  'discount.svg',
  'discoverable.svg',
  'doctor.svg',
  'doctors.svg',
  'documents.svg',
  'dog.svg',
  'dog-walking.svg',
  'doll-play.svg',
  'domain-names.svg',
  'done.svg',
  'done-checking.svg',
  'donut-love.svg',
  'download.svg',
  'drag.svg',
  'dream-world.svg',
  'dreamer.svg',
  'drone-delivery.svg',
  'drone-race.svg',
  'drone-surveillance.svg',
  'dropdown-menu.svg',
  'dua-lipa.svg',
  'duplicate.svg',
  'eating-together.svg',
  'eco-conscious.svg',
  'edit-photo.svg',
  'editable.svg',
  'education.svg',
  'educator.svg',
  'eiffel-tower.svg',
  'election-day.svg',
  'electric-car.svg',
  'electricity.svg',
  'elements.svg',
  'email-campaign.svg',
  'email-capture.svg',
  'emails.svg',
  'empty.svg',
  'empty-cart.svg',
  'empty-street.svg',
  'energizer.svg',
  'engineering-team.svg',
  'enter.svg',
  'envelope.svg',
  'environment.svg',
  'environmental-study.svg',
  'escaping.svg',
  'ether.svg',
  'ethereum.svg',
  'events.svg',
  'everyday-design.svg',
  'everyday-life.svg',
  'everywhere-together.svg',
  'exams.svg',
  'exciting-news.svg',
  'experience-design.svg',
  'experts.svg',
  'explore.svg',
  'exploring.svg',
  'export-files.svg',
  'factory.svg',
  'fall.svg',
  'fall-is-coming.svg',
  'family.svg',
  'fans.svg',
  'faq.svg',
  'farm-girl.svg',
  'fashion-blogging.svg',
  'fashion-photoshoot.svg',
  'fast.svg',
  'fast-loading.svg',
  'fatherhood.svg',
  'features-overview.svg',
  'feedback.svg',
  'feeling-blue.svg',
  'feeling-happy.svg',
  'feeling-of-joy.svg',
  'feeling-proud.svg',
  'female-avatar.svg',
  'festivities.svg',
  'file-analysis.svg',
  'file-bundle.svg',
  'file-manager.svg',
  'file-searching.svg',
  'file-sync.svg',
  'file-synchronization.svg',
  'files-sent.svg',
  'filing-system.svg',
  'fill-forms.svg',
  'fill-in.svg',
  'filter.svg',
  'finance.svg',
  'financial-data.svg',
  'fingerprint.svg',
  'fingerprint-login.svg',
  'finish-line.svg',
  'fireworks.svg',
  'firmware.svg',
  'fish-bowl.svg',
  'fishing.svg',
  'fitness-stats.svg',
  'fitness-tracker.svg',
  'fitting-piece.svg',
  'fitting-pieces.svg',
  'fixing-bugs.svg',
  'flagged.svg',
  'floating.svg',
  'flowers.svg',
  'flutter-dev.svg',
  'focus.svg',
  'folder.svg',
  'folder-files.svg',
  'follow-me-drone.svg',
  'followers.svg',
  'following.svg',
  'font.svg',
  'for-review.svg',
  'for-sale.svg',
  'forgot-password.svg',
  'forming-ideas.svg',
  'forms.svg',
  'freelancer.svg',
  'fresh-notification.svg',
  'friends.svg',
  'friends-online.svg',
  'friendship.svg',
  'frozen-figure.svg',
  'fun-moments.svg',
  'functions.svg',
  'futuristic-interface.svg',
  'game-day.svg',
  'game-world.svg',
  'gaming.svg',
  'gardening.svg',
  'gatsbyjs.svg',
  'gdpr.svg',
  'genius.svg',
  'getting-coffee.svg',
  'gift.svg',
  'gift-box.svg',
  'gift-card.svg',
  'gifts.svg',
  'girls-just-wanna-have-fun.svg',
  'goal.svg',
  'goals.svg',
  'going-offline.svg',
  'going-up.svg',
  'golden-gate-bridge.svg',
  'golf.svg',
  'gone-shopping.svg',
  'good-doggy.svg',
  'good-team.svg',
  'google-docs.svg',
  'grades.svg',
  'graduation.svg',
  'grand-slam.svg',
  'grandma.svg',
  'gravitas.svg',
  'greek-freak.svg',
  'grid-design.svg',
  'group-chat.svg',
  'group-hangout.svg',
  'group-selfie.svg',
  'group-video.svg',
  'growing.svg',
  'growth-analytics.svg',
  'growth-chart.svg',
  'growth-curve.svg',
  'hacker-mind.svg',
  'hacker-mindset.svg',
  'halloween.svg',
  'hamburger.svg',
  'hang-out.svg',
  'happy-2019.svg',
  'happy-2021.svg',
  'happy-announcement.svg',
  'happy-birthday.svg',
  'happy-feeling.svg',
  'happy-music.svg',
  'happy-new-year.svg',
  'happy-news.svg',
  'happy-women-day.svg',
  'having-fun.svg',
  'healthy-habit.svg',
  'healthy-lifestyle.svg',
  'healthy-options.svg',
  'heartbroken.svg',
  'heatmap.svg',
  'heavy-box.svg',
  'hello.svg',
  'helpful-sign.svg',
  'hey-by-basecamp.svg',
  'high-five.svg',
  'hiking.svg',
  'hire.svg',
  'hiring.svg',
  'hologram.svg',
  'home-cinema.svg',
  'home-run.svg',
  'home-screen.svg',
  'home-settings.svg',
  'hooked.svg',
  'horror-movie.svg',
  'hot-beverage.svg',
  'house-searching.svg',
  'houses.svg',
  'i-can-fly.svg',
  'ice-cream.svg',
  'icon-design.svg',
  'icons.svg',
  'ideas.svg',
  'ideas-flow.svg',
  'ideation.svg',
  'image-focus.svg',
  'image-folder.svg',
  'image-options.svg',
  'image-post.svg',
  'image-upload.svg',
  'image-viewer.svg',
  'images.svg',
  'imagination.svg',
  'in-no-time.svg',
  'in-progress.svg',
  'in-real-life.svg',
  'in-sync.svg',
  'in-the-office.svg',
  'in-the-pool.svg',
  'in-thought.svg',
  'inbox.svg',
  'inbox-cleanup.svg',
  'indoor-bike.svg',
  'influencer.svg',
  'information-tab.svg',
  'informed-decision.svg',
  'injured.svg',
  'innovative.svg',
  'insert.svg',
  'insert-block.svg',
  'inspection.svg',
  'inspiration.svg',
  'instant-analysis.svg',
  'instant-information.svg',
  'instant-support.svg',
  'instruction-manual.svg',
  'intense-feeling.svg',
  'interior-design.svg',
  'internet-on-the-go.svg',
  'interview.svg',
  'into-the-night.svg',
  'invest.svg',
  'investing.svg',
  'investment.svg',
  'investment-data.svg',
  'investor-update.svg',
  'invite.svg',
  'japan.svg',
  'javascript-frameworks.svg',
  'jewelry.svg',
  'job-hunt.svg',
  'job-offers.svg',
  'jogging.svg',
  'join.svg',
  'journey.svg',
  'joyride.svg',
  'judge.svg',
  'junior-soccer.svg',
  'just-browsing.svg',
  'just-saying.svg',
  'key-points.svg',
  'knowledge.svg',
  'landing-page.svg',
  'landscape-mode.svg',
  'landscape-photographer.svg',
  'laravel-and-vue.svg',
  'late-at-night.svg',
  'launch-day.svg',
  'launching.svg',
  'learning.svg',
  'learning-sketching.svg',
  'letter.svg',
  'light-the-fire.svg',
  'lightbulb-moment.svg',
  'lighthouse.svg',
  'like-dislike.svg',
  'link-shortener.svg',
  'listening.svg',
  'live-collaboration.svg',
  'live-photo.svg',
  'living.svg',
  'load-more.svg',
  'loading.svg',
  'location-review.svg',
  'location-search.svg',
  'location-tracking.svg',
  'logic.svg',
  'login.svg',
  'logistics.svg',
  'logo-design.svg',
  'lost.svg',
  'love.svg',
  'love-is-in-the-air.svg',
  'loving-it.svg',
  'loving-story.svg',
  'mail.svg',
  'mail-sent.svg',
  'mailbox.svg',
  'maintenance.svg',
  'make-it-rain.svg',
  'maker-launch.svg',
  'makeup-artist.svg',
  'making-art.svg',
  'male-avatar.svg',
  'manage-chats.svg',
  'map.svg',
  'map-dark.svg',
  'marilyn.svg',
  'marketing.svg',
  'master-plan.svg',
  'mathematics.svg',
  'media-player.svg',
  'medical-care.svg',
  'medical-research.svg',
  'medicine.svg',
  'meditating.svg',
  'meditation.svg',
  'meet-the-team.svg',
  'meeting.svg',
  'mello.svg',
  'memory-storage.svg',
  'mention.svg',
  'message-sent.svg',
  'messages.svg',
  'messaging.svg',
  'messaging-app.svg',
  'messaging-fun.svg',
  'messenger.svg',
  'metrics.svg',
  'mic-drop.svg',
  'millennial-girl.svg',
  'mind-map.svg',
  'mindfulness.svg',
  'mint-tea.svg',
  'miro.svg',
  'missed-chances.svg',
  'mobile.svg',
  'mobile-analytics.svg',
  'mobile-app.svg',
  'mobile-application.svg',
  'mobile-apps.svg',
  'mobile-browsers.svg',
  'mobile-development.svg',
  'mobile-devices.svg',
  'mobile-encryption.svg',
  'mobile-feed.svg',
  'mobile-images.svg',
  'mobile-inbox.svg',
  'mobile-interface.svg',
  'mobile-life.svg',
  'mobile-login.svg',
  'mobile-marketing.svg',
  'mobile-messages.svg',
  'mobile-pay.svg',
  'mobile-payments.svg',
  'mobile-photos.svg',
  'mobile-posts.svg',
  'mobile-prototyping.svg',
  'mobile-search.svg',
  'mobile-testing.svg',
  'mobile-user.svg',
  'mobile-ux.svg',
  'mobile-web.svg',
  'mobile-wireframe.svg',
  'modern-art.svg',
  'modern-design.svg',
  'modern-life.svg',
  'modern-professional.svg',
  'modern-woman.svg',
  'moment-to-remember.svg',
  'moments.svg',
  'monitor.svg',
  'monster-artist.svg',
  'moonlight.svg',
  'more-music.svg',
  'mornings.svg',
  'motherhood.svg',
  'movie-night.svg',
  'moving.svg',
  'moving-forward.svg',
  'multitasking.svg',
  'music.svg',
  'my-answer.svg',
  'my-app.svg',
  'my-code-snippets.svg',
  'my-current-location.svg',
  'my-documents.svg',
  'my-feed.svg',
  'my-files.svg',
  'my-location.svg',
  'my-notifications.svg',
  'my-password.svg',
  'my-personal-files.svg',
  'my-universe.svg',
  'nakamoto.svg',
  'nature.svg',
  'nature-benefits.svg',
  'nature-fun.svg',
  'nature-on-screen.svg',
  'navigation.svg',
  'navigator.svg',
  'neighbors.svg',
  'netflix.svg',
  'new-decade.svg',
  'new-entries.svg',
  'new-ideas.svg',
  'new-message.svg',
  'new-notifications.svg',
  'new-year-2021.svg',
  'new-year-2022.svg',
  'new-year-2023.svg',
  'news.svg',
  'newsletter.svg',
  'newspaper.svg',
  'next-option.svg',
  'next-tasks.svg',
  'nextjs.svg',
  'night-calls.svg',
  'ninja.svg',
  'no-data.svg',
  'not-found.svg',
  'note-list.svg',
  'notebook.svg',
  'noted.svg',
  'notes.svg',
  'notify.svg',
  'nuxt-js.svg',
  'observations.svg',
  'off-road.svg',
  'office-snack.svg',
  'omega.svg',
  'on-the-office.svg',
  'on-the-way.svg',
  'onboarding.svg',
  'online.svg',
  'online-ad.svg',
  'online-art.svg',
  'online-article.svg',
  'online-articles.svg',
  'online-banking.svg',
  'online-calendar.svg',
  'online-chat.svg',
  'online-collaboration.svg',
  'online-connection.svg',
  'online-cv.svg',
  'online-dating.svg',
  'online-discussion.svg',
  'online-everywhere.svg',
  'online-friends.svg',
  'online-gallery.svg',
  'online-groceries.svg',
  'online-information.svg',
  'online-learning.svg',
  'online-media.svg',
  'online-message.svg',
  'online-messaging.svg',
  'online-organizer.svg',
  'online-page.svg',
  'online-party.svg',
  'online-payments.svg',
  'online-popularity.svg',
  'online-posts.svg',
  'online-reading.svg',
  'online-resume.svg',
  'online-shopping.svg',
  'online-stats.svg',
  'online-test.svg',
  'online-transactions.svg',
  'online-video.svg',
  'online-wishes.svg',
  'online-world.svg',
  'open-source.svg',
  'opened.svg',
  'opened-tabs.svg',
  'opinion.svg',
  'optimize-image.svg',
  'options.svg',
  'order-a-car.svg',
  'order-confirmed.svg',
  'order-delivered.svg',
  'order-ride.svg',
  'ordinary-day.svg',
  'organize-photos.svg',
  'organize-resume.svg',
  'organized-content.svg',
  'organizing-projects.svg',
  'os-upgrade.svg',
  'our-neighborhood.svg',
  'our-solution.svg',
  'outdoor-adventure.svg',
  'outdoor-party.svg',
  'outdoors.svg',
  'outer-space.svg',
  'package-arrived.svg',
  'page-not-found.svg',
  'pair-programming.svg',
  'palette.svg',
  'pancakes.svg',
  'party.svg',
  'partying.svg',
  'passing-by.svg',
  'pay-online.svg',
  'payments.svg',
  'pedestrian-crossing.svg',
  'pen-tool.svg',
  'pending-approval.svg',
  'people.svg',
  'people-search.svg',
  'percentages.svg',
  'performance-overview.svg',
  'personal-data.svg',
  'personal-documents.svg',
  'personal-email.svg',
  'personal-file.svg',
  'personal-finance.svg',
  'personal-goals.svg',
  'personal-info.svg',
  'personal-information.svg',
  'personal-notebook.svg',
  'personal-notes.svg',
  'personal-opinions.svg',
  'personal-settings.svg',
  'personal-site.svg',
  'personal-text.svg',
  'personal-trainer.svg',
  'personal-training.svg',
  'personal-website.svg',
  'personalization.svg',
  'pet-adoption.svg',
  'phone-call.svg',
  'photo.svg',
  'photo-album.svg',
  'photo-session.svg',
  'photo-sharing.svg',
  'photocopy.svg',
  'photograph.svg',
  'photos.svg',
  'pic-profile.svg',
  'picture.svg',
  'pie-chart.svg',
  'pie-graph.svg',
  'pilates.svg',
  'pitching.svg',
  'pizza-sharing.svg',
  'placeholders.svg',
  'plain-credit-card.svg',
  'plans.svg',
  'play-time.svg',
  'playful-cat.svg',
  'playing-cards.svg',
  'playing-fetch.svg',
  'playlist.svg',
  'pleasant-surprise.svg',
  'podcast.svg',
  'podcast-audience.svg',
  'polaroid.svg',
  'popular.svg',
  'portfolio.svg',
  'portfolio-feedback.svg',
  'portfolio-update.svg',
  'portfolio-website.svg',
  'positive-attitude.svg',
  'post.svg',
  'post-online.svg',
  'posting-photo.svg',
  'posts.svg',
  'powerful.svg',
  'predictive-analytics.svg',
  'preferences.svg',
  'preferences-popup.svg',
  'preparation.svg',
  'presentation.svg',
  'press-play.svg',
  'pride.svg',
  'printing-invoices.svg',
  'prioritise.svg',
  'private-data.svg',
  'problem-solving.svg',
  'process.svg',
  'processing.svg',
  'processing-thoughts.svg',
  'product-explainer.svg',
  'product-hunt.svg',
  'product-iteration.svg',
  'product-photography.svg',
  'product-teardown.svg',
  'product-tour.svg',
  'professional-card.svg',
  'professor.svg',
  'profile.svg',
  'profile-data.svg',
  'profile-details.svg',
  'profile-image.svg',
  'profile-pic.svg',
  'programmer.svg',
  'programming.svg',
  'progress-data.svg',
  'progress-indicator.svg',
  'progress-overview.svg',
  'progress-tracking.svg',
  'progressive-app.svg',
  'project-complete.svg',
  'project-completed.svg',
  'project-feedback.svg',
  'project-team.svg',
  'projections.svg',
  'prototyping-process.svg',
  'proud-coder.svg',
  'proud-self.svg',
  'public-discussion.svg',
  'publish-article.svg',
  'publish-post.svg',
  'pull-request.svg',
  'pumpkin.svg',
  'pure-love.svg',
  'push-notifications.svg',
  'qa-engineers.svg',
  'quality-time.svg',
  'questions.svg',
  'quick-chat.svg',
  'quiet-town.svg',
  'quitting-time.svg',
  'quiz.svg',
  'raining.svg',
  'random-thoughts.svg',
  'react.svg',
  'reading.svg',
  'reading-book.svg',
  'reading-list.svg',
  'reading-time.svg',
  'ready-for-waves.svg',
  'real-time-analytics.svg',
  'real-time-collaboration.svg',
  'real-time-sync.svg',
  'receipt.svg',
  'recording.svg',
  'redesign-feedback.svg',
  'referral.svg',
  'refreshing.svg',
  'refreshing-beverage.svg',
  'regain-focus.svg',
  'relaunch-day.svg',
  'relaxation.svg',
  'relaxing-at-home.svg',
  'relaxing-walk.svg',
  'reminder.svg',
  'reminders.svg',
  'remote-design-team.svg',
  'remote-meeting.svg',
  'remote-team.svg',
  'remotely.svg',
  'report.svg',
  'researching.svg',
  'respond.svg',
  'responsive.svg',
  'responsiveness.svg',
  'result.svg',
  'resume.svg',
  'resume-folder.svg',
  'revenue.svg',
  'review.svg',
  'reviewed-docs.svg',
  'reviews.svg',
  'ride-a-bicycle.svg',
  'ride-till-i-can-no-more.svg',
  'right-direction.svg',
  'right-places.svg',
  'rising.svg',
  'road-sign.svg',
  'road-to-knowledge.svg',
  'robotics.svg',
  'romantic-getaway.svg',
  'runner-start.svg',
  'running-wild.svg',
  'safe.svg',
  'santa-claus.svg',
  'santa-visit.svg',
  'save-to-bookmarks.svg',
  'savings.svg',
  'schedule.svg',
  'schedule-meeting.svg',
  'science.svg',
  'scientist.svg',
  'scooter.svg',
  'screen-time.svg',
  'scrum-board.svg',
  'sculpting.svg',
  'search.svg',
  'search-app.svg',
  'searching.svg',
  'season-change.svg',
  'secure-files.svg',
  'secure-login.svg',
  'secure-server.svg',
  'security.svg',
  'security-on.svg',
  'segment.svg',
  'segment-analysis.svg',
  'segmentation.svg',
  'select.svg',
  'select-house.svg',
  'select-option.svg',
  'select-player.svg',
  'selected-options.svg',
  'selecting.svg',
  'selecting-team.svg',
  'selection.svg',
  'selfie.svg',
  'selfie-time.svg',
  'send-gift.svg',
  'sentiment-analysis.svg',
  'server.svg',
  'server-cluster.svg',
  'server-down.svg',
  'server-push.svg',
  'server-status.svg',
  'services.svg',
  'set-preferences.svg',
  'settings.svg',
  'settings-tab.svg',
  'setup.svg',
  'setup-analytics.svg',
  'setup-wizard.svg',
  'share.svg',
  'share-link.svg',
  'share-online.svg',
  'share-opinion.svg',
  'shared-goals.svg',
  'shared-workspace.svg',
  'sharing-articles.svg',
  'sharing-knowledge.svg',
  'shopping.svg',
  'shopping-app.svg',
  'short-bio.svg',
  'showing-support.svg',
  'sign-in.svg',
  'sign-up.svg',
  'signal-searching.svg',
  'site-content.svg',
  'site-stats.svg',
  'skateboard.svg',
  'skateboarding.svg',
  'slider.svg',
  'small-town.svg',
  'smart-home.svg',
  'smart-resize.svg',
  'smartwatch.svg',
  'smiley-face.svg',
  'snap-the-moment.svg',
  'snow-fun.svg',
  'snow-games.svg',
  'snow-globe.svg',
  'snowman.svg',
  'social-bio.svg',
  'social-dashboard.svg',
  'social-distancing.svg',
  'social-expert.svg',
  'social-friends.svg',
  'social-girl.svg',
  'social-growth.svg',
  'social-ideas.svg',
  'social-influencer.svg',
  'social-interaction.svg',
  'social-life.svg',
  'social-media.svg',
  'social-networking.svg',
  'social-notifications.svg',
  'social-serenity.svg',
  'social-share.svg',
  'social-sharing.svg',
  'social-strategy.svg',
  'social-thinking.svg',
  'social-tree.svg',
  'social-update.svg',
  'social-user.svg',
  'software-engineer.svg',
  'solution-mindset.svg',
  'sorting-thoughts.svg',
  'source-code.svg',
  'special-event.svg',
  'specs.svg',
  'speech-to-text.svg',
  'speed-test.svg',
  'split-testing.svg',
  'spooky-self.svg',
  'spread-love.svg',
  'spreadsheet.svg',
  'spreadsheets.svg',
  'stability-ball.svg',
  'stand-out.svg',
  'starlink.svg',
  'starry-window.svg',
  'stars.svg',
  'start-building.svg',
  'startled.svg',
  'startup-life.svg',
  'static-assets.svg',
  'static-website.svg',
  'statistic-chart.svg',
  'statistics.svg',
  'status-update.svg',
  'staying-in.svg',
  'step-to-the-sun.svg',
  'stepping-up.svg',
  'steps.svg',
  'stock-prices.svg',
  'stranded-traveler.svg',
  'street-food.svg',
  'stripe-payments.svg',
  'studying.svg',
  'subscribe.svg',
  'subscriber.svg',
  'subscriptions.svg',
  'suburbs.svg',
  'subway.svg',
  'success-factors.svg',
  'successful-purchase.svg',
  'summer.svg',
  'sunlight.svg',
  'sunny-day.svg',
  'super-thank-you.svg',
  'super-woman.svg',
  'superhero.svg',
  'surfer.svg',
  'surveillance.svg',
  'survey.svg',
  'sweet-home.svg',
  'swipe.svg',
  'swipe-options.svg',
  'swipe-profiles.svg',
  'switches.svg',
  'sync.svg',
  'sync-files.svg',
  'synchronize.svg',
  'tabs.svg',
  'tailwind-css.svg',
  'take-out-boxes.svg',
  'taken.svg',
  'taking-notes.svg',
  'taking-selfie.svg',
  'target.svg',
  'task.svg',
  'task-list.svg',
  'tasks.svg',
  'tasting.svg',
  'teacher.svg',
  'teaching.svg',
  'team.svg',
  'team-chat.svg',
  'team-collaboration.svg',
  'team-goals.svg',
  'team-page.svg',
  'team-spirit.svg',
  'team-up.svg',
  'team-work.svg',
  'teamwork.svg',
  'teddy-bear.svg',
  'term-sheet.svg',
  'terms.svg',
  'text-field.svg',
  'text-files.svg',
  'texting.svg',
  'the-search.svg',
  'the-world-is-mine.svg',
  'things-to-say.svg',
  'thought-process.svg',
  'thoughts.svg',
  'through-the-desert.svg',
  'through-the-park.svg',
  'through-the-window.svg',
  'throw-away.svg',
  'throw-down.svg',
  'time-management.svg',
  'timeline.svg',
  'to-do.svg',
  'to-do-list.svg',
  'to-the-moon.svg',
  'to-the-stars.svg',
  'together.svg',
  'tourist-map.svg',
  'towing.svg',
  'town.svg',
  'toy-car.svg',
  'track-and-field.svg',
  'transfer-files.svg',
  'transfer-money.svg',
  'travel-booking.svg',
  'travel-mode.svg',
  'travel-plans.svg',
  'travel-together.svg',
  'travelers.svg',
  'traveling.svg',
  'treasure.svg',
  'tree-swing.svg',
  'trends.svg',
  'trendy-interface.svg',
  'trip.svg',
  'true-friends.svg',
  'true-love.svg',
  'tutorial-video.svg',
  'tweetstorm.svg',
  'two-factor-authentication.svg',
  'typewriter.svg',
  'typing.svg',
  'unboxing.svg',
  'under-construction.svg',
  'undraw-1000.svg',
  'unexpected-friends.svg',
  'unicorn.svg',
  'unlock.svg',
  'up-to-date.svg',
  'update.svg',
  'updated.svg',
  'updated-resume.svg',
  'updates.svg',
  'upgrade.svg',
  'upload.svg',
  'upload-image.svg',
  'uploading.svg',
  'upvote.svg',
  'urban-design.svg',
  'usability-testing.svg',
  'user-flow.svg',
  'vault.svg',
  'verified.svg',
  'version-control.svg',
  'video-call.svg',
  'video-files.svg',
  'video-game-night.svg',
  'video-influencer.svg',
  'video-streaming.svg',
  'video-upload.svg',
  'videographer.svg',
  'virtual-assistant.svg',
  'virtual-reality.svg',
  'visionary-technology.svg',
  'visual-data.svg',
  'visualization.svg',
  'voice-assistant.svg',
  'voice-control.svg',
  'voice-interface.svg',
  'void.svg',
  'voting.svg',
  'vr-chat.svg',
  'wait-in-line.svg',
  'waiting-for-you.svg',
  'walk-dreaming.svg',
  'walk-in-the-city.svg',
  'walking-around.svg',
  'walking-outside.svg',
  'wall-post.svg',
  'wallet.svg',
  'wandering-mind.svg',
  'warning.svg',
  'wash-hands.svg',
  'watch-application.svg',
  'weather.svg',
  'weather-app.svg',
  'weather-notification.svg',
  'web-browsing.svg',
  'web-developer.svg',
  'web-development.svg',
  'web-devices.svg',
  'web-search.svg',
  'web-shopping.svg',
  'website-builder.svg',
  'website-setup.svg',
  'wedding.svg',
  'welcome.svg',
  'welcome-cats.svg',
  'welcoming.svg',
  'well-done.svg',
  'wilderness.svg',
  'wind-turbine.svg',
  'window-shopping.svg',
  'windows.svg',
  'windy-day.svg',
  'wine-tasting.svg',
  'winners.svg',
  'winter-activities.svg',
  'winter-designer.svg',
  'winter-magic.svg',
  'winter-road.svg',
  'winter-skating.svg',
  'winter-walk.svg',
  'wireframing.svg',
  'wishes.svg',
  'wishlist.svg',
  'with-love.svg',
  'woman.svg',
  'women-day.svg',
  'word-of-mouth.svg',
  'wordpress.svg',
  'work-chat.svg',
  'work-from-anywhere.svg',
  'work-in-progress.svg',
  'work-time.svg',
  'work-together.svg',
  'working.svg',
  'working-from-anywhere.svg',
  'working-late.svg',
  'working-out.svg',
  'working-remotely.svg',
  'workout.svg',
  'world.svg',
  'writer.svg',
  'xmas-snowman.svg',
  'xmas-surprise.svg',
  'yacht.svg',
  'year-2022.svg',
  'yoga.svg',
  'young-and-happy.svg',
  'youtube-tutorial.svg',
  'zoom-in.svg',
];
