import { Form, notification } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  FollowUpPrompt,
  Task,
  TaskDefinition,
  TaskMessage,
  TaskMessageInput,
} from '../../graphql/schema';
import uploadFormFiles from '../../helper/uploadFormFiles';
import useCreateGenericMessage from '../../stream/useCreateGenericMessage';
import useCreateMessage from '../../stream/useCreateMessage';
import useCreateMessageWithFollowUpPrompt from '../../stream/useCreateMessageWithFollowUpPrompt';
import EditMessage from './EditMessage';
import FollowUpPrompts from './FollowUpPrompts';
import Message, { TaskMessageWithLoadingIndicator } from './Message';
import TaskPrompt, { TaskPromptFormFields } from './TaskPrompt';

interface Props {
  task: Task;
  taskDefinition?: TaskDefinition;
  reloadTask: () => void;
}

const TaskView = ({ task, taskDefinition, reloadTask }: Props) => {
  const [newMessagePlaceholder, setNewMessagePlaceholder] =
    useState<TaskMessageWithLoadingIndicator>();

  const [promptForm] = Form.useForm<TaskPromptFormFields>();

  const [selectedMessage, setSelectedMessage] = useState<TaskMessage>();

  const createMessage = useCreateMessage(task.id);
  const createMessageUsingFollowUpPrompt = useCreateMessageWithFollowUpPrompt(
    task.id,
  );
  const createMessageUsingGenericPrompt = useCreateGenericMessage(task.id);

  const loadedTaskId = useRef<string | undefined>();
  const numberOfLoadedMessages = useRef<number | undefined>();

  useEffect(() => {
    // Scroll down when messages change
    window.document.getElementById('messagesContainer')?.scrollTo({
      top: 999999999,
    });
  }, [task.messages, newMessagePlaceholder]);

  useEffect(() => {
    loadedTaskId.current = task.id;

    if (
      task.id !== loadedTaskId.current ||
      numberOfLoadedMessages.current !== task.messages?.length
    ) {
      // Task changed or new messages are available
      numberOfLoadedMessages.current = task.messages?.length;

      // Reset on task change
      setSelectedMessage(undefined);

      // Reset placeholder
      setNewMessagePlaceholder(undefined);
    }

    // Handle new message requests passed from other pages using local storage
    const newTaskMessageData = window.localStorage.getItem('newTaskMessage');
    if (newTaskMessageData) {
      const parsedNewMessageData = JSON.parse(newTaskMessageData);

      if (parsedNewMessageData.taskId === task.id) {
        window.localStorage.removeItem('newTaskMessage');
        addMessage(parsedNewMessageData);
      }
    }
  }, [task]);

  const addTextChunk = useCallback((textChunk: string) => {
    setNewMessagePlaceholder((message) =>
      message
        ? {
            ...message,
            response: {
              text: `${message.response?.text ?? ''}${textChunk}`,
            },
          }
        : message,
    );
  }, []);

  const addMessage = useCallback(
    async (input: TaskMessageInput) => {
      // Add placeholder message
      setNewMessagePlaceholder({
        id: 'NEW',
        createdAt: new Date(),
        input,
        response: {},
        loading: true,
      });

      try {
        // Create message
        let newMessage: TaskMessage | undefined = undefined;

        if (input.formValues) {
          newMessage = await createMessage(
            await uploadFormFiles(
              'task',
              input.formValues,
              task.fileUploadInfo,
            ),
            addTextChunk,
          );
        } else if (input.prompt) {
          newMessage = await createMessageUsingGenericPrompt(
            input.prompt,
            addTextChunk,
          );
        } else if (input.followUpPrompt) {
          newMessage = await createMessageUsingFollowUpPrompt(
            input.followUpPrompt,
            addTextChunk,
          );
        }

        if (!newMessage) throw new Error('Failed to generate task message');

        reloadTask();
        setNewMessagePlaceholder(newMessage);
      } catch (error: any) {
        notification.error({
          message: error.message,
          closable: true,
          duration: null,
        });

        setNewMessagePlaceholder(undefined);
      }
    },
    [
      addTextChunk,
      createMessageUsingFollowUpPrompt,
      task,
      reloadTask,
      createMessage,
      createMessageUsingGenericPrompt,
    ],
  );

  const handleEditMessage = useCallback((message: TaskMessage) => {
    const { formValues, followUpPrompt, prompt } = message.input;

    if (formValues) {
      setSelectedMessage(message);
    } else if (followUpPrompt) {
      // Not possible
    } else if (prompt) {
      promptForm.setFieldValue('prompt', message.input.prompt?.prompt ?? '');
      document.getElementById('promptTextField')?.focus();
    }
  }, []);

  const handleAddMessageUsingGenericPrompt = useCallback(
    async (prompt: TaskPromptFormFields) => {
      addMessage({
        prompt,
      });
    },
    [addMessage],
  );

  const handleAddMessageUsingTaskDefinitionForm = useCallback(
    async (formValues: any) => {
      addMessage({
        formValues,
      });
    },
    [addMessage],
  );

  const handleAddMessageUsingFollowUpPrompt = useCallback(
    async (followUpPrompt: FollowUpPrompt) => {
      addMessage({
        followUpPrompt,
      });
    },
    [addMessage],
  );

  return (
    <>
      {selectedMessage && taskDefinition && (
        <EditMessage
          message={selectedMessage}
          taskDefinition={taskDefinition}
          onClose={() => setSelectedMessage(undefined)}
          onSave={handleAddMessageUsingTaskDefinitionForm}
        />
      )}

      {!selectedMessage && (
        <>
          <div
            style={{
              overflowY: 'auto',
              flex: '1 1 auto',
              height: 0,
            }}
            id="messagesContainer"
          >
            <div className="flex flex-col space-y-6">
              {task.messages?.map((message) => (
                <Message
                  message={message}
                  onEditMessage={handleEditMessage}
                  formFieldDefinitions={taskDefinition?.formDefinition ?? []}
                  key={message.id}
                />
              ))}
              {newMessagePlaceholder && (
                <Message
                  message={newMessagePlaceholder}
                  formFieldDefinitions={taskDefinition?.formDefinition ?? []}
                  key="NEW"
                />
              )}
            </div>

            <FollowUpPrompts
              task={task}
              onSelect={handleAddMessageUsingFollowUpPrompt}
            />
          </div>
          <TaskPrompt
            form={promptForm}
            onSend={handleAddMessageUsingGenericPrompt}
          />
        </>
      )}
    </>
  );
};

export default TaskView;
