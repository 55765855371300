import { TreeSelect } from 'antd';
import React from 'react';
import { useGetModulesWithCategoriesQuery } from '../../graphql/schema';

interface Props {
  value?: string;
  onChange?: (id: string) => void;
}

const CategorySelect = ({ value, onChange }: Props) => {
  const { data, loading } = useGetModulesWithCategoriesQuery();

  const options =
    data?.modulesWithCategories.map((item) => {
      return {
        title: item.title,
        value: item.id,
        children: item.categories?.map((category) => {
          return {
            label: category.title,
            value: category.id,
          };
        }),
      };
    }) || [];

  return (
    <TreeSelect
      showSearch
      treeDefaultExpandAll
      onChange={onChange}
      loading={loading}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      value={value}
      treeData={options}
      className="w-full"
    />
  );
};

export default CategorySelect;
