import { faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHover } from '@uidotdev/usehooks';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ListTaskFragment,
  Task,
  useDeleteTaskMutation,
} from '../../graphql/schema';
import ContextMenu from '../base/ContextMenu';

interface Props {
  task: ListTaskFragment;
  activeTaskId?: string;
}

const TasksSidebarEntry = ({ task, activeTaskId }: Props) => {
  const navigate = useNavigate();

  const [ref, hovering] = useHover();

  const [deleteTask] = useDeleteTaskMutation({
    variables: {
      id: task.id,
    },
    refetchQueries: ['GetTasks'],
  });

  const handleDeleteTask = useCallback(async () => {
    await deleteTask();
    navigate('/tasks', {
      replace: true,
    });
  }, [deleteTask, navigate]);

  return (
    <div
      ref={ref}
      onClick={() => navigate(`/task/${task.id}`)}
      className={`theme-bg-primary-hover ${activeTaskId === task.id ? 'theme-bg-primary' : ''} w-full cursor-pointer rounded-xl p-[5px] px-[10px] block text-sm relative`}
      title={task.summary ?? task.id}
    >
      <div className="text-xs opacity-60">
        {task.taskDefinitionLabel ?? 'Chat'}
      </div>
      <div className="text-ellipsis text-nowrap overflow-hidden">
        {task.summary ?? task.id}
      </div>

      <ContextMenu
        entries={[
          {
            label: 'Löschen',
            key: 'delete',
            onClick: handleDeleteTask,
            confirmText: 'Sicher?',
            icon: <FontAwesomeIcon icon={faTrashCan} />,
          },
        ]}
        visible={hovering}
      >
        <div className="absolute right-0 top-0 h-full w-[25px] theme-bg-primary flex justify-center items-center rounded-tr-xl rounded-br-xl">
          <FontAwesomeIcon icon={faEllipsisV} />
        </div>
      </ContextMenu>
    </div>
  );
};

export default TasksSidebarEntry;
