import { Select } from 'antd';
import { useMemo } from 'react';
import { File as ApiFile, FormFieldDefinition } from '../../graphql/schema';
import { getFileNameFromFileId } from '../../helper/files';
import splitString from '../../helper/splitString';

export interface Props {
  value?: string;
  onChange?: (value: string) => void;
  variables: any;
  messageSchema: FormFieldDefinition[];
  settingsSchema: FormFieldDefinition[];
  single?: boolean;
  files: ApiFile[];
}

const VariablesSelect = ({
  value,
  onChange,
  messageSchema,
  settingsSchema,
  single,
  files,
}: Props) => {
  const options = useMemo(() => {
    const options: {
      label: string;
      value: string;
    }[] = [];

    const schemas: [string, FormFieldDefinition[]][] = [
      ['form', messageSchema],
      ['settings', settingsSchema],
    ];

    for (const [name, schema] of schemas) {
      for (const field of schema) {
        if (
          ['file', 'image'].includes(field.type) ||
          (!single && ['files', 'images'].includes(field.type))
        ) {
          options.push({
            label: `${name}.${field.name} (${field.label})`,
            value: `${name}.${field.name}`,
          });
        }
      }
    }

    files.forEach((file) => {
      options.push({
        label: getFileNameFromFileId(file.fileName),
        value: file.id,
      });
    });

    return options;
  }, [messageSchema, settingsSchema, single, files]);

  return (
    <div>
      <Select
        size="small"
        options={options}
        value={value}
        onChange={onChange}
        mode={single ? undefined : 'tags'}
      />
    </div>
  );
};

export default VariablesSelect;
