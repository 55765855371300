import { Button, ButtonProps } from 'antd';

const FancyButton = (props: ButtonProps) => {
  return (
    <Button
      {...props}
      className={`${props.type === 'default' ? '' : 'theme-bg-primary'} ${props.size === 'small' ? 'p-3' : 'p-4'} font-semibold rounded-full border-0 ${props.className}`}
    >
      {props.children}
    </Button>
  );
};

export default FancyButton;
