import { faMinusCircle, faPlusCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
} from 'antd';
import React from 'react';
import formItemProps from '../../helper/formItemProps';
import fields from './fields';

const FormFieldSettings = () => {
  return (
    <>
      <Form.Item {...formItemProps('type', 'Typ', true)}>
        <Select
          options={Object.keys(fields).map((fieldType) => {
            return {
              label: fields[fieldType].label,
              value: fieldType,
            };
          })}
        />
      </Form.Item>
      <Form.Item {...formItemProps('name', 'Name', true)}>
        <Input />
      </Form.Item>
      <Form.Item {...formItemProps('label', 'Titel', true)}>
        <Input />
      </Form.Item>
      <Form.Item
        {...formItemProps('required', 'Erforderlich', false)}
        valuePropName="checked"
      >
        <Checkbox />
      </Form.Item>
      <Form.Item {...formItemProps('description', 'Beschreibung', false)}>
        <Input />
      </Form.Item>
      <Form.Item {...formItemProps('tooltip', 'Erklärung', false)}>
        <Input.TextArea />
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.type !== currentValues.type
        }
      >
        {({ getFieldValue }) =>
          fields[getFieldValue('type')]?.hasMinMax && (
            <>
              <Form.Item {...formItemProps('minimum', 'Min. Wert', false)}>
                <InputNumber />
              </Form.Item>
              <Form.Item {...formItemProps('maximum', 'Max. Wert', false)}>
                <InputNumber />
              </Form.Item>
            </>
          )
        }
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.type !== currentValues.type ||
          prevValues.options !== currentValues.options
        }
      >
        {({ getFieldValue, getFieldsValue }) => {
          const type = fields[getFieldValue('type')];
          if (!type || type.supportsDefaultValue === false) return <div />;

          return (
            <Form.Item
              {...formItemProps('default', 'Standardwert', false)}
              valuePropName={type.valuePropName}
            >
              {type.renderComponent(getFieldsValue())}
            </Form.Item>
          );
        }}
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.type !== currentValues.type
        }
      >
        {({ getFieldValue }) =>
          fields[getFieldValue('type')]?.hasOptions && (
            <>
              <label className="mb-2" title="Optionen">
                Optionen
              </label>
              <Form.List name="options">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{ display: 'flex', marginBottom: 8 }}
                        align="baseline"
                      >
                        <Form.Item
                          {...restField}
                          name={[name, 'value']}
                          rules={[
                            {
                              required: true,
                              message: 'Wert fehlt!',
                            },
                          ]}
                        >
                          <Input placeholder="Wert" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'label']}
                          rules={[
                            {
                              required: true,
                              message: 'Beschreibung fehlt!',
                            },
                          ]}
                        >
                          <Input placeholder="Beschreibung" />
                        </Form.Item>
                        <FontAwesomeIcon
                          icon={faMinusCircle}
                          className="cursor-pointer"
                          onClick={() => remove(name)}
                        />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={
                          <FontAwesomeIcon
                            icon={faPlusCircle}
                            className="cursor-pointer"
                          />
                        }
                      >
                        Option hinzufügen
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </>
          )
        }
      </Form.Item>
    </>
  );
};

export default FormFieldSettings;
