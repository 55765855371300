import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Space } from 'antd';
import React from 'react';
import { TaskDefinition, TaskMessage } from '../../graphql/schema';
import Illustration from '../base/Illustration';
import TaskDefinitionForm from './TaskDefinitionForm';

interface Props {
  message: TaskMessage;
  taskDefinition: TaskDefinition;
  onClose: () => void;
  onSave: (formValues: any) => void;
}

const EditMessage = ({ message, taskDefinition, onClose, onSave }: Props) => {
  return (
    <>
      <div className="text-left mb-2">
        <Button type="text" size="small" onClick={onClose}>
          <Space>
            <FontAwesomeIcon icon={faArrowLeft} />
            Zurück
          </Space>
        </Button>
      </div>
      <div className="flex space-x-4 mb-3">
        <div className="w-1/4 max-w-[180px]">
          <Illustration id={taskDefinition.illustration} className="w-full" />
        </div>
        <div>
          <div className="text-lg font-semibold">{taskDefinition.title}</div>
          <p className="text-sm">{taskDefinition.description}</p>
        </div>
      </div>
      <TaskDefinitionForm
        schema={taskDefinition.formDefinition}
        onSubmit={onSave}
        values={message.input.formValues}
        saveButtonLabel="Weiter"
      />
    </>
  );
};

export default EditMessage;
