import { Card, Col, Form, FormInstance, Input, Row } from 'antd';
import React, { useCallback } from 'react';
import { Columns } from '../../components/admin/Table';
import TableWithDrawer from '../../components/admin/TableWithDrawer';
import PageHeader from '../../components/base/PageHeader';
import IllustrationSelect from '../../components/form/IllustrationSelect';
import {
  GetModulesQuery,
  GetModulesQueryVariables,
  Module,
  useCreateModuleMutation,
  useDeleteModuleMutation,
  useGetModulesQuery,
  useUpdateModuleMutation,
} from '../../graphql/schema';
import formItemProps from '../../helper/formItemProps';

const columns: Columns<Module> = {
  title: 'Titel',
};

interface FormFields {
  title: string;
  description: string;
  illustration: string;
}

const Modules = () => {
  const [create] = useCreateModuleMutation();
  const [update] = useUpdateModuleMutation();
  const [remove] = useDeleteModuleMutation();

  const handleCreate = useCallback(
    async (values: FormFields) => {
      await create({
        variables: {
          dto: {
            title: values.title,
            description: values.description,
            illustration: values.illustration,
          },
        },
        refetchQueries: ['GetModulesWithCategories'],
      });
    },
    [create],
  );

  const handleUpdate = useCallback(
    async (id: string, values: FormFields) => {
      await update({
        variables: {
          id,
          dto: {
            title: values.title,
            description: values.description,
            illustration: values.illustration,
          },
        },
        refetchQueries: ['GetModulesWithCategories'],
      });
    },
    [update],
  );

  const handleDelete = useCallback(
    async (id: string) => {
      await remove({
        variables: {
          id,
        },
        refetchQueries: ['GetModulesWithCategories'],
      });
    },
    [remove],
  );

  const formRenderer = (form: FormInstance) => {
    return (
      <Form layout="vertical" form={form}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item {...formItemProps('title', 'Titel', true)}>
              <Input placeholder="Titel" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <Form.Item {...formItemProps('description', 'Beschreibung', true)}>
              <Input.TextArea placeholder="Beschreibung" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item {...formItemProps('illustration', 'Illustration', true)}>
          <IllustrationSelect />
        </Form.Item>
      </Form>
    );
  };

  return (
    <Card>
      <PageHeader
        breadcrumbs={[
          {
            label: 'Module',
            link: '#',
          },
        ]}
      />
      <TableWithDrawer<
        FormFields,
        Module,
        GetModulesQuery,
        GetModulesQueryVariables
      >
        query={useGetModulesQuery}
        columns={columns}
        editFormRenderer={formRenderer}
        singleItemTitle="Modul"
        onCreate={handleCreate}
        onUpdate={handleUpdate}
        onDelete={handleDelete}
        prepareFormValues={(item) => ({
          title: item.title,
          description: item.description,
          illustration: item.illustration,
        })}
      />
    </Card>
  );
};

export default Modules;
