import { useAuth } from '@app/react-cognito';
import {
  faComment,
  faHouse,
  faSearch,
  faStar,
  faUsers,
  faWrench,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Navigation = () => {
  const { groups } = useAuth();
  const { pathname } = useLocation();

  const elements = useMemo(
    () => [
      {
        label: 'Übersicht',
        icon: faHouse,
        link: '/',
        prefixes: ['/module/', '/createTask/'],
      },
      {
        label: 'Meine Aktivitäten',
        icon: faComment,
        link: '/tasks',
        prefixes: ['/tasks/', '/task/'],
      },
      /*
      {
        label: 'Favoriten',
        icon: faStar,
        link: '/favorites',
      },
       */
      ...(groups.includes('SYSTEM_ADMIN') || groups.includes('COMPANY_ADMIN')
        ? [
            {
              label: 'Benutzer',
              icon: faUsers,
              link: '/users',
            },
          ]
        : []),
      ...(groups.includes('SYSTEM_ADMIN')
        ? [
            {
              label: 'System',
              icon: faWrench,
              link: '/admin',
            },
          ]
        : []),
      {
        label: 'Suche',
        icon: faSearch,
        link: '/search',
        prefixes: ['/search'],
      },
    ],
    [groups],
  );

  return (
    <div className="flex space-x-2">
      {elements.map(({ link, label, icon, prefixes }) => {
        const active = prefixes
          ? pathname === link || prefixes.find((p) => pathname.startsWith(p))
          : pathname.startsWith(link);
        return (
          <Link
            key={link}
            to={link}
            className={`theme-bg-primary-hover cursor-pointer text p-2 px-3 space-x-2 rounded-full ${active ? 'font-bold' : ''}`}
          >
            <FontAwesomeIcon icon={icon} />
            <span>{label}</span>
          </Link>
        );
      })}
    </div>
  );
};

export default Navigation;
