import { useAuth } from '@app/react-cognito';
import { faCog } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'antd';
import React, { MouseEvent, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { TaskDefinition } from '../../graphql/schema';
import Tile from './Tile';

interface Props {
  taskDefinition: TaskDefinition;
}

const TaskDefinitionTile = ({ taskDefinition }: Props) => {
  const navigate = useNavigate();
  const { hasGroup } = useAuth();

  const handleOpenSettings = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      navigate(`/taskDefinitionSettings/${taskDefinition.id}`);
    },
    [taskDefinition, navigate],
  );

  return (
    <Tile
      link={`/createTask/${taskDefinition.id}`}
      title={taskDefinition.title}
      description={taskDefinition.description}
      illustration={taskDefinition.illustration}
    >
      {hasGroup(['SYSTEM_ADMIN', 'COMPANY_ADMIN']) &&
        taskDefinition.settingsFormDefinition &&
        taskDefinition.settingsFormDefinition.length > 0 && (
          <Button
            type="text"
            className="absolute top-[15px] right-[15px] cursor-pointer"
            onClick={handleOpenSettings}
          >
            <FontAwesomeIcon icon={faCog} />
          </Button>
        )}
    </Tile>
  );
};

export default TaskDefinitionTile;
