import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useClickAway } from '@uidotdev/usehooks';
import {
  MutableRefObject,
  ReactElement,
  cloneElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';

interface ContextMenuEntryProps {
  key: string;
  label: string;
  icon: any;
  onClick: () => void;
  confirmText?: string;
}

interface ContextMenuElementProps {
  entry: ContextMenuEntryProps;
  onConfirm: () => void;
}

const ContextMenuElement = ({ entry, onConfirm }: ContextMenuElementProps) => {
  const [confirmActive, setConfirmActive] = useState<boolean>(false);

  const handleClick = useCallback(() => {
    if (confirmActive) return;

    if (entry.confirmText) {
      setConfirmActive(true);
    } else {
      entry.onClick();
      onConfirm();
    }
  }, [entry, onConfirm]);

  const handleConfirm = useCallback(() => {
    setConfirmActive(false);
    entry.onClick();
    onConfirm();
  }, [entry, onConfirm]);

  return (
    <div
      className={`flex flex-row text-sm hover:bg-gray-200 rounded w-full cursor-pointer p-2 items-center space-x-2 min-w-[220px] ${confirmActive ? 'bg-gray-200 hover:bg-gray-200 p-0' : ''}`}
      onClick={handleClick}
    >
      {confirmActive ? (
        <div className="flex flex-row justify-between w-full">
          <div className="p-2">{entry.confirmText}</div>
          <div className="flex flex-row">
            <div
              className="bg-gray-500 hover:bg-gray-500 text-white w-[30px] flex justify-center items-center"
              onClick={() => setConfirmActive(false)}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
            <div
              className="bg-red-400 hover:bg-red-400 text-white w-[30px] flex justify-center items-center rounded-tr rounded-br"
              onClick={handleConfirm}
            >
              {entry.icon}
            </div>
          </div>
        </div>
      ) : (
        <>
          {entry.icon}
          <span>{entry.label}</span>
        </>
      )}
    </div>
  );
};

interface ContextMenuPopoverProps {
  entries: ContextMenuEntryProps[];
  onClose: () => void;
  clickableAreaRef: MutableRefObject<HTMLDivElement | null>;
}

const ContextMenuPopover = ({
  entries,
  onClose,
  clickableAreaRef,
}: ContextMenuPopoverProps) => {
  const ref = useClickAway<HTMLDivElement>(() => {
    onClose();
  });
  const [position, setPosition] = useState<any>({});

  useEffect(() => {
    const rect = clickableAreaRef.current?.getBoundingClientRect();
    if (!rect) return;

    setPosition({
      left: rect.left + rect.width + 7,
      top: rect.top,
    });
  }, [clickableAreaRef]);

  return (
    <>
      <div
        className="absolute w-[11px] h-[11px] rotate-45 shadow"
        style={{
          left: position.left - 8,
          top: position.top + 16,
        }}
      />
      <div
        ref={ref}
        className="absolute bg-white rounded shadow p-1 flex flex-col space-y-1"
        style={position}
      >
        {entries.map((entry) => (
          <ContextMenuElement
            key={entry.key}
            entry={entry}
            onConfirm={onClose}
          />
        ))}
      </div>
      <div
        className="absolute w-[11px] h-[11px]"
        style={{
          left: position.left - 11,
          top: position.top + 11,
          borderColor: 'transparent #fff',
          borderWidth: '11px 11px 11px 0',
        }}
      />
    </>
  );
};

interface ContextMenuProps {
  visible: boolean;
  entries: ContextMenuEntryProps[];
  children: ReactElement;
}

const ContextMenu = ({ entries, children, visible }: ContextMenuProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const clickableAreaRef = useRef<HTMLDivElement | null>(null);

  return (
    <>
      {visible &&
        cloneElement(children, {
          onClick: (e: MouseEvent) => {
            // e.preventDefault();
            // e.stopPropagation();
            setOpen(true);
          },
          ref: clickableAreaRef,
        })}
      {open &&
        createPortal(
          <ContextMenuPopover
            clickableAreaRef={clickableAreaRef}
            onClose={() => setOpen(false)}
            entries={entries}
          />,
          document.body,
        )}
    </>
  );
};

export default ContextMenu;
