import { Spin } from 'antd';
import React from 'react';

const FullPageLoading = () => {
  return (
    <div className="flex w-full h-screen justify-center items-center content-center">
      <Spin size="large" />
    </div>
  );
};
export default FullPageLoading;
