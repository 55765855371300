import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Space } from 'antd';
import { Image as BaseImage } from 'antd';
import React, { useCallback } from 'react';
import FancyButton from './FancyButton';

interface Props {
  url: string;
  previewUrl?: string;
  width: number;
}

const Image = ({ url, previewUrl, width }: Props) => {
  const handleImageDownload = useCallback(() => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(new Blob([blob]));
        const link = document.createElement<'a'>('a');
        link.href = url;
        link.download = 'image.png';
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        link.remove();
      });
  }, [url]);

  return (
    <BaseImage
      src={previewUrl ?? url}
      width={width}
      className="rounded"
      style={{
        boxShadow: '0 0 1px rgba(0,0,0,0.2)',
      }}
      wrapperClassName="rounded overflow-hidden"
      preview={{
        toolbarRender: (_, { image: { url } }) => (
          <Space size={12} className="toolbar-wrapper">
            <FancyButton
              onClick={handleImageDownload}
              icon={<FontAwesomeIcon icon={faDownload} />}
            >
              Herunterladen
            </FancyButton>
          </Space>
        ),
      }}
    />
  );
};

export default Image;
