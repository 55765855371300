import { faLink } from '@fortawesome/pro-light-svg-icons';
import { fal as icons } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Select } from 'antd';
import React from 'react';

interface Props {
  value?: string;
  onChange?: (id: string) => void;
}

const options = Object.keys(icons).map((icon) => {
  return {
    value: icon,
    label: icon,
  };
});

const IconSelect = ({ value, onChange }: Props) => {
  return (
    <>
      <Select
        showSearch
        placeholder="Select an illustration"
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        onChange={onChange}
        value={value}
        options={options}
      />
      <a
        href="https://fontawesome.com/search"
        target="_blank"
        rel="noreferrer"
        style={{
          textDecoration: 'underline',
        }}
      >
        <FontAwesomeIcon icon={faLink} />
        &nbsp; Symbole durchsuchen
      </a>
      {value && (
        <div
          style={{
            marginTop: 15,
          }}
        >
          <div
            style={{
              border: '1px solid #eee',
              padding: 10,
              borderRadius: 10,
              display: 'inline-block',
            }}
          >
            <FontAwesomeIcon icon={icons[value]} size="2xl" />
          </div>
        </div>
      )}
    </>
  );
};

export default IconSelect;
