import * as Apollo from '@apollo/client';
import { OperationVariables } from '@apollo/client';
import type { QueryResult } from '@apollo/client/react/types/types';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, FormInstance, notification } from 'antd';
import React, { useMemo, useState } from 'react';
import AddDrawer from './AddDrawer';
import EditDrawer from './EditDrawer';
import Fab from './Fab';
import Table, { Columns } from './Table';

interface Props<
  FormFieldsType,
  ItemType,
  QueryType,
  QueryVariables extends OperationVariables,
> {
  query: (baseOptions?: any) => QueryResult<any, any>;
  queryVariables?: QueryVariables;
  columns: Columns<ItemType>;
  editFormRenderer: (form: FormInstance<FormFieldsType>) => JSX.Element;
  createFormRenderer?: (form: FormInstance<FormFieldsType>) => JSX.Element;
  singleItemTitle: string;
  onCreate?: (values: FormFieldsType) => Promise<void>;
  onUpdate?: (id: any, values: FormFieldsType, item: ItemType) => Promise<void>;
  onDelete?: (id: any, item: ItemType) => Promise<void>;
  prepareFormValues?: (values: ItemType) => FormFieldsType;
  emptyContent?: React.ReactNode;
  editDrawerExtra?: (
    item: ItemType,
    closeDrawer: () => void,
  ) => React.ReactNode;
  children?: any;
  rowActions?: (item: ItemType, editRow: () => void) => React.ReactNode;
  drawerWidth?: number;
}

function TableWithDrawer<
  FormFieldsType,
  ItemType,
  QueryType,
  QueryVariables extends OperationVariables,
>({
  query,
  queryVariables,
  columns,
  editFormRenderer,
  createFormRenderer,
  singleItemTitle,
  onCreate,
  onUpdate,
  onDelete,
  prepareFormValues,
  emptyContent,
  editDrawerExtra,
  children,
  rowActions,
  drawerWidth,
}: Props<FormFieldsType, ItemType, QueryType, QueryVariables>) {
  const { refetch, data, loading, fetchMore } = query({
    variables: queryVariables,
  });

  const [addDrawerVisible, setAddDrawerVisible] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<ItemType>();

  const handleCreate = async (values: FormFieldsType) => {
    if (!onCreate) return;
    await onCreate(values);
    refetch();
  };

  const handleUpdate = async (values: FormFieldsType) => {
    if (!onUpdate) return;

    await onUpdate((selectedItem as any).id, values, selectedItem as ItemType);
    notification.success({
      message: singleItemTitle,
      description: 'Alle Änderungen wurden gespeichert!',
    });
    refetch();
  };

  const handleDelete = async () => {
    if (!onDelete) return;

    await onDelete((selectedItem as any).id, selectedItem as ItemType);
    notification.success({
      message: singleItemTitle,
      description: `${singleItemTitle} wurde gelöscht!`,
    });
    refetch();
  };

  const [items, hasPagination, nextPaginationToken] = useMemo(() => {
    if (!data) return [[], false, undefined];

    const itemsData = data[Object.keys(data)[0]];

    if (itemsData?.items) {
      return [itemsData.items, true, itemsData.nextToken];
    }

    return [itemsData, false, undefined];
  }, [data]);

  return (
    <>
      {onCreate && (
        <Fab
          icon={<FontAwesomeIcon icon={faPlus} />}
          onClick={() => setAddDrawerVisible(true)}
          title={`${singleItemTitle} hinzufügen`}
        />
      )}

      <Table<ItemType>
        columns={columns}
        onClickRow={(record) => {
          setSelectedItem(record);
        }}
        emptyContent={emptyContent}
        items={items}
        rowActions={rowActions}
        loading={loading}
      >
        {children}
        {hasPagination && nextPaginationToken && (
          <Button
            onClick={() =>
              fetchMore({
                variables: {
                  options: {
                    startingToken: nextPaginationToken,
                  },
                },
              })
            }
          />
        )}
      </Table>

      {onCreate && (
        <AddDrawer<FormFieldsType>
          title={`${singleItemTitle} hinzufügen`}
          visible={addDrawerVisible}
          onClose={() => setAddDrawerVisible(false)}
          onSave={handleCreate}
          formRenderer={
            createFormRenderer ? createFormRenderer : editFormRenderer
          }
          width={drawerWidth}
        />
      )}

      <EditDrawer<FormFieldsType>
        title={`${singleItemTitle} bearbeiten`}
        visible={!!selectedItem}
        onClose={() => setSelectedItem(undefined)}
        onSave={onUpdate ? handleUpdate : undefined}
        onDelete={onDelete ? handleDelete : undefined}
        initialValues={
          selectedItem && prepareFormValues
            ? prepareFormValues(selectedItem)
            : (selectedItem as FormFieldsType)
        }
        formRenderer={editFormRenderer}
        extra={
          editDrawerExtra && selectedItem
            ? editDrawerExtra(selectedItem, () => setSelectedItem(undefined))
            : undefined
        }
        width={drawerWidth}
      />
    </>
  );
}

export default TableWithDrawer;
