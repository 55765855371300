/**
 * JS String.split() function removes all parts after the limit is reached.
 * This function adds all remaining parts to the last part.
 */
const splitString = (value: string, splitChar: string, limit?: number) => {
  const parts = value.split(splitChar);

  if (!limit) return parts;

  return [...parts.splice(0, limit - 1), parts.join(splitChar)];
};

export default splitString;
