import React, { ReactElement } from 'react';
import { Button, Space } from 'antd';

interface Props {
  icon: ReactElement;
  title?: string;
  onClick?: () => void;
  loading?: boolean;
}

const Fab = ({ icon, onClick, title, loading }: Props) => {
  return (
    <div
      style={{
        position: 'fixed',
        bottom: 30,
        right: 30,
        zIndex: 1000,
      }}
    >
      <Button
        type="primary"
        shape={title ? 'round' : 'circle'}
        onClick={loading ? undefined : onClick}
        size="large"
        loading={loading}
      >
        <Space>
          {!loading && icon}
          {title && <span>{title}</span>}
        </Space>
      </Button>
    </div>
  );
};

export default Fab;
