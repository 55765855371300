import { HttpLink, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { Session } from '@app/react-cognito';
import { API_URL } from '../../constants';

const generateLink = (refreshSession?: () => Promise<Session | undefined>) => {
  const httpConnectionLink = new HttpLink({
    uri: API_URL,
  });

  const authLink = setContext((_, previousContext) => {
    const savedCompanyId = window.localStorage.getItem('companyId');
    const token = window.localStorage.getItem('accessToken');

    return {
      ...previousContext,
      headers: {
        ...previousContext.headers,
        authorization: `Bearer ${token}`,
        ...(savedCompanyId
          ? {
              'x-company-id': savedCompanyId,
            }
          : {}),
      },
    };
  });

  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (!graphQLErrors) return;

      for (const { message } of graphQLErrors) {
        if (message !== 'Unauthorized') continue;

        const { getContext, setContext } = operation;
        const context = getContext();

        setContext({
          ...context,
          _needsRefresh: true,
        });

        return forward(operation);
      }
    },
  );

  const refreshLink = setContext(async (_, previousContext) => {
    if (previousContext?._needsRefresh && refreshSession) {
      await refreshSession();
    }

    return previousContext;
  });

  return from([errorLink, refreshLink, authLink, httpConnectionLink]);
};

export default generateLink;
