import { faLink } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Select } from 'antd';
import React from 'react';
import Illustration from '../../base/Illustration';
import illustrations from './illustrations';

interface Props {
  value?: string;
  onChange?: (id: string) => void;
}

interface Option {
  label: string;
  value: string;
}

const options = illustrations.map((file) => {
  const value = file.replace('.svg', '');
  return {
    value,
    label: value,
  };
});

const IllustrationSelect = ({ value, onChange }: Props) => {
  return (
    <>
      <Select
        showSearch
        placeholder="Select an illustration"
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        onChange={onChange}
        value={value}
        options={options}
      />
      <a
        href="https://undraw.co/illustrations"
        target="_blank"
        rel="noreferrer"
        style={{
          textDecoration: 'underline',
        }}
      >
        <FontAwesomeIcon icon={faLink} />
        &nbsp; Illustrationen durchsuchen
      </a>
      {value && (
        <div
          style={{
            width: '30%',
            marginTop: 15,
            border: '1px solid #eee',
            borderRadius: 10,
            padding: 5,
          }}
        >
          <Illustration id={value} className="w-full" />
        </div>
      )}
    </>
  );
};

export default IllustrationSelect;
