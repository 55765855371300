import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from 'antd';
import React, { useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  GenericPromptInput,
  useCreateTaskMutation,
} from '../../graphql/schema';
import FancyButton from '../base/FancyButton';
import TaskPrompt, { TaskPromptFormFields } from './TaskPrompt';

const GenericTask = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const [createTask] = useCreateTaskMutation();

  const [promptForm] = Form.useForm<TaskPromptFormFields>();

  const addMessageFromForm = useCallback(
    async (prompt: TaskPromptFormFields) => {
      setLoading(true);

      const task = await createTask({
        variables: {
          dto: {},
        },
        refetchQueries: ['GetTasks'],
      });

      window.localStorage.setItem(
        'newTaskMessage',
        JSON.stringify({
          taskId: task.data?.createTask.id,
          prompt,
        }),
      );

      setLoading(false);

      navigate(`/task/${task.data?.createTask.id}`);
    },
    [createTask, navigate],
  );

  return (
    <div className="flex flex-col flex-1 justify-center items-center">
      <div className="w-full space-y-3">
        <div className="w-full text-2xl font-semibold text-center">
          Wie kann ich helfen?
        </div>
        <TaskPrompt
          form={promptForm}
          onSend={addMessageFromForm}
          multiline
          loading={loading}
        />
        <div className="flex w-full space-y-2 flex-col items-center">
          <div className="text-sm">oder</div>
          <Link to="/">
            <FancyButton
              icon={<FontAwesomeIcon icon={faArrowRight} />}
              iconPosition="end"
            >
              Aufgaben entdecken
            </FancyButton>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default GenericTask;
