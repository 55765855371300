import { useDebounce } from '@uidotdev/usehooks';
import { useEffect, useState } from 'react';
import SearchInput from '../components/base/SearchInput';
import TaskDefinitionTile from '../components/tiles/TaskDefinitionTile';
import TilesGrid from '../components/tiles/TilesGrid';
import { useSearchTaskDefinitionsLazyQuery } from '../graphql/schema';

const TaskDefinitionSearch = () => {
  const [term, setTerm] = useState<string>('');
  const debouncedSearchTerm = useDebounce(term, 300);

  const [search, { data, loading }] = useSearchTaskDefinitionsLazyQuery();

  useEffect(() => {
    if (debouncedSearchTerm.trim() === '') return;

    search({
      variables: {
        options: {
          term: debouncedSearchTerm,
        },
      },
    });
  }, [debouncedSearchTerm, search]);

  return (
    <>
      <SearchInput value={term} onChange={setTerm} />

      <TilesGrid loading={loading}>
        {!loading &&
          term.trim() !== '' &&
          data?.taskDefinitionsSearch
            .map((x) => x)
            .sort((a, b) => a.title.localeCompare(b.title))
            .map((taskDefinition) => (
              <TaskDefinitionTile
                taskDefinition={taskDefinition}
                key={taskDefinition.id}
              />
            ))}
      </TilesGrid>
    </>
  );
};

export default TaskDefinitionSearch;
