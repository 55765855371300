import { useApolloClient } from '@apollo/client';
import { useAuth } from '@app/react-cognito';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Company, useGetCompaniesLazyQuery } from '../../graphql/schema';
import { defaultThemeColors } from '../theme/themeContext';
import useTheme from '../theme/useTheme';
import { CompanyContext } from './companyContext';

const CompanyProvider = ({ children }: { children: React.ReactNode }) => {
  const { isLoading, isAuthenticated } = useAuth();
  const { setColors } = useTheme();
  const apolloClient = useApolloClient();

  const [company, setCompany] = useState<Company>();

  const [loadCompanies, { data, loading }] = useGetCompaniesLazyQuery();

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      // Delay to make sure new session was loaded
      setTimeout(() => loadCompanies(), 100);
    }
  }, [isLoading, isAuthenticated]);

  const updateTheme = useCallback(
    (company: Company) => {
      setColors({
        primary: company.config.primaryColor ?? defaultThemeColors.primary,
        secondary:
          company.config.secondaryColor ?? defaultThemeColors.secondary,
        primaryText:
          company.config.primaryColorText ?? defaultThemeColors.primaryText,
      });
    },
    [setColors],
  );

  useEffect(() => {
    const savedCompanyId = window.localStorage.getItem('companyId');
    if (data) {
      if (savedCompanyId) {
        const newCompany = data?.companies.find((t) => t.id === savedCompanyId);
        if (newCompany) {
          setCompany(newCompany as Company);
          updateTheme(newCompany as Company);
          window.localStorage.setItem('companyId', `${newCompany.id}`);
          return;
        }
      }

      if (data.companies.length > 0) {
        setCompany(data.companies[0] as Company);
        updateTheme(data.companies[0] as Company);
        window.localStorage.setItem('companyId', `${data.companies[0].id}`);
      }
    }
  }, [data, updateTheme]);

  const changeCompany = useCallback(
    (id: string) => {
      const newCompany = data?.companies.find((t) => t.id === id);
      if (newCompany) {
        window.localStorage.setItem('companyId', `${newCompany.id}`);
        setCompany(newCompany as Company);
        updateTheme(newCompany as Company);
        apolloClient.resetStore();
      }
    },
    [data, updateTheme, apolloClient],
  );

  const providerValue = useMemo(() => {
    return {
      company:
        company ??
        ({
          config: {},
        } as Company),
      changeCompany: changeCompany,
      companies: (data?.companies ?? []) as Company[],
      isLoading: loading && !data?.companies,
    };
  }, [data, company, changeCompany, loading]);

  return (
    <CompanyContext.Provider value={providerValue}>
      {children}
    </CompanyContext.Provider>
  );
};

export default CompanyProvider;
