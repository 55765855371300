import { Select } from 'antd';
import React from 'react';
import { Role } from '../../graphql/schema';

interface Props {
  value?: string;
  onChange?: (id: string) => void;
  showSystemAdmin?: boolean;
}

interface Option {
  label: string;
  value: string;
}

const RoleSelect = ({ value, onChange, showSystemAdmin }: Props) => {
  const options: Option[] = [
    {
      label: 'Benutzer',
      value: Role.USER,
    },
    {
      label: 'Firmenadministrator',
      value: Role.COMPANY_ADMIN,
    },
    ...(showSystemAdmin
      ? [
          {
            label: 'Systemadministrator',
            value: Role.SYSTEM_ADMIN,
          },
        ]
      : []),
  ];

  return (
    <Select
      onChange={onChange}
      value={value}
      options={options}
      className="w-full"
    />
  );
};

export default RoleSelect;
