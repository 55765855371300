import React, { useMemo, useState } from 'react';
import { ThemeColors, ThemeContext, defaultThemeColors } from './themeContext';

const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const [colors, setColors] = useState<ThemeColors>(defaultThemeColors);

  const providerValue = useMemo(() => {
    return {
      colors,
      setColors,
    };
  }, [colors]);

  return (
    <ThemeContext.Provider value={providerValue}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
