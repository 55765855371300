import { useAuth } from '@app/react-cognito';
import { STREAM_API_URL } from '../constants';

const useStreamingMessageRequest = () => {
  const { session, refreshSession } = useAuth();

  return async <Message>(
    type: string,
    taskId: string,
    payload: any,
    onReceiveTextChunk: (chunk: string) => void,
  ): Promise<Message> => {
    if (!session.user?.accessToken) throw new Error('Missing access token');

    const companyId = window.localStorage.getItem('companyId');

    const executeFetch = (token?: string) =>
      fetch(STREAM_API_URL, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'x-company-id': companyId ?? '',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          type,
          taskId,
          payload,
        }),
      });

    let response = await executeFetch(session.user?.accessToken);

    if (response.status === 401) {
      // Refresh token
      const newSession = await refreshSession();
      // Retry
      if (newSession?.user) {
        response = await executeFetch(newSession.user.accessToken);
      }
    }

    if (!response.body || response.status !== 200)
      throw new Error('Invalid response');

    const reader = response.body.getReader();
    const decoder = new TextDecoder('utf-8');
    while (true) {
      const { value, done } = await reader.read();
      if (done) break;

      const chunkData = decoder.decode(value);

      if (chunkData.startsWith('ERROR:'))
        throw new Error(`Streaming response error: ${chunkData}`);

      if (chunkData.includes('___RESPONSE_END___')) {
        // Chunk contains response
        const [chunk, responseMessage] = chunkData.split('___RESPONSE_END___');
        onReceiveTextChunk(chunk);

        try {
          const parsedMessage = JSON.parse(responseMessage);

          if (parsedMessage.error) {
            throw new Error(parsedMessage.error);
          }
          return parsedMessage;
        } catch (e: any) {
          console.error(e);
          throw e;
        }
      } else {
        onReceiveTextChunk(chunkData);
      }
    }

    throw new Error('Missing final message response');
  };
};

export default useStreamingMessageRequest;
