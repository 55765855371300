import { AntdLogin } from '@app/react-cognito';
import { Card } from 'antd';
import React, { useEffect } from 'react';
import Loading from '../components/base/Loading';
import { defaultThemeColors } from '../context/theme/themeContext';
import useTheme from '../context/theme/useTheme';
import { useGetInstanceConfigQuery } from '../graphql/schema';

const Login = () => {
  const { setColors } = useTheme();
  const { data, loading } = useGetInstanceConfigQuery();

  useEffect(() => {
    if (data?.instanceConfig) {
      setColors({
        primary: data.instanceConfig.primaryColor ?? defaultThemeColors.primary,
        primaryText:
          data.instanceConfig.primaryColorText ??
          defaultThemeColors.primaryText,
        secondary:
          data.instanceConfig.secondaryColor ?? defaultThemeColors.secondary,
      });
    }
  }, [data]);

  if (loading) return <Loading />;

  return (
    <Card
      style={{
        width: 400,
        margin: 20,
      }}
    >
      <div className="flex justify-center mb-5">
        {data?.instanceConfig.logo ? (
          <img
            src={data?.instanceConfig.logo}
            alt={data?.instanceConfig.name ?? ''}
            className="max-h-[40px]"
          />
        ) : (
          data?.instanceConfig.name
        )}
      </div>

      <AntdLogin allowRegistration={false} />
    </Card>
  );
};

export default Login;
