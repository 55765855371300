import React from 'react';
import { createPortal } from 'react-dom';
import { ReactSVG } from 'react-svg';
import useTheme from '../../context/theme/useTheme';

interface Props {
  id: string;
  className: string;
  height?: number;
}

const headRoot = document.head;

export const ThemeStyles = () => {
  const { colors } = useTheme();

  const css = `
    .illustration svg *[fill="#6c63ff"] { fill: ${colors.primary}; }
    .theme-bg-primary { background-color: ${colors.primary}; color: ${colors.primaryText}; }    
    .theme-bg-primary:hover { background-color: ${colors.primary} !important; color: ${colors.primaryText} !important; }    
    .theme-bg-primary-hover:hover { background-color: ${colors.primary}; color: ${colors.primaryText}; }    
  `;

  return createPortal(<style type="text/css">{css}</style>, headRoot);
};

const Illustration = ({ id, className, height }: Props) => {
  return (
    <div
      className={`${className} illustration ${height ? 'illustration-h' : ''}`}
      style={{ height }}
    >
      <ReactSVG src={`/images/illustrations/${id}.svg`} />
    </div>
  );
};
export default Illustration;
