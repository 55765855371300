import { Card, Col, Form, FormInstance, Input, Row } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { Columns } from '../../components/admin/Table';
import TableWithDrawer from '../../components/admin/TableWithDrawer';
import PageHeader from '../../components/base/PageHeader';
import IconSelect from '../../components/form/IconSelect';
import IllustrationSelect from '../../components/form/IllustrationSelect';
import ModuleSelect from '../../components/form/ModuleSelect';
import {
  Category,
  GetCategoriesQuery,
  GetCategoriesQueryVariables,
  useCreateCategoryMutation,
  useDeleteCategoryMutation,
  useGetCategoriesQuery,
  useUpdateCategoryMutation,
} from '../../graphql/schema';
import formItemProps from '../../helper/formItemProps';

const columns: Columns<Category> = {
  title: 'Titel',
};

interface FormFields {
  moduleId: string;
  title: string;
  description: string;
  illustration: string;
  icon: string;
}

const Categories = () => {
  const [moduleId, setModuleId] = useState<string | undefined>(
    window.localStorage.getItem('moduleId') ?? undefined,
  );

  useEffect(() => {
    if (moduleId) {
      window.localStorage.setItem('moduleId', moduleId ?? '');
    } else {
      window.localStorage.removeItem('moduleId');
    }
  }, [moduleId]);

  const [create] = useCreateCategoryMutation();
  const [update] = useUpdateCategoryMutation();
  const [remove] = useDeleteCategoryMutation();

  const handleCreate = useCallback(
    async (values: FormFields) => {
      await create({
        variables: {
          dto: {
            moduleId: values.moduleId ?? moduleId,
            title: values.title,
            description: values.description,
            illustration: values.illustration,
            icon: values.icon,
          },
        },
        refetchQueries: ['GetModulesWithCategories'],
      });
    },
    [create, moduleId],
  );

  const handleUpdate = useCallback(
    async (id: string, values: FormFields) => {
      await update({
        variables: {
          id,
          dto: {
            moduleId: values.moduleId,
            title: values.title,
            description: values.description,
            illustration: values.illustration,
            icon: values.icon,
          },
        },
        refetchQueries: ['GetModulesWithCategories'],
      });
    },
    [update],
  );

  const handleDelete = useCallback(
    async (id: string) => {
      await remove({
        variables: {
          id,
        },
        refetchQueries: ['GetModulesWithCategories'],
      });
    },
    [remove],
  );

  const formRenderer = (type: 'create' | 'edit') => (form: FormInstance) => {
    return (
      <Form layout="vertical" form={form}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item {...formItemProps('title', 'Titel', true)}>
              <Input placeholder="Titel" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <Form.Item {...formItemProps('description', 'Beschreibung', true)}>
              <Input.TextArea placeholder="Beschreibung" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item {...formItemProps('illustration', 'Illustration', true)}>
          <IllustrationSelect />
        </Form.Item>

        <Form.Item {...formItemProps('icon', 'Symbol', true)}>
          <IconSelect />
        </Form.Item>

        {type === 'edit' && (
          <Form.Item {...formItemProps('moduleId', 'Modul', true)}>
            <ModuleSelect />
          </Form.Item>
        )}
      </Form>
    );
  };

  return (
    <Card>
      <PageHeader
        breadcrumbs={[
          {
            label: 'Kategorien',
            link: '#',
          },
        ]}
      />

      <div className="flex space-x-2 items-center h-[32px] w-[300px] mb-4">
        <span>Modul:</span>
        <ModuleSelect value={moduleId} onChange={setModuleId} />
      </div>

      {moduleId && (
        <TableWithDrawer<
          FormFields,
          Category,
          GetCategoriesQuery,
          GetCategoriesQueryVariables
        >
          query={useGetCategoriesQuery}
          queryVariables={{
            moduleId,
          }}
          columns={columns}
          editFormRenderer={formRenderer('edit')}
          createFormRenderer={formRenderer('create')}
          singleItemTitle="Kategorie"
          onCreate={handleCreate}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          prepareFormValues={(item) => ({
            moduleId: item.moduleId ?? moduleId,
            title: item.title,
            description: item.description,
            illustration: item.illustration,
            icon: item.icon,
          })}
        />
      )}
    </Card>
  );
};

export default Categories;
