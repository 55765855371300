import { faImage, faSpinner, faUpload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Upload as BaseUpload, message } from 'antd';
import DisabledContext from 'antd/es/config-provider/DisabledContext';
import { RcFile } from 'antd/es/upload';
import { useCallback, useContext, useEffect, useState } from 'react';
import styles from './FileStringUpload.module.scss';

interface Props {
  value?: string;
  onChange?: (val: string) => void;
}

const FileStringUpload = ({ value, onChange }: Props) => {
  const disabled = useContext(DisabledContext);

  const [previewUrl, setPreviewUrl] = useState<string>();

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setPreviewUrl(value);
  }, [value]);

  const onBeforeUpload = useCallback((file: RcFile) => {
    if (file.type !== 'image/png') {
      message.error('Bitte laden Sie Bilder im Format PNG hoch!');
      return false;
    }

    if (file.size / 1024 / 1024 > 1) {
      message.error('Bilder dürfen maximal 1MB groß sein!');
      return false;
    }

    return true;
  }, []);

  const onUpload = useCallback(
    async ({ file, onSuccess, onError }: any) => {
      setLoading(true);

      const imgData = await new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          // Resize image
          const img = document.createElement('img');

          img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            ctx?.drawImage(img, 0, 0);

            const MAX_WIDTH = 400;
            const MAX_HEIGHT = 80;
            let width = img.width;
            let height = img.height;

            if (img.width > MAX_WIDTH || img.height >= MAX_HEIGHT) {
              if (width > height) {
                if (width > MAX_WIDTH) {
                  height *= MAX_WIDTH / width;
                  width = MAX_WIDTH;
                }
              } else {
                if (height > MAX_HEIGHT) {
                  width *= MAX_HEIGHT / height;
                  height = MAX_HEIGHT;
                }
              }
            }

            canvas.width = width;
            canvas.height = height;

            const ctx2 = canvas.getContext('2d');
            ctx2?.drawImage(img, 0, 0, width, height);

            resolve(canvas.toDataURL('image/png'));
          };

          img.src = reader.result as string;
        };
      });

      setLoading(false);

      if (!imgData) {
        onError();
        return;
      }

      setPreviewUrl(imgData);
      if (onChange) onChange(imgData);

      onSuccess();
    },
    [onChange],
  );

  return (
    <div className={styles.container}>
      <BaseUpload
        beforeUpload={onBeforeUpload}
        customRequest={onUpload}
        maxCount={1}
        showUploadList={false}
      >
        <div className={styles.preview}>
          {loading && (
            <span className={styles.loading}>
              <FontAwesomeIcon icon={faSpinner} size="2x" color="#222" spin />
            </span>
          )}
          {!loading && previewUrl && <img src={previewUrl} alt="" />}
          {!loading && !previewUrl && (
            <FontAwesomeIcon icon={faImage} size="2x" color="#ccc" />
          )}

          <div
            style={
              disabled
                ? {
                    display: 'none',
                  }
                : undefined
            }
          >
            <FontAwesomeIcon icon={faUpload} size="2x" />
            Bild hochladen
          </div>
        </div>
      </BaseUpload>
    </div>
  );
};

export default FileStringUpload;
