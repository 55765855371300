import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Drawer, Form, FormInstance, Space, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import confirmModal from '../../helper/confirmModal';

export interface Props<FormFieldsType> {
  visible: boolean;
  onClose: () => void;
  initialValues?: Partial<FormFieldsType>;
  onSave?: (values: FormFieldsType) => void;
  onDelete?: () => void;
  title: string;
  formRenderer: (form: FormInstance<FormFieldsType>) => JSX.Element;
  extra?: React.ReactNode;
  width?: number;
}

function EditDrawer<FormFieldsType>({
  visible,
  onClose,
  onSave,
  initialValues,
  onDelete,
  title,
  formRenderer,
  extra,
  width,
}: Props<FormFieldsType>) {
  const [form] = Form.useForm<FormFieldsType>();

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (initialValues) {
      form.resetFields();
      form.setFieldsValue(initialValues as any);
    }
  }, [initialValues, form]);

  const handleSave = async () => {
    if (!onSave) return;
    if (loading) return;

    try {
      setLoading(true);

      const values = await form.validateFields();

      await onSave(values);
      onClose();

      setLoading(false);
    } catch (e) {
      setLoading(false);
      notification.error({
        message: 'Error',
        description: 'Error while saving',
      });
    }
  };

  const handleDelete = async () => {
    if (!onDelete) return;
    if (loading) return;

    if (
      await confirmModal({
        title: 'Löschen',
        content: 'Das löschen kann nicht Rückgängig gemacht werden!',
        okText: 'Endgültig löschen',
        okType: 'danger',
        cancelText: 'Cancel',
      })
    ) {
      try {
        setLoading(true);
        await onDelete();
        onClose();
        setLoading(false);
      } catch (e) {
        setLoading(false);
        notification.error({
          message: 'Fehler',
          description: 'Fehler beim Löschen.',
        });
      }
    }
  };

  return (
    <Drawer
      title={title}
      open={visible}
      onClose={onClose}
      extra={
        <Space>
          {extra}

          {onDelete && (
            <Button danger onClick={handleDelete} loading={loading}>
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          )}
        </Space>
      }
      width={width ?? 600}
    >
      {formRenderer(form)}
      {onSave && (
        <Button type="primary" onClick={handleSave} loading={loading}>
          Speichern
        </Button>
      )}
    </Drawer>
  );
}

export default EditDrawer;
