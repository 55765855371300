export interface Props {
  value?: string;
  onChange?: (value: string) => void;
}

const SimpleCodeInput = ({ value, onChange }: Props) => {
  return (
    <textarea
      className="border border-none rounded bg-[#1f2937] text-white font-mono w-full min-h-[250px]"
      value={`${value ?? ''}`}
      onChange={(e) => {
        if (onChange) onChange(e.target.value);
      }}
    />
  );
};

export default SimpleCodeInput;
