import { Table as AntTable, Button, Card, Empty, Result } from 'antd';
import React, { useState } from 'react';

export interface ColumnDefinition<ItemType, ValueType> {
  label: string;
  render?: (
    value: ValueType,
    record: ItemType,
    index: number,
  ) => React.ReactNode;
  align?: 'left' | 'right' | 'center';
  className?: string;
  colSpan?: number;
}

type KnownColumns<ItemType> = {
  [Property in keyof ItemType]?:
    | string
    | ColumnDefinition<ItemType, ItemType[Property]>;
};

interface UnknownColumns<ItemType> {
  [key: string]: string | ColumnDefinition<ItemType, never>;
}

export type Columns<ItemType> = KnownColumns<ItemType> &
  UnknownColumns<ItemType>;

interface Props<ItemType> {
  columns: Columns<ItemType>;
  onClickRow?: (item: ItemType) => void;
  onSelectRows?: (selectedRows: React.Key[]) => void;
  children?: any;
  emptyContent?: React.ReactNode;
  loading?: boolean;
  items: ItemType[];
  rowActions?: (item: ItemType, editRow: () => void) => React.ReactNode;
}

function Table<ItemType>({
  columns,
  onClickRow,
  onSelectRows,
  children,
  emptyContent,
  loading,
  items,
  rowActions,
}: Props<ItemType>) {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  if (!loading && !items) {
    return (
      <Result
        status="500"
        title="Fehler"
        subTitle="Fehler beim Laden der Daten"
        extra={
          <Button
            type="primary"
            onClick={() => {
              window.location.reload();
            }}
          >
            Reload
          </Button>
        }
      />
    );
  }

  if ((!items || items.length === 0) && emptyContent)
    return <div>{emptyContent}</div>;

  return (
    <div>
      <AntTable
        loading={loading}
        size="small"
        locale={{
          emptyText: <Empty description="No entries" />,
        }}
        pagination={false}
        dataSource={items as any[]}
        rowKey="id"
        columns={[
          ...Object.keys(columns).map((columnKey) => {
            const column = columns[
              columnKey as keyof ItemType
            ] as ColumnDefinition<ItemType, any>;
            if (typeof column === 'string') {
              return {
                title: column,
                dataIndex: columnKey,
                key: columnKey,
              };
            }

            return {
              title: column.label,
              dataIndex: columnKey,
              key: columnKey,
              render: column.render,
              align: column.align,
              className: column.className,
              colSpan: column.colSpan,
            } as any;
          }),
          ...(rowActions
            ? [
                {
                  title: '',
                  dataIndex: '_actions',
                  key: '_actions',
                  align: 'right',
                  render: (_: any, item: ItemType) => {
                    return rowActions(item, () => {
                      if (onClickRow) onClickRow(item);
                    });
                  },
                },
              ]
            : []),
        ]}
        onRow={
          !rowActions
            ? (record) => {
                return {
                  onClick: () => {
                    if (onClickRow) onClickRow(record);
                  },
                  style: {
                    cursor: 'pointer',
                  },
                };
              }
            : undefined
        }
        rowSelection={
          onSelectRows
            ? {
                selectedRowKeys,
                onChange: (newSelectedRowKeys: React.Key[]) => {
                  setSelectedRowKeys(newSelectedRowKeys);
                  onSelectRows(newSelectedRowKeys);
                },
              }
            : undefined
        }
      />
      {children}
    </div>
  );
}

export default Table;
