import React from 'react';
import { Company } from '../../graphql/schema';

interface TenantContextProps {
  changeCompany: (companyId: string) => void;
  company?: Company;
  companies: Company[];
  isLoading: boolean;
}

export const CompanyContext = React.createContext<TenantContextProps>({
  changeCompany: () => {},
  company: undefined,
  companies: [],
  isLoading: true,
});
