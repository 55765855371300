import {
  faCopy,
  faHeadSideBrain,
  faPencil,
} from '@fortawesome/pro-light-svg-icons';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Skeleton, notification } from 'antd';
import React from 'react';
import useTheme from '../../context/theme/useTheme';
import { FormFieldDefinition, TaskMessage } from '../../graphql/schema';
import FormattedMessage from '../base/FormattedMessage/formattedMessage';
import Image from '../base/Image';
import Logo from '../base/Logo';
import fields from '../formBuilder/fields';

export type TaskMessageWithLoadingIndicator = TaskMessage & {
  loading?: boolean;
};

interface Props {
  message: TaskMessageWithLoadingIndicator;
  onEditMessage?: (message: TaskMessage) => void;
  formFieldDefinitions: FormFieldDefinition[];
}

const Message = ({ message, onEditMessage, formFieldDefinitions }: Props) => {
  const { colors } = useTheme();

  return (
    <div className="w-full flex flex-col space-y-6">
      {(message.input.followUpPrompt || message.input.prompt) && (
        <div className="w-2/4 self-end flex flex-row items-start">
          <div className="opacity-40 hover:opacity-100">
            {message.input.prompt && onEditMessage && (
              <Button
                type="text"
                onClick={() => onEditMessage(message)}
                className="rounded-full"
              >
                <FontAwesomeIcon icon={faPencil} />
              </Button>
            )}
          </div>
          <div className="w-full bg-white bg-opacity-50 rounded-xl p-3 text-sm ml-1">
            {message.input.prompt?.prompt ??
              message.input.followUpPrompt?.label}
          </div>
        </div>
      )}

      {message.input.formValues && (
        <div className="w-3/4 self-end flex flex-row items-start">
          <div className="opacity-40 hover:opacity-100">
            {onEditMessage && (
              <Button
                type="text"
                onClick={() => onEditMessage(message)}
                className="rounded-full"
              >
                <FontAwesomeIcon icon={faPencil} />
              </Button>
            )}
          </div>
          <div className="w-full bg-white bg-opacity-50 rounded-xl p-3 text-sm ml-1">
            {formFieldDefinitions.map((formField) => {
              const field = fields[formField.type];
              if (
                !field ||
                message.input.formValues[formField.name] === undefined
              )
                return null;

              return (
                <div key={formField.name}>
                  <strong>{formField.label}: </strong>
                  {field.renderReadOnly(
                    message.input.formValues[formField.name],
                    formField,
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}

      <div className="flex flex-row items-start">
        <div className="rounded-full flex w-[30px] h-[30px] items-center justify-center mr-4 theme-bg-primary">
          <Logo color={colors.primaryText} width={15} />
        </div>
        <div className="w-3/4 self-start bg-white rounded-xl p-3 text-sm mr-1">
          {message.loading && !message.response?.text && (
            <Skeleton
              active
              round
              paragraph={{
                rows: 2,
              }}
            />
          )}

          {message.loading && message.response?.text && (
            <div className="prose w-full max-w-full text-base">
              <FormattedMessage text={message.response.text} />{' '}
              <FontAwesomeIcon icon={faCircle} color="#ccc" />
            </div>
          )}

          {!message.loading && message.response?.text && (
            <div className="prose w-full max-w-full text-base">
              <FormattedMessage text={message.response.text} />
            </div>
          )}

          {!message.loading && message.response?.files && (
            <div className="flex flex-row space-x-2 w-full">
              {message.response.files
                .filter((file) => !!file)
                .map((file) => (
                  <Image url={file.url} width={200} key={file.id} />
                ))}
            </div>
          )}
        </div>
        <div className="flex flex-row opacity-40 hover:opacity-100 self-end">
          {!message.loading && (
            <Button
              type="text"
              onClick={() => {
                navigator.clipboard.writeText(message.response?.text ?? '');
                notification.info({
                  message: 'Die Nachricht wurde in die Zwischenablage kopiert',
                });
              }}
              className="rounded-full"
            >
              <FontAwesomeIcon icon={faCopy} />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Message;
