import { faHouse } from '@fortawesome/pro-light-svg-icons';
import { Typography, notification } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Illustration from '../components/base/Illustration';
import Loading from '../components/base/Loading';
import PageHeader from '../components/base/PageHeader';
import TaskDefinitionForm from '../components/tasks/TaskDefinitionForm';
import {
  useGetModulesWithCategoriesQuery,
  useGetTaskDefinitionQuery,
  useGetTaskDefinitionSettingsQuery,
  useUpdateTaskDefinitionSettingsMutation,
} from '../graphql/schema';

const TaskDefinitionSettings = () => {
  const { taskDefinitionId } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const { data, loading: dataLoading } = useGetTaskDefinitionQuery({
    variables: {
      id: taskDefinitionId ?? '',
    },
    skip: !taskDefinitionId,
  });

  const { data: settings, loading: settingsLoading } =
    useGetTaskDefinitionSettingsQuery({
      variables: {
        taskDefinitionId: taskDefinitionId ?? '',
      },
      skip: !taskDefinitionId,
    });

  const [updateSettings] = useUpdateTaskDefinitionSettingsMutation();

  const handleSubmit = useCallback(
    async (formValues: any) => {
      setLoading(true);
      await updateSettings({
        variables: {
          taskDefinitionId: taskDefinitionId ?? '',
          dto: {
            settings: formValues,
          },
        },
        refetchQueries: ['GetTasks'],
      });

      setLoading(false);

      notification.success({
        message: 'Die Einstellungen wurden gespeichert.',
      });

      navigate(-1);
    },
    [taskDefinitionId, navigate, updateSettings],
  );

  const { data: modules, loading: modulesLoading } =
    useGetModulesWithCategoriesQuery();

  const [module, category] = useMemo(() => {
    if (modules) {
      for (const m of modules.modulesWithCategories) {
        if (m.categories) {
          for (const c of m.categories) {
            if (c.id === data?.taskDefinition.categoryId) {
              return [m, c];
            }
          }
        }
      }
    }

    return [undefined, undefined];
  }, [data, modules]);

  if (dataLoading || settingsLoading) return <Loading />;

  return (
    <div>
      <PageHeader
        breadcrumbs={[
          {
            icon: faHouse,
            link: '/',
          },
          {
            label: module?.title,
            link: `/module/${module?.id}`,
          },
          {
            label: category?.title,
            link: `/module/${module?.id}`,
          },
          {
            label: data?.taskDefinition.title,
            link: '#',
          },
        ]}
      />
      {data?.taskDefinition && (
        <>
          <Typography.Title level={3}>Einstellungen</Typography.Title>

          <div className="flex space-x-4 mb-3 relative">
            <div className="w-1/4 max-w-[180px]">
              <Illustration
                id={data.taskDefinition.illustration}
                className="w-full"
              />
            </div>
            <div>
              <div className="text-lg font-semibold">
                {data.taskDefinition.title}
              </div>
              <p className="text-sm">{data.taskDefinition.description}</p>
            </div>
          </div>
          <TaskDefinitionForm
            values={settings?.taskDefinitionSettings.settings}
            schema={data?.taskDefinition.settingsFormDefinition}
            onSubmit={handleSubmit}
            saveButtonLabel="Speichern"
            loading={loading}
            hideSaveButtonIcon
          />
        </>
      )}
    </div>
  );
};

export default TaskDefinitionSettings;
