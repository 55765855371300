import { FollowUpPrompt, TaskMessage } from '../graphql/schema';
import useStreamingMessageRequest from './useStreamingMessageRequest';

const useCreateMessageWithFollowUpPrompt = (taskId?: string) => {
  const streamingMessageRequest = useStreamingMessageRequest();

  return async (
    followUpPrompt: FollowUpPrompt,
    onReceiveTextChunk: (chunk: string) => void,
  ): Promise<TaskMessage> => {
    if (!taskId) throw new Error('Missing task ID');

    return streamingMessageRequest<TaskMessage>(
      'createTaskMessageWithFollowUpPrompt',
      taskId,
      followUpPrompt,
      onReceiveTextChunk,
    );
  };
};

export default useCreateMessageWithFollowUpPrompt;
