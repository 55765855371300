import { faHeadSideBrain } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Form, Row } from 'antd';
import React from 'react';
import { FormFieldDefinition } from '../../graphql/schema';
import formItemProps from '../../helper/formItemProps';
import styles from './FormField.module.scss';
import fields from './fields';

interface Props {
  field: FormFieldDefinition;
  children?: any;
}

const FormField = ({ field, children }: Props) => {
  const type = fields[field.type];

  return (
    <div className={styles.container}>
      <Row gutter={24}>
        <Col md={16} xs={24}>
          <Form.Item
            {...formItemProps(field.name, field.label, !!field.required)}
            help={field.description}
            valuePropName={type.valuePropName}
          >
            {type.renderComponent(field)}
          </Form.Item>
        </Col>
        <Col md={8} xs={24}>
          {field.description && field.description.trim() !== '' && (
            <div className="h-[22px] max-[767px]:hidden" />
          )}
          {field.tooltip && (
            <div className="bg-white max-[767px]:mt-[15px] min-[768px]:mt-[32px] rounded p-3">
              <FontAwesomeIcon icon={faHeadSideBrain} className="mr-2" />
              {field.tooltip}
            </div>
          )}
        </Col>
      </Row>
      {children}
    </div>
  );
};

export default FormField;
