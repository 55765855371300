import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, FormInstance, Input } from 'antd';
import React, { useCallback } from 'react';

export interface TaskPromptFormFields {
  prompt: string;
}

interface Props {
  form: FormInstance<TaskPromptFormFields>;
  onSend: (prompt: TaskPromptFormFields) => Promise<void>;
  multiline?: boolean;
  loading?: boolean;
}

const TaskPrompt = ({ onSend, multiline, loading, form }: Props) => {
  const handleSend = useCallback(
    async (formValues: TaskPromptFormFields) => {
      if (loading) return;
      await onSend(formValues);
      form.resetFields();
    },
    [onSend, loading, form],
  );

  if (multiline) {
    return (
      <div className="w-full justify-center flex mb-3">
        <Form
          form={form}
          onFinish={handleSend}
          className="flex flex-row pt-3 space-x-4 w-2/3 relative rounded-2xl border-0 bg-white p-2 pb-10"
        >
          <Form.Item name="prompt" required noStyle>
            <Input.TextArea
              className="border-0 bg-white outline-0 focus:outline-0 focus:border-none focus:shadow-none"
              placeholder="Wie kann ich helfen?"
              id="promptTextField"
              autoSize={{ minRows: 3, maxRows: 15 }}
            />
          </Form.Item>
          <Button
            htmlType="submit"
            className="absolute rounded-full border-0 h-[35px] !w-[35px] theme-bg-primary right-2 bottom-2"
            loading={loading}
          >
            {!loading && <FontAwesomeIcon icon={faArrowRight} />}
          </Button>
        </Form>
      </div>
    );
  }

  return (
    <div className="w-full justify-center flex mb-3">
      <Form
        form={form}
        onFinish={handleSend}
        className="flex flex-row pt-3 space-x-4 w-2/3"
      >
        <Form.Item name="prompt" required noStyle>
          <Input
            className="rounded-full border-0 bg-white p-3"
            placeholder="Wie kann ich helfen?"
            id="promptTextField"
          />
        </Form.Item>
        <Button
          htmlType="submit"
          className="rounded-full border-0 h-[54px] w-[54px] theme-bg-primary"
          loading={loading}
        >
          <FontAwesomeIcon icon={faArrowRight} />
        </Button>
      </Form>
    </div>
  );
};

export default TaskPrompt;
