import { faFileSpreadsheet, faPencil } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  FormInstance,
  Input,
  Row,
  Space,
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Columns } from '../../components/admin/Table';
import TableWithDrawer from '../../components/admin/TableWithDrawer';
import PageHeader from '../../components/base/PageHeader';
import CategorySelect from '../../components/form/CategorySelect';
import IllustrationSelect from '../../components/form/IllustrationSelect';
import KeywordsSelect from '../../components/form/KeywordsSelect';
import {
  GetTaskDefinitionsQuery,
  GetTaskDefinitionsQueryVariables,
  TaskDefinition,
  useCreateTaskDefinitionMutation,
  useDeleteTaskDefinitionMutation,
  useGetTaskDefinitionsQuery,
  useUpdateTaskDefinitionMutation,
} from '../../graphql/schema';
import formItemProps from '../../helper/formItemProps';

const columns: Columns<TaskDefinition> = {
  title: 'Titel',
};

interface FormFields {
  categoryId: string;
  title: string;
  description: string;
  illustration: string;
  keywords: string[];
  active: boolean;
}

const TaskDefinitions = () => {
  const navigate = useNavigate();
  const [categoryId, setCategoryId] = useState<string | undefined>(
    window.localStorage.getItem('categoryId') ?? undefined,
  );

  useEffect(() => {
    if (categoryId) {
      window.localStorage.setItem('categoryId', categoryId ?? '');
    } else {
      window.localStorage.removeItem('categoryId');
    }
  }, [categoryId]);

  const [create] = useCreateTaskDefinitionMutation();
  const [update] = useUpdateTaskDefinitionMutation();
  const [remove] = useDeleteTaskDefinitionMutation();

  const handleCreate = useCallback(
    async (values: FormFields) => {
      await create({
        variables: {
          dto: {
            categoryId: values.categoryId ?? categoryId,
            title: values.title,
            description: values.description,
            illustration: values.illustration,
            keywords: values.keywords,
            active: values.active,
          },
        },
      });
    },
    [create, categoryId],
  );

  const handleUpdate = useCallback(
    async (id: string, values: FormFields) => {
      await update({
        variables: {
          id,
          dto: {
            categoryId: values.categoryId,
            title: values.title,
            description: values.description,
            illustration: values.illustration,
            keywords: values.keywords,
            active: values.active,
          },
        },
      });
    },
    [update],
  );

  const handleDelete = useCallback(
    async (id: string) => {
      await remove({
        variables: {
          id,
        },
      });
    },
    [remove],
  );

  const formRenderer = (type: 'create' | 'edit') => (form: FormInstance) => {
    return (
      <Form layout="vertical" form={form}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item {...formItemProps('title', 'Titel', true)}>
              <Input placeholder="Titel" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <Form.Item {...formItemProps('description', 'Beschreibung', true)}>
              <Input.TextArea placeholder="Beschreibung" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item {...formItemProps('illustration', 'Illustration', true)}>
          <IllustrationSelect />
        </Form.Item>

        <Form.Item {...formItemProps('keywords', 'Stichwörter', false)}>
          <KeywordsSelect />
        </Form.Item>

        {type === 'edit' && (
          <Form.Item {...formItemProps('categoryId', 'Kategorie', true)}>
            <CategorySelect />
          </Form.Item>
        )}

        <Form.Item
          {...formItemProps('active', 'Aktiv', true)}
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
      </Form>
    );
  };

  return (
    <Card>
      <PageHeader
        breadcrumbs={[
          {
            label: 'Aufgaben',
            link: '#',
          },
        ]}
      />

      <div className="flex space-x-2 items-center h-[32px] w-[300px] mb-4">
        <span>Kategorie:</span>
        <CategorySelect value={categoryId} onChange={setCategoryId} />
      </div>

      {categoryId && (
        <TableWithDrawer<
          FormFields,
          TaskDefinition,
          GetTaskDefinitionsQuery,
          GetTaskDefinitionsQueryVariables
        >
          query={useGetTaskDefinitionsQuery}
          queryVariables={{
            categoryId,
          }}
          columns={columns}
          editFormRenderer={formRenderer('edit')}
          createFormRenderer={formRenderer('create')}
          singleItemTitle="Aufgabe"
          onCreate={handleCreate}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          prepareFormValues={(item) => ({
            categoryId: item.categoryId ?? categoryId,
            title: item.title,
            description: item.description,
            illustration: item.illustration,
            keywords: item.keywords ?? [],
            active: !!item.active,
          })}
          rowActions={(item, editRow) => (
            <Space>
              <Button type="text" onClick={editRow}>
                <FontAwesomeIcon icon={faPencil} />
              </Button>
              <Button
                type="text"
                onClick={() => navigate(`/admin/taskDefinition/${item.id}`)}
              >
                <FontAwesomeIcon icon={faFileSpreadsheet} />
              </Button>
            </Space>
          )}
        />
      )}
    </Card>
  );
};

export default TaskDefinitions;
