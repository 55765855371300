import { Select } from 'antd';
import React from 'react';

interface Props {
  value?: string[];
  onChange?: (value: string[]) => void;
}

const KeywordsSelect = ({ value, onChange }: Props) => {
  return (
    <Select
      mode="tags"
      style={{ width: '100%' }}
      onChange={onChange}
      value={value}
      tokenSeparators={[',']}
    />
  );
};

export default KeywordsSelect;
