import { faCopy, faFileDownload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, notification } from 'antd';
import React, { useCallback, useMemo } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { darcula, docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

interface Props {
  className?: string;
  children: any;
  isInline?: boolean;
  language?: string;
}

const CodeBlock = ({ children, className, isInline, language }: Props) => {
  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(`${children}`);
    notification.info({
      message: 'Der Inhalt wurde in die Zwischenablage kopiert',
    });
  }, [children]);

  const [fileExtension, mimeType] = useMemo(() => {
    if (language === 'xml') return ['xml', 'text/xml'];

    return [null, null];
  }, [language]);

  const RenderingElement = useMemo(() => {
    if (language === 'markdown')
      return ({ children, ...props }: any) => (
        <pre
          {...props}
          className={`${props.className ?? ''} bg-gray-100 p-3 rounded text-black`}
          style={{
            ...props.style,
            minHeight: isInline ? undefined : 40,
          }}
        >
          {children}
        </pre>
      );

    if (isInline) {
      return ({ children, ...props }: any) => (
        <pre
          {...props}
          style={{
            margin: 0,
            padding: 1,
            paddingLeft: 5,
            paddingRight: 5,
            top: 7,
          }}
        >
          <code {...props}>{children}</code>
        </pre>
      );
    }

    return ({ children, ...props }: any) => (
      <SyntaxHighlighter
        language={language}
        style={darcula}
        customStyle={{
          minHeight: 40,
          borderRadius: '0.25rem',
          ...props.style,
        }}
        codeTagProps={{
          style: props.style,
        }}
      >
        {children}
      </SyntaxHighlighter>
    );
  }, [language, isInline]);

  const handleDownload = useCallback(() => {
    if (!fileExtension) return;

    const a = document.createElement('a');
    const blob = new Blob([children], { type: mimeType });
    const url = URL.createObjectURL(blob);
    a.setAttribute('href', url);
    a.setAttribute('download', `file.${fileExtension}`);
    a.click(); // Start downloading
  }, [fileExtension, mimeType, children]);

  return (
    <div
      className="relative"
      style={{
        display: isInline ? 'inline-block' : 'block',
      }}
    >
      <RenderingElement
        className={className}
        style={{
          position: 'relative',
          wordWrap: 'break-word',
          whiteSpace: 'break-spaces',
          wordBreak: 'break-word',
        }}
      >
        {children}
      </RenderingElement>
      {!isInline && (
        <div className="absolute top-0 right-0 text-white bg-[#222] rounded-tr rounded-bl">
          {fileExtension && (
            <Button
              type="text"
              onClick={handleDownload}
              className="!text-white !hover:text-white py-1 px-3"
              title="Als Datei herunterladen"
            >
              <FontAwesomeIcon icon={faFileDownload} />
            </Button>
          )}
          <Button
            type="text"
            onClick={handleCopy}
            className="!text-white !hover:text-white py-1 px-3"
            title="In die Zwischenablage kopieren"
          >
            <FontAwesomeIcon icon={faCopy} />
          </Button>
        </div>
      )}
    </div>
  );
};

export default CodeBlock;
