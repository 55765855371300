import { Card, Form, Space } from 'antd';
import React, { useCallback, useEffect } from 'react';
import {
  File as ApiFile,
  FormFieldDefinition,
  Provider,
  TaskDefinitionProviderConfig,
} from '../../graphql/schema';
import FollowUpPrompts from './FollowUpPrompts';
import TemplateFormField from './TemplateFormField';

interface Props {
  provider: Provider;
  config: TaskDefinitionProviderConfig;
  onConfigChange: (config: TaskDefinitionProviderConfig) => void;
  variables: any;
  messageSchema: FormFieldDefinition[];
  settingsSchema: FormFieldDefinition[];
  files: ApiFile[];
}

const MessageTemplateBuilder = ({
  provider,
  config,
  onConfigChange,
  variables,
  messageSchema,
  settingsSchema,
  files,
}: Props) => {
  const [form] = Form.useForm();

  useEffect(() => {
    const fieldValues = {
      messageTemplate: config.messageTemplate,
      settingsTemplate: config.settingsTemplate,
      followUpPrompts: config.followUpPrompts,
    };

    provider.messageTemplateFields.forEach((field) => {
      if (
        fieldValues.messageTemplate &&
        fieldValues.messageTemplate[field.name] === undefined &&
        field.default
      ) {
        fieldValues.messageTemplate[field.name] = field.default;
      }
    });

    provider.settingsTemplateFields.forEach((field) => {
      if (
        fieldValues.settingsTemplate &&
        fieldValues.settingsTemplate[field.name] === undefined &&
        field.default
      ) {
        fieldValues.settingsTemplate[field.name] = field.default;
      }
    });

    form.setFieldsValue(fieldValues);
  }, [config, form, provider]);

  const handleChange = useCallback(() => {
    onConfigChange(form.getFieldsValue());
  }, [form, onConfigChange]);

  const { form: _, ...variablesWithoutForm } = variables;

  return (
    <Form form={form} layout="vertical" onBlur={handleChange}>
      <Space direction="vertical" className="w-full mb-[100px]">
        <Card title="Prompt Vorlage">
          {provider.messageTemplateFields.map((field) => (
            <TemplateFormField
              key={field.name}
              name={['messageTemplate', field.name]}
              field={field}
              variables={variables}
              messageSchema={messageSchema}
              settingsSchema={settingsSchema}
              files={files}
            />
          ))}
        </Card>

        <Card title="Einstellungen Vorlage">
          {provider.settingsTemplateFields.map((field) => (
            <TemplateFormField
              key={field.name}
              name={['settingsTemplate', field.name]}
              field={field}
              variables={variablesWithoutForm}
              messageSchema={messageSchema}
              settingsSchema={settingsSchema}
              files={files}
            />
          ))}
        </Card>

        {provider.supportsFollowUpPrompts && (
          <Card title="Folge-Prompts">
            <FollowUpPrompts variables={variablesWithoutForm} />
          </Card>
        )}
      </Space>
    </Form>
  );
};

export default MessageTemplateBuilder;
