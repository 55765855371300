import React from 'react';
import { Module } from '../../graphql/schema';
import Tile from './Tile';

interface Props {
  module: Module;
}

const ModuleTile = ({ module }: Props) => {
  return (
    <Tile
      link={`/module/${module.id}`}
      title={module.title}
      description={module.description}
      illustration={module.illustration}
    />
  );
};

export default ModuleTile;
