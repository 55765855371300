import { useAuth } from '@app/react-cognito';
import React, { useEffect } from 'react';
import { FeedbackContext } from './feedbackContext';

const FeedbackProvider = ({ children }: { children: React.ReactNode }) => {
  const { session, isLoading } = useAuth();

  useEffect(() => {
    if (isLoading) return;

    if (session.user?.userAttributes.email) {
      (window as any).bugyard('setUser', {
        email: session.user?.userAttributes.email,
        id: session.user?.userAttributes.sub,
      });
    } else {
      (window as any).bugyard('freeUser');
    }
  }, [session, isLoading]);

  return (
    <FeedbackContext.Provider value={{}}>{children}</FeedbackContext.Provider>
  );
};

export default FeedbackProvider;
