import * as Sentry from '@sentry/react';

import React, { useMemo } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import dayjs from 'dayjs';
import { COGNITO_CLIENT_ID, COGNITO_USER_POOL_ID } from './constants';
import 'dayjs/locale/de';
import { AuthProvider } from '@app/react-cognito';
import { ConfigProvider, ThemeConfig } from 'antd';
import locale from 'antd/es/locale/de_DE';
import ApiProvider from './context/api/apiProvider';
import CompanyProvider from './context/company/companyProvider';
import FeedbackProvider from './context/feedback/feedbackProvider';
import ThemeProvider from './context/theme/themeProvider';
import useTheme from './context/theme/useTheme';

if (import.meta.env.VITE_SENTRY_DSN?.length) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_DOMAIN,
    integrations: [
      Sentry.httpClientIntegration({
        failedRequestStatusCodes: [400, 403, [405, 599]], // report errors for 400, 403 and 405 - 599 status codes
      }),
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],

    // Tracing
    tracesSampleRate: 0.2, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

dayjs.locale('de-DE');

const Root = () => {
  const { colors } = useTheme();

  const theme = useMemo(
    () =>
      ({
        token: {
          colorPrimary: colors.primary,
          colorSuccess: '#52c41a',
          colorLink: '#222',
          borderRadius: 2,
        },
        components: {
          Layout: {
            headerBg: colors.primary,
            siderBg: '#fff',
          },
          Menu: {
            itemActiveBg: '#CCE9FF',
            itemSelectedBg: '#CCE9FF',
          },
        },
      }) satisfies ThemeConfig,
    [colors],
  );

  return (
    <ConfigProvider locale={locale} theme={theme}>
      <AuthProvider
        clientId={COGNITO_CLIENT_ID}
        userPoolId={COGNITO_USER_POOL_ID}
        language="deFormal"
      >
        <FeedbackProvider>
          <ApiProvider>
            <CompanyProvider>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </CompanyProvider>
          </ApiProvider>
        </FeedbackProvider>
      </AuthProvider>
    </ConfigProvider>
  );
};

const root = createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <Root />
    </ThemeProvider>
  </React.StrictMode>,
);
