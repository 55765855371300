import { Button, Drawer, Form, FormInstance, notification } from 'antd';
import React, { useState } from 'react';

export interface Props<FormFieldsType> {
  visible: boolean;
  onClose: () => void;
  onSave: (values: FormFieldsType) => void;
  title: string;
  formRenderer: (form: FormInstance<FormFieldsType>) => JSX.Element;
  width?: number;
}

function AddDrawer<FormFieldsType>({
  visible,
  onClose,
  onSave,
  title,
  formRenderer,
  width,
}: Props<FormFieldsType>) {
  const [form] = Form.useForm<FormFieldsType>();

  const [loading, setLoading] = useState<boolean>(false);

  const handleSave = async () => {
    if (loading) return;
    try {
      setLoading(true);
      const values = await form.validateFields();
      await onSave(values);
      onClose();
      form.resetFields();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      notification.error({
        message: 'Fehler',
        description: 'Fehler beim Speichern',
      });
    }
  };

  return (
    <Drawer title={title} open={visible} onClose={onClose} width={width ?? 600}>
      {formRenderer(form)}
      <Button type="primary" onClick={handleSave} loading={loading}>
        Hinzufügen
      </Button>
    </Drawer>
  );
}

export default AddDrawer;
