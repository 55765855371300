import { useAuth } from '@app/react-cognito';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import FullPageLoading from './components/base/FullPageLoading';
import AdminLayout from './components/layout/AdminLayout';
import Layout from './components/layout/Layout';
import LoginLayout from './components/layout/LoginLayout';
import useCompany from './context/company/useCompany';
import ChangePassword from './pages/ChangePassword';
import CreateGenericTask from './pages/CreateGenericTask';
import CreateTask from './pages/CreateTask';
import Error404 from './pages/Error404';
import Login from './pages/Login';
import Overview from './pages/Overview';
import Task from './pages/Task';
import TaskDefinitionSearch from './pages/TaskDefinitionSearch';
import TaskDefinitionSettings from './pages/TaskDefinitionSettings';
import TaskOverview from './pages/TaskOverview';
import Users from './pages/Users';
import AdminDashboard from './pages/admin/AdminDashboard';
import Categories from './pages/admin/Categories';
import Companies from './pages/admin/Companies';
import InstanceConfig from './pages/admin/InstanceConfig';
import Modules from './pages/admin/Modules';
import ProviderConfigs from './pages/admin/ProviderConfigs';
import TaskDefinition from './pages/admin/TaskDefinition';
import TaskDefinitions from './pages/admin/TaskDefinitions';

const App = () => {
  const { isLoading: authIsLoading, isAuthenticated } = useAuth();
  const { isLoading } = useCompany();

  if (authIsLoading || isLoading) return <FullPageLoading />;

  return isAuthenticated ? (
    <Routes>
      <Route element={<LoginLayout />}>
        <Route element={<Login />} path="login" />
      </Route>
      <Route element={<Layout />}>
        <Route element={<Overview />} path="" />
        <Route element={<TaskOverview />} path="module/:moduleId" />
        <Route
          element={<TaskDefinitionSettings />}
          path="taskDefinitionSettings/:taskDefinitionId"
        />
        <Route element={<Users />} path="users" />
        <Route element={<ChangePassword />} path="changePassword" />
        <Route element={<Task />} path="tasks" />
        <Route element={<Task />} path="task/:taskId" />
        <Route element={<TaskDefinitionSearch />} path="search" />
        <Route element={<CreateTask />} path="createTask/:taskDefinitionId" />
        <Route element={<CreateGenericTask />} path="createTask" />
        <Route path="admin" element={<AdminLayout />}>
          <Route element={<AdminDashboard />} path="" />
          <Route element={<InstanceConfig />} path="instanceConfig" />
          <Route element={<ProviderConfigs />} path="providerConfigs" />
          <Route element={<Categories />} path="categories" />
          <Route element={<Companies />} path="companies" />
          <Route element={<Modules />} path="modules" />
          <Route element={<TaskDefinitions />} path="taskDefinitions" />
          <Route element={<TaskDefinition />} path="taskDefinition/:id" />
          <Route element={<Error404 />} path="*" />
        </Route>
        <Route element={<Error404 />} path="*" />
      </Route>
    </Routes>
  ) : (
    <Routes>
      <Route element={<LoginLayout />}>
        <Route element={<Login />} path="" />
        <Route element={<Login />} path="*" />
      </Route>
    </Routes>
  );
};

export default App;
