import React from 'react';
import ReactMarkdown from 'react-markdown';
import ReHypeKatex from 'rehype-katex';
import RemarkMath from 'remark-math';
import CodeBlock from './CodeBlock';

interface Props {
  text: string;
}

const FormattedMessage = ({ text }: Props) => {
  return (
    <ReactMarkdown
      remarkPlugins={[RemarkMath]}
      rehypePlugins={[
        [
          ReHypeKatex,
          {
            output: 'mathml',
          },
        ],
      ]}
      components={{
        pre: ({ children, className, node }) => {
          return <div>{children}</div>;
        },
        code: ({ children, className, node }) => {
          const languageClasses = Array.isArray(node?.properties.className)
            ? node.properties.className
                .filter((e) => `${e}`.startsWith('language-'))
                .map((e) => `${e}`.replace('language-', ''))
            : [];
          const language =
            languageClasses.length > 0 ? languageClasses[0] : undefined;

          return (
            <CodeBlock
              className={className}
              isInline={
                node?.position?.start &&
                node?.position?.end &&
                node.position.start.line === node.position.end.line
              }
              language={language}
            >
              {children}
            </CodeBlock>
          );
        },
      }}
      className="text-sm"
    >
      {text.replace(/\\\((.+?)\\\)/g, '$ $1 $')}
    </ReactMarkdown>
  );
};

export default FormattedMessage;
