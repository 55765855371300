import {Select} from 'antd';
import React from 'react';
import {countries} from "../../helper/countries";

interface Props {
  value?: string;
  onChange?: (id: string) => void;
}

interface Option {
  label: string;
  value: string;
}

const options: Option[] =
  countries.map((country) => {
    return {
      label: country.name,
      value: country.name,
    };
  }) || [];

const CountrySelect = ({ value, onChange }: Props) => {
  return (
    <Select
      onChange={onChange}
      value={value}
      options={options}
      className="w-full"
      showSearch
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
    />
  );
};

export default CountrySelect;
