import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import Illustration from '../base/Illustration';

interface Props {
  link: string;
  illustration: string;
  title: string;
  description: string;
  children?: any;
}

const Tile = ({ title, link, description, illustration, children }: Props) => {
  return (
    <Link
      to={link}
      className="relative rounded-xl bg-white flex-row flex p-[30px] cursor-pointer hover:ring w-full min-h-[250px]"
    >
      <Illustration
        id={illustration}
        className="flex-none absolute bottom-[30px] right-[30px]"
        height={90}
      />

      <div>
        <p className="text-xl font-semibold">{title}</p>
        <div className="mt-2 text-gray-500 text-xs">{description}</div>
      </div>

      <FontAwesomeIcon
        icon={faArrowRight}
        size="xl"
        className="absolute bottom-[30px] left-[30px]"
      />

      {children}
    </Link>
  );
};

export default Tile;
