import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef } from 'react';

interface Props {
  value: string;
  onChange: (value: string) => void;
}

const SearchInput = ({ onChange, value }: Props) => {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    ref.current?.focus();
  }, []);

  return (
    <div
      className="flex justify-center items-center mb-7"
      style={{
        minHeight: value === '' ? '80vh' : undefined,
      }}
    >
      <div className="relative max-w-[600px] w-full ">
        <input
          ref={ref}
          type="search"
          placeholder="Suche nach Aufgabenstellungen..."
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className="p-3 px-5 pl-11 w-full rounded-full"
        />
        <FontAwesomeIcon
          icon={faSearch}
          className="absolute top-[15px] left-[18px]"
        />
      </div>
    </div>
  );
};

export default SearchInput;
