interface Props {
  color: string;
  width: number;
}

const Logo = ({ color, width }: Props) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 477 491"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
        width,
      }}
    >
      <g transform="matrix(0.365952,0,0,0.837563,244.64,-288.245)">
        <path
          d="M258.708,344.147L546.998,344.147C594.071,344.147 632.289,360.845 632.289,381.413L632.289,467.475C632.289,488.042 594.071,504.741 546.998,504.741L376.417,504.741C329.343,504.741 291.126,488.042 291.126,467.475L291.126,384.295C291.126,369.099 278.983,355.132 258.708,344.147Z"
          style={{
            fill: color,
          }}
        />
      </g>
      <g transform="matrix(0.970733,0,0,0.837563,-282.605,-288.245)">
        <path
          d="M577.045,504.741L325.766,504.741C306.648,504.741 291.126,486.751 291.126,464.592L291.126,384.295C291.126,362.137 306.648,344.147 325.766,344.147L597.648,344.147C616.767,344.147 632.289,362.137 632.289,384.295L632.289,568.768C632.289,533.43 607.535,504.741 577.045,504.741Z"
          style={{
            fill: color,
          }}
        />
      </g>
      <g transform="matrix(6.03708e-17,0.985929,-0.837563,5.1286e-17,599.423,-132.522)">
        <path
          d="M632.289,384.295L632.289,464.592C632.289,486.751 617.006,504.741 598.182,504.741L291.126,504.741L291.126,384.295C291.126,362.137 306.409,344.147 325.233,344.147L598.182,344.147C617.006,344.147 632.289,362.137 632.289,384.295Z"
          style={{
            fill: color,
          }}
        />
      </g>
    </svg>
  );
};

export default Logo;
