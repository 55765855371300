import { Select } from 'antd';
import React from 'react';
import { useGetCompaniesQuery } from '../../graphql/schema';

interface Props {
  value?: string | null;
  onChange?: (id?: string | null) => void;
  nullable?: boolean;
}

interface Option {
  label: string;
  value?: string | null;
}

const CompanySelect = ({ value, onChange, nullable }: Props) => {
  const { data, loading } = useGetCompaniesQuery();

  const options: Option[] =
    data?.companies.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    }) || [];

  if (nullable) {
    options.push({
      label: '-',
      value: null,
    });
  }

  return (
    <Select
      onChange={onChange}
      loading={loading}
      value={value}
      options={options}
      className="w-full"
    />
  );
};

export default CompanySelect;
