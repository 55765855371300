import React, { useMemo, useState } from 'react';
import CodeBlock from './CodeBlock';

interface Props {
  children: any;
}

const CollapsibleCodeBlock = ({ children }: Props) => {
  const [collapsed, setCollapsed] = useState<boolean>(true);

  const collapsible = useMemo(() => {
    return `${children}`.split('\n').length >= 3;
  }, [children]);

  return (
    <div className="bg-[#2b2b2b] rounded">
      <div
        className="m-0"
        style={
          collapsible
            ? {
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              }
            : {}
        }
      >
        <CodeBlock>
          {collapsible && collapsed
            ? `${children}`
                .split('\n')
                .filter((_, i) => i < 3)
                .join('\n')
            : children}
        </CodeBlock>
        {collapsible && collapsed && (
          <div className="text-gray-400 ml-[10px]">...</div>
        )}
      </div>
      {collapsible && (
        <div
          onClick={() => setCollapsed((c) => !c)}
          className="rounded-bl rounded-br text-gray-400 text-xs pl-[10px] pb-2 cursor-pointer"
        >
          {collapsed ? 'Ausklappen' : 'Einklappen'}
        </div>
      )}
    </div>
  );
};

export default CollapsibleCodeBlock;
