export const languages = {
  ab: 'Abchasisch',
  ace: 'Aceh-Sprache',
  ach: 'Acholi-Sprache',
  ada: 'Adangme',
  ady: 'Adygeisch',
  aa: 'Afar',
  afh: 'Afrihili',
  af: 'Afrikaans',
  agq: 'Aghem',
  egy: '\u00c4gyptisch',
  arz: '\u00c4gyptisches Arabisch',
  ain: 'Ainu-Sprache',
  ak: 'Akan',
  akk: 'Akkadisch',
  bss: 'Akoose',
  akz: 'Alabama',
  sq: 'Albanisch',
  ale: 'Aleutisch',
  arq: 'Algerisches Arabisch',
  nwc: 'Alt-Newari',
  ang: 'Altenglisch',
  fro: 'Altfranz\u00f6sisch',
  grc: 'Altgriechisch',
  goh: 'Althochdeutsch',
  sga: 'Altirisch',
  non: 'Altnordisch',
  peo: 'Altpersisch',
  prg: 'Altpreu\u00dfisch',
  pro: 'Altprovenzalisch',
  syc: 'Altsyrisch',
  ase: 'Amerikanische Geb\u00e4rdensprache',
  en_US: 'Amerikanisches Englisch',
  am: 'Amharisch',
  anp: 'Angika',
  njo: 'Ao-Naga',
  ar: 'Arabisch',
  an: 'Aragonesisch',
  arc: 'Aram\u00e4isch',
  aro: 'Araona',
  arp: 'Arapaho-Sprache',
  arn: 'Araukanisch',
  arw: 'Arawak-Sprache',
  hy: 'Armenisch',
  rup: 'Aromunisch',
  az: 'Aserbaidschanisch',
  as: 'Assamesisch',
  ast: 'Asturianisch',
  cch: 'Atsam',
  en_AU: 'Australisches Englisch',
  ae: 'Avestisch',
  awa: 'Awadhi',
  av: 'Awarisch',
  ay: 'Aymara',
  bqi: 'Bachtiarisch',
  bfq: 'Badaga',
  ksf: 'Bafia',
  bfd: 'Bafut',
  bar: 'Bairisch',
  ban: 'Balinesisch',
  bm: 'Bambara-Sprache',
  bax: 'Bamun',
  bjn: 'Banjaresisch',
  bas: 'Basaa-Sprache',
  ba: 'Baschkirisch',
  eu: 'Baskisch',
  bbc: 'Batak Toba',
  bej: 'Bedauye',
  bal: 'Belutschisch',
  bem: 'Bemba-Sprache',
  bez: 'Bena',
  bn: 'Bengalisch',
  mrj: 'Bergmari',
  bew: 'Betawi',
  bho: 'Bhodschpuri',
  dz: 'Bhutanisch',
  bik: 'Bikol-Sprache',
  bin: 'Bini-Sprache',
  my: 'Birmanisch',
  bpy: 'Bishnupriya',
  bi: 'Bislama',
  bla: 'Blackfoot-Sprache',
  byn: 'Blin',
  zbl: 'Bliss-Symbole',
  brx: 'Bodo',
  bs: 'Bosnisch',
  brh: 'Brahui',
  bra: 'Braj-Bhakha',
  pt_BR: 'Brasilianisches Portugiesisch',
  br: 'Bretonisch',
  en_GB: 'Britisches Englisch',
  bug: 'Buginesisch',
  bg: 'Bulgarisch',
  bum: 'Bulu',
  bua: 'Burjatisch',
  cad: 'Caddo',
  frc: 'Cajun',
  cps: 'Capiznon',
  cay: 'Cayuga',
  ceb: 'Cebuano',
  ch: 'Chamorro-Sprache',
  chr: 'Cherokee',
  chy: 'Cheyenne',
  chb: 'Chibcha-Sprache',
  qug: 'Chimborazo Hochland-Quechua',
  zh: 'Chinesisch',
  zh_Hant: 'Chinesisch (traditionell)',
  zh_Hans: 'Chinesisch (vereinfacht)',
  chn: 'Chinook',
  chp: 'Chipewyan',
  cho: 'Choctaw',
  cr: 'Cree',
  dak: 'Dakota-Sprache',
  da: 'D\u00e4nisch',
  dar: 'Darginisch',
  dzg: 'Dazaga',
  del: 'Delaware-Sprache',
  de: 'Deutsch',
  din: 'Dinka-Sprache',
  dyo: 'Diola',
  doi: 'Dogri',
  dgr: 'Dogrib',
  dua: 'Duala',
  dyu: 'Dyula-Sprache',
  efi: 'Efik',
  eka: 'Ekajuk',
  elx: 'Elamisch',
  tvl: 'Elliceanisch',
  ebu: 'Embu',
  egl: 'Emilianisch',
  en: 'Englisch',
  myv: 'Ersja-Mordwinisch',
  eo: 'Esperanto',
  et: 'Estnisch',
  pt_PT: 'Europ\u00e4isches Portugiesisch',
  es_ES: 'Europ\u00e4isches Spanisch',
  ee: 'Ewe',
  ewo: 'Ewondo',
  ext: 'Extremadurisch',
  fat: 'Fanti-Sprache',
  gur: 'Farefare',
  fo: 'F\u00e4r\u00f6isch',
  fj: 'Fidschi',
  hif: 'Fidschi-Hindi',
  fil: 'Filipino',
  fi: 'Finnisch',
  nl_BE: 'Fl\u00e4misch',
  fon: 'Fon-Sprache',
  frp: 'Frankoprovenzalisch',
  fr: 'Franz\u00f6sisch',
  fur: 'Friulisch',
  ff: 'Ful',
  gaa: 'Ga-Sprache',
  gbz: 'Gabri',
  gag: 'Gagausisch',
  gl: 'Galizisch',
  gan: 'Gan',
  lg: 'Ganda',
  gay: 'Gayo',
  gba: 'Gbaya-Sprache',
  gez: 'Geez',
  aln: 'Gegisch',
  ka: 'Georgisch',
  bbj: 'Ghomala',
  glk: 'Gilaki',
  gil: 'Gilbertesisch',
  gom: 'Goa-Konkani',
  gon: 'Gondi-Sprache',
  got: 'Gotisch',
  grb: 'Grebo-Sprache',
  el: 'Griechisch',
  kl: 'Gr\u00f6nl\u00e4ndisch',
  gn: 'Guarani',
  gu: 'Gujarati',
  guz: 'Gusii',
  hai: 'Haida-Sprache',
  ht: 'Haitianisch',
  hak: 'Hakka',
  ha: 'Hausa',
  haw: 'Hawaiisch',
  he: 'Hebr\u00e4isch',
  hz: 'Herero-Sprache',
  hit: 'Hethitisch',
  hil: 'Hiligaynon-Sprache',
  hi: 'Hindi',
  ho: 'Hiri-Motu',
  hup: 'Hupa',
  iba: 'Iban',
  ibb: 'Ibibio',
  io: 'Ido-Sprache',
  ig: 'Igbo',
  ilo: 'Ilokano-Sprache',
  smn: 'Inari-Lappisch',
  id: 'Indonesisch',
  inh: 'Inguschisch',
  ia: 'Interlingua',
  ie: 'Interlingue',
  iu: 'Inuktitut',
  ik: 'Inupiak',
  ga: 'Irisch',
  izh: 'Ischorisch',
  is: 'Isl\u00e4ndisch',
  it: 'Italienisch',
  sah: 'Jakutisch',
  jam: 'Jamaikanisch-kreolische Sprache',
  ja: 'Japanisch',
  jv: 'Javanisch',
  yi: 'Jiddisch',
  kaj: 'Jju',
  jrb: 'J\u00fcdisch-Arabisch',
  jpr: 'J\u00fcdisch-Persisch',
  jut: 'J\u00fctisch',
  quc: 'K\u2019iche\u2019',
  kbd: 'Kabardinisch',
  kea: 'Kabuverdianu',
  kab: 'Kabylisch',
  kac: 'Kachin-Sprache',
  kgp: 'Kaingang',
  kkj: 'Kako',
  kln: 'Kalenjin',
  xal: 'Kalm\u00fcckisch',
  kam: 'Kamba',
  km: 'Kambodschanisch',
  en_CA: 'Kanadisches Englisch',
  fr_CA: 'Kanadisches Franz\u00f6sisch',
  kbl: 'Kanembu',
  kn: 'Kannada',
  yue: 'Kantonesisch',
  kr: 'Kanuri-Sprache',
  kaa: 'Karakalpakisch',
  krc: 'Karatschaiisch-Balkarisch',
  krl: 'Karelisch',
  car: 'Karibisch',
  kk: 'Kasachisch',
  ks: 'Kaschmirisch',
  csb: 'Kaschubisch',
  ca: 'Katalanisch',
  kaw: 'Kawi',
  zxx: 'Keine Sprachinhalte',
  ken: 'Kenyang',
  kha: 'Khasi-Sprache',
  mag: 'Khotta',
  khw: 'Khowar',
  ki: 'Kikuyu',
  kmb: 'Kimbundu-Sprache',
  krj: 'Kinaray-a',
  cu: 'Kirchenslawisch',
  ky: 'Kirgisisch',
  kiu: 'Kirmanjki',
  lzh: 'Klassisches Chinesisch',
  tlh: 'Klingonisch',
  ksh: 'K\u00f6lsch',
  bkm: 'Kom',
  koi: 'Komi-Permjakisch',
  kv: 'Komi-Sprache',
  swb: 'Komorisch',
  swc: 'Kongo-Suaheli',
  kg: 'Kongolesisch',
  kok: 'Konkani',
  cop: 'Koptisch',
  ko: 'Koreanisch',
  kw: 'Kornisch',
  kfo: 'Koro',
  co: 'Korsisch',
  kos: 'Kosraeanisch',
  avk: 'Kotava',
  khq: 'Koyra Chiini',
  ses: 'Koyra Senni',
  kpe: 'Kpelle-Sprache',
  crh: 'Krimtatarisch',
  kri: 'Krio',
  hr: 'Kroatisch',
  kum: 'Kum\u00fckisch',
  ku: 'Kurdisch',
  gwi: 'Kutchin-Sprache',
  kut: 'Kutenai-Sprache',
  kj: 'Kwanyama',
  nmg: 'Kwasio',
  lad: 'Ladino',
  lah: 'Lahnda',
  lkt: 'Lakota',
  lam: 'Lamba-Sprache',
  lag: 'Langi',
  lo: 'Laotisch',
  lzz: 'Lasisch',
  la: 'Latein',
  es_419: 'Lateinamerikanisches Spanisch',
  lez: 'Lesgisch',
  ltg: 'Lettgallisch',
  lv: 'Lettisch',
  lij: 'Ligurisch',
  li: 'Limburgisch',
  ln: 'Lingala',
  lfn: 'Lingua Franca Nova',
  lt: 'Litauisch',
  liv: 'Livisch',
  jbo: 'Lojban',
  lmo: 'Lombardisch',
  lu: 'Luba-Katanga',
  lua: 'Luba-Lulua',
  luy: 'Luhya',
  lui: 'Luiseno-Sprache',
  smj: 'Lule-Lappisch',
  lun: 'Lunda-Sprache',
  luo: 'Luo-Sprache',
  lus: 'Lushai-Sprache',
  lb: 'Luxemburgisch',
  mde: 'Maba',
  jmc: 'Machame',
  mg: 'Madagassisch',
  mad: 'Maduresisch',
  maf: 'Mafa',
  vmf: 'Mainfr\u00e4nkisch',
  mai: 'Maithili',
  mak: 'Makassarisch',
  mgh: 'Makhuwa-Meetto',
  kde: 'Makonde',
  ms: 'Malaiisch',
  ml: 'Malayalam',
  dv: 'Maledivisch',
  mt: 'Maltesisch',
  mdr: 'Mandaresisch',
  man: 'Manding-Sprache',
  mnc: 'Mandschurisch',
  gv: 'Manx',
  mi: 'Maori',
  mr: 'Marathi',
  ary: 'Marokkanisches Arabisch',
  mh: 'Marschallesisch',
  mwr: 'Marwari',
  mzn: 'Masanderanisch',
  mas: 'Massai-Sprache',
  mk: 'Mazedonisch',
  umb: 'Mbundu-Sprache',
  fit: 'Me\u00e4nkieli',
  byv: 'Medumba',
  mul: 'Mehrsprachig',
  mni: 'Meithei-Sprache',
  men: 'Mende-Sprache',
  mwv: 'Mentawai',
  mer: 'Meru',
  mgo: 'Meta\u2019',
  es_MX: 'Mexikanisches Spanisch',
  hmn: 'Miao-Sprache',
  mic: 'Micmac-Sprache',
  nan: 'Min Nan',
  min: 'Minangkabau-Sprache',
  xmf: 'Mingrelisch',
  mwl: 'Mirandesisch',
  enm: 'Mittelenglisch',
  frm: 'Mittelfranz\u00f6sisch',
  gmh: 'Mittelhochdeutsch',
  mga: 'Mittelirisch',
  dum: 'Mittelniederl\u00e4ndisch',
  pal: 'Mittelpersisch',
  ar_001: 'Modernes Hocharabisch',
  moh: 'Mohawk',
  mdf: 'Moksha',
  ro_MD: 'Moldauisch',
  lol: 'Mongo',
  mn: 'Mongolisch',
  gor: 'Mongondou',
  mfe: 'Morisyen',
  mos: 'Mossi-Sprache',
  mua: 'Mundang',
  mus: 'Muskogee-Sprache',
  mye: 'Myene',
  nqo: 'N\u2019Ko',
  naq: 'Nama',
  na: 'Nauruisch',
  nv: 'Navajo',
  ng: 'Ndonga',
  nap: 'Neapolitanisch',
  ne: 'Nepalesisch',
  tpi: 'Neumelanesisch',
  new: 'Newari',
  sba: 'Ngambay',
  nnh: 'Ngiemboon',
  jgo: 'Ngomba',
  yrl: 'Nheengatu',
  nia: 'Nias-Sprache',
  nds: 'Niederdeutsch',
  nl: 'Niederl\u00e4ndisch',
  dsb: 'Niedersorbisch',
  niu: 'Niue-Sprache',
  nog: 'Nogai',
  nd: 'Nord-Ndebele-Sprache',
  se: 'Nord-Samisch',
  nso: 'Nord-Sotho-Sprache',
  frr: 'Nordfriesisch',
  no: 'Norwegisch',
  nb: 'Norwegisch Bokm\u00e5l',
  nn: 'Norwegisch Nynorsk',
  nov: 'Novial',
  nus: 'Nuer',
  nym: 'Nyamwezi-Sprache',
  ny: 'Nyanja-Sprache',
  nyn: 'Nyankole',
  nyo: 'Nyoro',
  nzi: 'Nzima',
  hsb: 'Obersorbisch',
  oj: 'Ojibwa-Sprache',
  oc: 'Okzitanisch',
  kru: 'Oraon-Sprache',
  or: 'Oriya',
  om: 'Oromo',
  osa: 'Osage-Sprache',
  ota: 'Osmanisch',
  os: 'Ossetisch',
  rap: 'Osterinsel-Sprache',
  de_AT: '\u00d6sterreichisches Deutsch',
  frs: 'Ostfriesisch',
  pau: 'Palau',
  pi: 'Pali',
  pam: 'Pampanggan-Sprache',
  pag: 'Pangasinan-Sprache',
  fan: 'Pangwe-Sprache',
  pa: 'Panjabi',
  pap: 'Papiamento',
  asa: 'Pare',
  ps: 'Paschtu',
  pdc: 'Pennsylvaniadeutsch',
  fa: 'Persisch',
  pfl: 'Pf\u00e4lzisch',
  phn: 'Ph\u00f6nikisch',
  pcd: 'Picardisch',
  pms: 'Piemontesisch',
  pdt: 'Plautdietsch',
  pl: 'Polnisch',
  pon: 'Ponapeanisch',
  pnt: 'Pontisch',
  pt: 'Portugiesisch',
  qu: 'Quechua',
  raj: 'Rajasthani',
  rar: 'Rarotonganisch',
  rm: 'R\u00e4toromanisch',
  rgn: 'Romagnol',
  rom: 'Romani',
  rof: 'Rombo',
  root: 'Root',
  loz: 'Rotse-Sprache',
  rtm: 'Rotumanisch',
  rug: 'Roviana',
  rw: 'Ruandisch',
  cgg: 'Rukiga',
  ro: 'Rum\u00e4nisch',
  rn: 'Rundi-Sprache',
  rue: 'Russinisch',
  ru: 'Russisch',
  rwk: 'Rwa',
  ssy: 'Saho',
  kho: 'Sakisch',
  sam: 'Samaritanisch',
  saq: 'Samburu',
  sm: 'Samoanisch',
  sgs: 'Samogitisch',
  sad: 'Sandawe-Sprache',
  sg: 'Sango',
  sbp: 'Sangu',
  sa: 'Sanskrit',
  sat: 'Santali',
  sc: 'Sardisch',
  sas: 'Sasak',
  sdc: 'Sassarisch',
  stq: 'Saterfriesisch',
  saz: 'Saurashtra',
  shn: 'Schan-Sprache',
  sli: 'Schlesisch',
  szl: 'Schlesisch (Polen)',
  sco: 'Schottisch',
  gd: 'Schottisches G\u00e4lisch',
  sv: 'Schwedisch',
  fr_CH: 'Schweizer Franz\u00f6sisch',
  de_CH: 'Schweizer Hochdeutsch',
  gsw: 'Schweizerdeutsch',
  zea: 'Seel\u00e4ndisch',
  sly: 'Selayar',
  sel: 'Selkupisch',
  seh: 'Sena',
  see: 'Seneca',
  sr: 'Serbisch',
  sh: 'Serbo-Kroatisch',
  srr: 'Serer-Sprache',
  sei: 'Seri',
  ksb: 'Shambala',
  sn: 'Shona',
  sid: 'Sidamo',
  sd: 'Sindhi',
  si: 'Singhalesisch',
  scn: 'Sizilianisch',
  sms: 'Skolt-Lappisch',
  den: 'Slave',
  sk: 'Slowakisch',
  sl: 'Slowenisch',
  xog: 'Soga',
  sog: 'Sogdisch',
  so: 'Somali',
  snk: 'Soninke-Sprache',
  ckb: 'Sorani',
  es: 'Spanisch',
  srn: 'Srananisch',
  sw: 'Suaheli',
  alt: 'S\u00fcd-Altaisch',
  nr: 'S\u00fcd-Ndebele-Sprache',
  sma: 'S\u00fcd-Samisch',
  st: 'S\u00fcd-Sotho-Sprache',
  azb: 'S\u00fcdaserbaidschanisch',
  suk: 'Sukuma-Sprache',
  sux: 'Sumerisch',
  su: 'Sundanesisch',
  sus: 'Susu',
  ss: 'Swazi',
  syr: 'Syrisch',
  tg: 'Tadschikisch',
  tl: 'Tagalog',
  ty: 'Tahitisch',
  dav: 'Taita',
  tly: 'Talisch',
  tmh: 'Tamaseq',
  zgh: 'Tamazight',
  ta: 'Tamilisch',
  rif: 'Tarifit',
  trv: 'Taroko',
  twq: 'Tasawaq',
  shi: 'Taschelhit',
  tt: 'Tatarisch',
  ttt: 'Tatisch',
  te: 'Telugu',
  tem: 'Temne',
  ter: 'Tereno-Sprache',
  teo: 'Teso',
  tet: 'Tetum-Sprache',
  th: 'Thail\u00e4ndisch',
  bo: 'Tibetisch',
  tig: 'Tigre',
  ti: 'Tigrinja',
  tiv: 'Tiv-Sprache',
  tli: 'Tlingit-Sprache',
  tkl: 'Tokelauanisch',
  to: 'Tongaisch',
  chk: 'Trukesisch',
  tkr: 'Tsachurisch',
  tsd: 'Tsakonisch',
  shu: 'Tschadisch-Arabisch',
  chg: 'Tschagataisch',
  cs: 'Tschechisch',
  chm: 'Tscheremissisch',
  ce: 'Tschetschenisch',
  cv: 'Tschuwaschisch',
  tsi: 'Tsimshian-Sprache',
  ts: 'Tsonga',
  tog: 'Tsonga-Sprache',
  tn: 'Tswana-Sprache',
  tcy: 'Tulu',
  tum: 'Tumbuka-Sprache',
  aeb: 'Tunesisches Arabisch',
  tr: 'T\u00fcrkisch',
  tk: 'Turkmenisch',
  tru: 'Turoyo',
  tyv: 'Tuwinisch',
  tw: 'Twi',
  kcg: 'Tyap',
  udm: 'Udmurtisch',
  uga: 'Ugaritisch',
  ug: 'Uigurisch',
  uk: 'Ukrainisch',
  und: 'Unbestimmte Sprache',
  hu: 'Ungarisch',
  ur: 'Urdu',
  uz: 'Usbekisch',
  vai: 'Vai',
  ve: 'Venda-Sprache',
  vec: 'Venetisch',
  vi: 'Vietnamesisch',
  vo: 'Volap\u00fck',
  vro: 'V\u00f5ro',
  vun: 'Vunjo',
  wal: 'Walamo-Sprache',
  cy: 'Walisisch',
  wa: 'Wallonisch',
  wae: 'Walser-Dialekte',
  war: 'Waray',
  wbp: 'Warlpiri',
  was: 'Washo-Sprache',
  guc: 'Way\u00fau',
  be: 'Wei\u00dfrussisch',
  vep: 'Wepsisch',
  vls: 'Westfl\u00e4misch',
  fy: 'Westfriesisch',
  wo: 'Wolof',
  vot: 'Wotisch',
  wuu: 'Wu',
  xh: 'Xhosa',
  hsn: 'Xiang',
  yav: 'Yangben',
  yao: 'Yao-Sprache',
  yap: 'Yapesisch',
  ybb: 'Yemba',
  ii: 'Yi',
  yo: 'Yoruba',
  zap: 'Zapotekisch',
  dje: 'Zarma',
  zza: 'Zaza',
  zen: 'Zenaga',
  esu: 'Zentral-Alaska-Yupik',
  dtp: 'Zentral-Dusun',
  tzm: 'Zentralatlas-Tamazight',
  za: 'Zhuang',
  zu: 'Zulu',
  zun: 'Zuni-Sprache',
};
