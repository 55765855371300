import { useAuth } from '@app/react-cognito';
import {
  faCheck,
  faUserCheck,
  faUserSlash,
  faUsers,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Form, FormInstance, Input, Row, Space } from 'antd';
import dayjs from 'dayjs';
import React, { useCallback } from 'react';
import { Columns } from '../components/admin/Table';
import TableWithDrawer from '../components/admin/TableWithDrawer';
import PageHeader from '../components/base/PageHeader';
import RoleSelect from '../components/form/RoleSelect';
import {
  GetUsersQuery,
  GetUsersQueryVariables,
  Role,
  User,
  UserStatus,
  useDeleteUserMutation,
  useDisableUserMutation,
  useEnableUserMutation,
  useGetUsersQuery,
  useInviteUserMutation,
  useUpdateUserMutation,
} from '../graphql/schema';
import formItemProps from '../helper/formItemProps';

const columns: Columns<User> = {
  email: 'E-Mail',
  name: {
    label: 'Name',
    render: (_, item) => `${item.firstName ?? ''} ${item.lastName ?? ''}`,
  },
  status: {
    label: 'Status',
    render: (value) => {
      if (value === UserStatus.ARCHIVED) return 'Archiviert';
      if (value === UserStatus.COMPROMISED) return 'Kompromittiert';
      if (value === UserStatus.CONFIRMED) return 'Bestätigt';
      if (value === UserStatus.EXTERNAL_PROVIDER) return 'Externe Anmeldung';
      if (value === UserStatus.FORCE_CHANGE_PASSWORD)
        return 'Passwortänderung nötig';
      if (value === UserStatus.RESET_REQUIRED) return 'Zurücksetzen nötig';
      if (value === UserStatus.UNCONFIRMED) return 'Unbestätigt';
      if (value === UserStatus.UNKNOWN) return 'Unbekannt';
      return '-';
    },
  },
  role: {
    label: 'Rolle',
    render: (role) => {
      if (role === Role.USER) return 'Benutzer';
      if (role === Role.COMPANY_ADMIN) return 'Firmenadministrator';
      if (role === Role.SYSTEM_ADMIN) return 'Systemadministrator';
      return '-';
    },
  },
  enabled: {
    label: 'Zugriff erlaubt',
    render: (value) =>
      value ? (
        <FontAwesomeIcon icon={faCheck} />
      ) : (
        <FontAwesomeIcon icon={faUserSlash} />
      ),
  },
  createdAt: {
    label: 'Erstellt am',
    render: (value) => dayjs(value).format('DD.MM.YYYY'),
  },
};

interface FormFields {
  email: string;
  firstName: string;
  lastName: string;
  role: Role;
}

const Users = () => {
  const { groups } = useAuth();

  const [invite] = useInviteUserMutation();
  const [disable] = useDisableUserMutation();
  const [enable] = useEnableUserMutation();
  const [remove] = useDeleteUserMutation();
  const [update] = useUpdateUserMutation();

  const handleInvite = useCallback(
    async (values: FormFields) => {
      await invite({
        variables: {
          dto: {
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            role: values.role,
          },
        },
        refetchQueries: ['GetUsers'],
      });
    },
    [invite],
  );

  const handleDisable = useCallback(
    (id: string, closeDrawer: () => void) => async () => {
      await disable({
        variables: {
          id,
        },
        refetchQueries: ['GetUsers'],
      });
      closeDrawer();
    },
    [disable],
  );

  const handleEnable = useCallback(
    (id: string, closeDrawer: () => void) => async () => {
      await enable({
        variables: {
          id,
        },
        refetchQueries: ['GetUsers'],
      });
      closeDrawer();
    },
    [enable],
  );

  const handleDelete = useCallback(
    async (id: string) => {
      await remove({
        variables: {
          id,
        },
        refetchQueries: ['GetUsers'],
      });
    },
    [remove],
  );

  const handleUpdate = useCallback(
    async (id: string, values: FormFields) => {
      await update({
        variables: {
          id,
          dto: {
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            role: values.role,
          },
        },
        refetchQueries: ['GetUsers'],
      });
    },
    [update],
  );

  const formRenderer = (form: FormInstance) => {
    return (
      <Form layout="vertical" form={form}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item {...formItemProps('email', 'E-Mail')}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item {...formItemProps('firstName', 'Vorname')}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item {...formItemProps('lastName', 'Nachname')}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item {...formItemProps('role', 'Rolle')}>
              <RoleSelect
                showSystemAdmin={groups.includes(Role.SYSTEM_ADMIN)}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <Card>
      <PageHeader
        breadcrumbs={[
          {
            icon: faUsers,
            label: 'Benutzer',
          },
        ]}
      />
      <TableWithDrawer<FormFields, User, GetUsersQuery, GetUsersQueryVariables>
        query={useGetUsersQuery}
        columns={columns}
        editFormRenderer={formRenderer}
        createFormRenderer={formRenderer}
        singleItemTitle="Benutzer"
        onCreate={handleInvite}
        onUpdate={handleUpdate}
        onDelete={handleDelete}
        editDrawerExtra={(item, closeDrawer) => (
          <Space>
            {item.enabled && (
              <Button
                icon={<FontAwesomeIcon icon={faUserSlash} />}
                onClick={handleDisable(item.id, closeDrawer)}
              >
                Benutzer deaktivieren
              </Button>
            )}
            {!item.enabled && (
              <Button
                icon={<FontAwesomeIcon icon={faUserCheck} />}
                onClick={handleEnable(item.id, closeDrawer)}
              >
                Benutzer reaktivieren
              </Button>
            )}
          </Space>
        )}
      />
    </Card>
  );
};

export default Users;
