import React from 'react';
import GenericTask from '../components/tasks/GenericTask';
import TasksSidebar from '../components/tasks/TasksSidebar';

const CreateGenericTask = () => {
  return (
    <div className="flex flex-row space-x-6 h-full flex-1">
      <TasksSidebar />
      <GenericTask />
    </div>
  );
};

export default CreateGenericTask;
