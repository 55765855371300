import { v7 } from 'uuid';
import { FileUploadInfo } from '../graphql/schema';

interface ImageSizes {
  [key: string]: [number, number];
}

interface ImageResizeResult {
  size: string;
  file: Blob | null;
}

const scaleDownImage = async (file: File, sizes: ImageSizes) => {
  return new Promise<ImageResizeResult[]>((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      // Resize image
      const img = document.createElement('img');

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0);

        Promise.all(
          Object.keys(sizes).map(
            (sizeKey) =>
              new Promise<ImageResizeResult>((resolveSize) => {
                const [MAX_WIDTH, MAX_HEIGHT] = sizes[sizeKey];

                let width = img.width;
                let height = img.height;

                if (img.width > MAX_WIDTH || img.height >= MAX_HEIGHT) {
                  if (width > height) {
                    if (width > MAX_WIDTH) {
                      height *= MAX_WIDTH / width;
                      width = MAX_WIDTH;
                    }
                  } else {
                    if (height > MAX_HEIGHT) {
                      width *= MAX_HEIGHT / height;
                      height = MAX_HEIGHT;
                    }
                  }
                }

                canvas.width = width;
                canvas.height = height;

                const ctx2 = canvas.getContext('2d');
                ctx2?.drawImage(img, 0, 0, width, height);

                canvas.toBlob(
                  (data) => {
                    resolveSize({
                      size: sizeKey,
                      file: data,
                    });
                  },
                  'image/jpeg',
                  80,
                );
              }),
          ),
        ).then((scaledDownImages) => {
          resolve(scaledDownImages);
        });
      };

      img.src = reader.result as string;
    };
  });
};

const uploadFile = async (
  protocol: string,
  file: File,
  uploadInfo: FileUploadInfo,
) => {
  const fileName = `${v7()}_${file.name.replace(/\//g, '')}`;

  const formData = new FormData();
  for (const key in uploadInfo.fields) {
    formData.append(
      key,
      uploadInfo.fields[key].replace('${filename}', fileName),
    );
  }

  formData.append('file', file);

  await fetch(uploadInfo.url, {
    method: 'POST',
    body: formData,
  });

  // Scale down and upload images
  if (
    ['image/jpeg', 'image/jpg', 'image/png', 'image/webp'].includes(file.type)
  ) {
    const scaledDownImages = await scaleDownImage(file, {
      thumbnail: [200, 200],
      small: [2048, 2048],
    });

    await Promise.all(
      scaledDownImages.map(async (previewImage) => {
        if (previewImage.file) {
          const formData = new FormData();
          for (const key in uploadInfo.fields) {
            formData.append(
              key,
              uploadInfo.fields[key].replace(
                '${filename}',
                fileName.replace(
                  /^(.*)\.(jpg|jpeg|png|webp)$/,
                  `$1.$2.${previewImage.size}.jpg`,
                ),
              ),
            );
          }

          formData.append('file', previewImage.file);

          await fetch(uploadInfo.url, {
            method: 'POST',
            body: formData,
          });
        }
      }),
    );
  }

  return `${protocol}://${fileName}`;
};

const uploadFormFiles = async (
  protocol: 'task' | 'taskDefinition' | 'taskDefinitionSettings',
  formValues: any,
  uploadInfo?: FileUploadInfo | null,
) => {
  if (!uploadInfo) throw new Error('Missing upload info');

  await Promise.all(
    Object.keys(formValues).map(async (fieldName) => {
      const fieldValue = formValues[fieldName];
      if (Array.isArray(fieldValue)) {
        await Promise.all(
          fieldValue.map(async (item, index) => {
            if (item instanceof File) {
              formValues[fieldName][index] = await uploadFile(
                protocol,
                item,
                uploadInfo,
              );
            } else if (
              typeof item === 'object' &&
              Object.hasOwn(item, 'value')
            ) {
              // File was uploaded before
              formValues[fieldName][index] = item.value;
            }
          }),
        );
      } else {
        if (fieldValue instanceof File) {
          formValues[fieldName] = await uploadFile(
            protocol,
            fieldValue,
            uploadInfo,
          );
        } else if (
          typeof fieldValue === 'object' &&
          Object.hasOwn(fieldValue, 'value')
        ) {
          // File was uploaded before
          formValues[fieldName] = fieldValue.value;
        }
      }
    }),
  );

  return formValues;
};

export default uploadFormFiles;
