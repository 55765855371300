import { faFile, faPaperclip } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Upload as BaseUpload, Button, UploadFile, message } from 'antd';
import { RcFile } from 'antd/es/upload';
import { useCallback, useMemo } from 'react';
import { v7 } from 'uuid';
import { getFileNameFromFileId } from '../../helper/files';
import Image from '../base/Image';

interface Props {
  value?: string | File | string[] | File[];
  onChange?: (val: File | File[]) => void;
  maxCount?: number;
  allowedMimeTypes?: string[];
}

const parseFileName = (value: any) => {
  if (!value) return null;

  if (typeof value === 'object' && Object.hasOwn(value, 'value')) {
    // Previously uploaded file
    return getFileNameFromFileId(value.value);
  }

  if (typeof value === 'string') return getFileNameFromFileId(value);

  if (value instanceof File) {
    // File object
    return value.name;
  }

  return 'Unknown file name';
};

const Upload = ({ value, onChange, maxCount, allowedMimeTypes }: Props) => {
  const onBeforeUpload = useCallback(
    (file: RcFile) => {
      if (allowedMimeTypes && !allowedMimeTypes.includes(file.type)) {
        message.error('Dieses Dateiformat wird leider nicht unterstützt!');
        return false;
      }

      if (file.size / 1024 / 1024 > 20) {
        message.error('Dateien dürfen maximal 20MB groß sein!');
        return false;
      }

      return true;
    },
    [allowedMimeTypes],
  );

  const onUpload = useCallback(
    async ({
      onSuccess,
    }: {
      onSuccess?: (body?: any) => void;
    }) => {
      if (onSuccess) onSuccess();
    },
    [],
  );

  const handleChange = useCallback(
    ({ fileList }: { fileList: UploadFile[] }) => {
      if (onChange) {
        if (maxCount && maxCount > 1) {
          onChange(
            fileList.map((file) => file.originFileObj).filter((file) => !!file),
          );
        } else {
          if (fileList[0].originFileObj) onChange(fileList[0].originFileObj);
        }
      }
    },
    [onChange, maxCount],
  );

  const fileList = useMemo(() => {
    if (!value) return [];

    if (Array.isArray(value)) {
      return value.map((val) => {
        return {
          uid: v7(),
          name: parseFileName(val),
          url: (val as any).link,
        };
      });
    }

    return [
      {
        uid: v7(),
        name: parseFileName(value),
        url: (value as any).link,
      },
    ];
  }, [value]);

  return (
    <BaseUpload
      beforeUpload={onBeforeUpload}
      customRequest={onUpload}
      onChange={handleChange}
      maxCount={maxCount ?? 1}
      multiple={!!(maxCount && maxCount > 1)}
      showUploadList={true}
      className="max-w-[400px] block"
      fileList={fileList as UploadFile[]}
    >
      <Button icon={<FontAwesomeIcon icon={faPaperclip} />}>
        <span className="max-w-[200px] overflow-ellipsis overflow-hidden text-nowrap">
          {`Datei${maxCount && maxCount > 1 ? 'en' : ''} auswählen`}
        </span>
      </Button>
    </BaseUpload>
  );
};

const FilePreview = ({
  name,
  link,
  preview,
}: { name: any; link?: string; preview?: string }) => {
  const fileName = parseFileName(name);

  if (
    link &&
    typeof name === 'string' &&
    name.toLowerCase().match(/.+(jpg|jpeg|png|gif|webp)$/) &&
    preview
  ) {
    // Image
    return <Image previewUrl={preview} url={link} width={90} />;
  }

  return (
    <a
      href={link}
      target="_blank"
      className="flex flex-col items-center justify-center overflow-hidden w-[100px] h-[70px] p-1 space-y-2"
      style={{
        boxShadow: '0 0 1px rgba(0,0,0,0.2)',
      }}
      title={fileName ?? ''}
    >
      <FontAwesomeIcon icon={faFile} size="lg" />
      <div className="text-[10px] leading-none overflow-hidden line-clamp-3 text-center">
        {fileName}
      </div>
    </a>
  );
};

export const UploadReadOnly = ({ value }: { value: any }) => {
  if (!value) return '';

  // Multiple files
  if (Array.isArray(value)) {
    return (
      <div className="flex flex-row space-x-2">
        {value.map((file) => {
          // File with link
          if (typeof file === 'object' && file.link) {
            return (
              <FilePreview
                name={file.value}
                link={file.link}
                preview={file.preview}
                key={file.link}
              />
            );
          }

          // File without link
          return <FilePreview name={file} key={file} />;
        })}
      </div>
    );
  }

  // Single file with link
  if (typeof value === 'object' && value.link) {
    return (
      <div>
        <FilePreview
          name={value.value}
          link={value.link}
          preview={value.preview}
        />
      </div>
    );
  }

  // File without link
  return (
    <div>
      <FilePreview name={value} />
    </div>
  );
};

export default Upload;
