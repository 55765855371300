import { Spin } from 'antd';
import React from 'react';

const Loading = () => {
  return (
    <div className="w-full text-center mt-10">
      <Spin />
    </div>
  );
};
export default Loading;
