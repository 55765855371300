import { AntdChangePassword } from '@app/react-cognito';
import { faKey } from '@fortawesome/pro-light-svg-icons';
import { Card } from 'antd';
import React from 'react';
import PageHeader from '../components/base/PageHeader';

const ChangePassword = () => {
  return (
    <Card>
      <PageHeader
        breadcrumbs={[
          {
            icon: faKey,
            label: 'Passwort ändern',
          },
        ]}
      />

      <AntdChangePassword />
    </Card>
  );
};

export default ChangePassword;
