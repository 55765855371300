import {
  faChevronDown,
  faMagicWandSparkles,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dropdown, Space } from 'antd';
import { useMemo } from 'react';
import { TaskDefinition, useGetProvidersQuery } from '../../graphql/schema';

interface Props {
  taskDefinition: TaskDefinition;
  selectProvider: (providerId: string) => void;
  selectedProviderId?: string;
}

const ProviderSelector = ({
  taskDefinition,
  selectProvider,
  selectedProviderId,
}: Props) => {
  const { data, loading } = useGetProvidersQuery();

  const providers = useMemo(() => {
    return data?.providers.filter((p) =>
      taskDefinition.providerConfigs?.find((c) => c.providerId === p.id),
    );
  }, [data, taskDefinition]);

  const selectedProvider = useMemo(() => {
    return providers?.find((p) => p.id === selectedProviderId);
  }, [providers, selectedProviderId]);

  if (loading || !selectedProvider) return <div />;

  return (
    <div className="flex flex-row space-x-2">
      <Dropdown
        menu={{
          items: providers?.map((provider) => ({
            label: provider.name,
            key: provider.id,
            onClick: () => selectProvider(provider.id),
          })),
        }}
        trigger={['click']}
      >
        <Button type="text">
          <Space>
            <FontAwesomeIcon icon={faMagicWandSparkles} />
            {selectedProvider.name}
            <FontAwesomeIcon icon={faChevronDown} />
          </Space>
        </Button>
      </Dropdown>
    </div>
  );
};

export default ProviderSelector;
