import { faList, fal as icons } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Category, Module } from '../../graphql/schema';

interface Props {
  module: Module;
  selectCategory: (category?: Category) => void;
  activeCategoryId?: string;
}

const CategoriesSidebar = ({
  module,
  selectCategory,
  activeCategoryId,
}: Props) => {
  return (
    <div className="flex flex-col space-y-2">
      <div
        onClick={() => selectCategory()}
        className={`theme-bg-primary-hover ${!activeCategoryId ? 'theme-bg-primary' : ''} cursor-pointer rounded-xl p-[10px] px-[15px] flex space-x-2 items-center`}
      >
        <FontAwesomeIcon icon={faList} />
        <span>Alle</span>
      </div>

      {module.categories?.map((category) => (
        <div
          key={category.id}
          onClick={() => selectCategory(category)}
          className={`theme-bg-primary-hover ${activeCategoryId === category.id ? 'theme-bg-primary' : ''} cursor-pointer rounded-xl p-[10px] px-[15px] flex space-x-2 items-center`}
        >
          <FontAwesomeIcon icon={icons[category.icon]} />
          <span>{category.title}</span>
        </div>
      ))}
    </div>
  );
};

export default CategoriesSidebar;
