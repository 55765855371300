import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { useAuth } from '@app/react-cognito';
import React, { useEffect } from 'react';
import { TaskList } from '../../graphql/schema';
import { ApiContext } from './apiContext';
import generateLink from './generateLink';

const apolloClient = new ApolloClient({
  link: generateLink(),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          tasks: {
            keyArgs: false,
            merge(existing: TaskList | undefined, incoming: TaskList) {
              if (!existing) return incoming;
              return {
                ...incoming,
                items: [...existing.items, ...incoming.items],
              } satisfies TaskList;
            },
          },
        },
      },
    },
  }),
  defaultOptions: {
    query: {
      fetchPolicy: 'cache-first',
    },
  },
});

interface Props {
  children: React.ReactNode;
}

const ApiProvider = ({ children }: Props) => {
  const { refreshSession, session } = useAuth();

  useEffect(() => {
    apolloClient.setLink(
      generateLink(async () => {
        return refreshSession();
      }),
    );
  }, [refreshSession]);

  useEffect(() => {
    apolloClient.resetStore();
  }, [session.user?.user]);

  return (
    <ApiContext.Provider value={undefined}>
      <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
    </ApiContext.Provider>
  );
};

export default ApiProvider;
